import store from "../redux/store";

export const getTenant = () => {
  const tenantStore = store.getState().tenant;
  const tenant = tenantStore.tenants.find((_tenant) => _tenant.alias === tenantStore.selectedTenantAlias);
  return tenant;
};

export const getSelectedTenant = () => {
  return store.getState().tenant.selectedTenant;
};

export const getBranchId = () => {
  const branchId = store.getState().tenant?.selectedTenant?.branchId;
  return branchId;
};

export const tenantIsUSA = () => {
  const currentTenantId = store?.getState()?.tenant?.selectedTenant?.id;
  const isUSA = [101, 192, 290, 274].includes(currentTenantId);
  return isUSA;
};
