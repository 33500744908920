import { MAX_AGE_COOKIE } from "../constants";

interface ICookies {
  cookieExpiration: string;
  sameSite: string;
  secure?: boolean;
}

export function getJSONCookie(name: string): { [x: string]: any } {
  const cookie = getCookie(name);
  let cookieJSON = {};
  try {
    cookieJSON = cookie ? JSON.parse(cookie) : {};
  } catch {
    console.warn("Could decode JSON cookie: ", cookie);
  }
  return cookieJSON;
}

export function getCookie(name: string): string {
  const regex = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*=\\s*([^;]*).*$)|^.*$`);
  let cookie: string = document.cookie.replace(regex, "$1");
  return cookie ? decodeURIComponent(cookie) : "";
}

export function setJSONCookie(name: string, value: { [x: string]: any } = {}): boolean {
  return setCookie(name, JSON.stringify(value));
}

export function setCookie(name: string, value: string): boolean {
  const escapeValue = encodeURIComponent(value);
  document.cookie = `${name}=${escapeValue};path=/;max-age=${MAX_AGE_COOKIE};samesite=stric;`;
  return true;
}
export function setCookieWithOptions(name: string, value: string, options = {} as ICookies) {
  const escapeValue = encodeURIComponent(value);
  let expiration = `max-age=${MAX_AGE_COOKIE}`;
  if (options.cookieExpiration) expiration = `expires=${options.cookieExpiration}`;
  document.cookie = `${name}=${escapeValue};path=/;${expiration};samesite=${options.sameSite};${options.secure ? "Secure;" : ""}`;
  return true;
}
export function deleteCookie(name: string): boolean {
  document.cookie = name + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  return true;
}
