import React, { ReactElement, useEffect, useState } from "react";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import { CombinedState } from "../../../../redux/store/state";
import { TenantState } from "../../../../redux/slices/tenantSlice";
import { NavigationState } from "../../../../redux/slices/navigationSlice";

import { CURRENT_LOCALE, getLocale } from "../../../../helper/locale-utils";
import { getAppInsights } from "../../../../helper/telemetryService";
import { TrackingStorage } from "../../../../helper/tracking-storage";
import { TelemetryProvider } from "../../../../helper/telemetryProvider";

import InternalContainer from "../../containers/internal-container";
import PublicContainer from "../../containers/public-container";
import LandingContainer from "../../containers/landing-container";
import Snackbar from "../../snackbar/snackbar";
import Loading from "../../loading/loading";

import { Container } from "../../../../constants";

// added import to be able to work the tz function in all pages that use moment
import "moment-timezone";

// import old ui switch styles
import "../../../../assets/styles/shared/_switch-old-ui.scss";

/**
 * Component that handle the auth context
 */
const AppContainer: React.FC<any> = (): ReactElement => {
  const tracking = new TrackingStorage();

  const tenantsState: TenantState = useSelector((state: CombinedState) => state.tenant);
  const navigationState: NavigationState = useSelector((state: CombinedState) => state.navigation);

  const theme = tenantsState.selectedTenant?.theme;
  const currentRoute = navigationState.currentRoute;

  const [loading, setLoading] = useState(true);
  const [container, setContainer] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    tracking.clearStorage();
    setContainer(currentRoute?.landing ? Container.landing : currentRoute?.public ? Container.public : Container.internal);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContainer(currentRoute?.landing ? Container.landing : currentRoute?.public ? Container.public : Container.internal);
  }, [currentRoute]);

  function onSnackbar(message?: string) {
    setErrorMessage(message ? message : "");
    setShowSnackbar(message ? true : false);
  }

  const cache = createIntlCache();

  const intl: any = createIntl({ locale: CURRENT_LOCALE === "en-USA" ? "en-US" : CURRENT_LOCALE, messages: getLocale(CURRENT_LOCALE).messages }, cache);

  return (
    <RawIntlProvider value={intl}>
      <TelemetryProvider after={() => getAppInsights()} classTheme="">
        <div id="recruitment-connect-container" className={currentRoute?.nicename !== "/health" ? theme : ""}>
          {loading ? (
            <Loading id="app-container" />
          ) : (
            <>
              {container === Container.internal && <InternalContainer onShowSnackbar={onSnackbar} getAppInsights={getAppInsights} />}
              {container === Container.public && <PublicContainer onShowSnackbar={onSnackbar} getAppInsights={getAppInsights} />}
              {container === Container.landing && <LandingContainer onShowSnackbar={onSnackbar} getAppInsights={getAppInsights} />}
            </>
          )}
        </div>
        {showSnackbar && <Snackbar message={errorMessage} onTimeout={onSnackbar} />}
      </TelemetryProvider>
    </RawIntlProvider>
  );
};

export default AppContainer;
