import store from "../redux/store";
import { APIM_KEY } from "../constants";
import { CURRENT_LOCALE } from "../helper/locale-utils";

export function isPublicPart(): boolean {
  const storeState = store?.getState();
  const actualRoute = storeState?.navigation?.currentRoute || {};
  // Double negation to force return true/false and avoid undefined
  return !!actualRoute?.public;
}

export function isExternalPage(): boolean {
  const storeState = store?.getState();
  const actualRoute = storeState?.navigation?.currentRoute || {};
  // Double negation to force return true/false and avoid undefined
  return !!actualRoute?.external;
}

// GENERATE HEADERS
export function getBasicHeaders(branchHeaders: boolean = false, overwriteHeaders: any = {}) {
  const storeState = store?.getState();
  const t = storeState?.auth?.token || null;
  const external = storeState?.auth?.external || null;
  const selectedTenant = storeState?.tenant?.selectedTenant || {};
  const selectedUserProfile = storeState?.tenant?.selectedUserProfile;
  let headers: any = {
    "X-Language": CURRENT_LOCALE,
    "X-Tenant-Alias": selectedTenant.alias || "",
    "Ocp-Apim-Subscription-Key": APIM_KEY,
    "X-User-Profile": selectedUserProfile || "",
  };

  if (external) {
    headers["X-External"] = "true";
  }

  if (t && (!isPublicPart() || isExternalPage())) {
    headers["Authorization"] = "Bearer " + t;
  }

  if (branchHeaders) {
    const branchID = selectedTenant.branchId;
    if (branchID) {
      headers.branchId = branchID;
    }
    const regionId = selectedTenant.regionId;
    if (branchID) {
      headers.regionId = regionId;
    }
    const zoneId = selectedTenant.zoneId;
    if (branchID) {
      headers.zoneId = zoneId;
    }
  }

  headers = { ...headers, ...overwriteHeaders };
  return headers;
}
