const localStorageTrackingTenantAlias = "rc_x";
const localStorageTrackingUserId = "rc_y";
const localStorageTrackingProcessId = "rc_z";

export class TrackingStorage {
  saveTrackingData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  loadTrackingData(key: string) {
    const data = localStorage.getItem(key);
    if (!data) return null;
    return JSON.parse(data);
  }

  clearStorage() {
    const rc_trackingStorage = [localStorageTrackingTenantAlias, localStorageTrackingUserId, localStorageTrackingProcessId];

    rc_trackingStorage.forEach((rc_trackingStorageItem: string) => {
      localStorage.removeItem(rc_trackingStorageItem);
    });
  }
}
