import React, { Component } from "react";
export interface IProps {
  [others: string]: any;
}
export default class Selections extends Component<IProps> {
  onRemoveItem = (item: any) => {
    this.props.onRemoveItem(item);
  };
  renderOptions() {
    if (this.props.items && this.props.items.length > 0) {
      let options: any[] = [];
      this.props.items.forEach((item: any) => {
        let element = (
          <span key={item.props?.value} className="tag-button">
            {item.props?.label}

            <button
              className="btn-grey"
              disabled={this.props.disabled}
              onClick={() => {
                this.onRemoveItem(item.props?.value);
              }}
            >
              <span className="fa fa-times"></span>
            </button>
          </span>
        );
        if (this.props.value) {
          if (
            (this.props.value.length && typeof this.props.value === "object" && (this.props.value.indexOf(item.props?.value) !== -1 || this.props.value.indexOf(`${item.props?.value}`) !== -1)) ||
            this.props.value === item.props?.value ||
            this.props.value === `${item.props?.value}`
          ) {
            options.push(element);
          }
        }
      });
      return options;
    }
    return null;
  }
  render() {
    return <div className="selected-items-wrapper">{this.renderOptions()}</div>;
  }
}
