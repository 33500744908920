import { matchPath } from "react-router-dom";
import { CANDIDATE_SOURCING_MODULE, RECRUITMENT_CONNECT_MODULE } from "../constants";
import { getFeatureConfiguration } from "../helper/feature-configuration-helper";
import { getUserAllowedModules } from "../helper/role-helper";
import { ROUTES } from "../routes";
export interface IRoute {
  nicename: string;
  id: string;
  isActive?: boolean;
  breadcrumb?: string;
  parentId?: string;
  title?: string;
  allowedModules?: string;
  hideInProduction?: boolean;
  url: string;
  public?: boolean;
  landing?: boolean;
  section?: string;
  roles?: string[];
  permissions?: string[];
  fullSizeView?: boolean;
  external?: boolean;
}

export class NavItem implements IRoute {
  constructor(obj: IRoute) {
    this.id = obj.id;
    this.parentId = obj.parentId;
    this.nicename = obj.nicename;
    this.breadcrumb = obj.breadcrumb;
    this.allowedModules = obj.allowedModules;
    this.hideInProduction = obj.hideInProduction;
    this.url = getPageRelativeURL(obj);
    this.public = obj.public;
    this.landing = obj.landing;
    this.section = obj.section;
    this.roles = obj.roles;
    this.permissions = obj.permissions;
    this.fullSizeView = obj.fullSizeView;
    this.external = obj.external;
  }
  id: string;
  parentId?: string;
  nicename: string;
  breadcrumb?: string;
  allowedModules?: string;
  hideInProduction?: boolean;
  url: string;
  isActive?: boolean;
  public?: boolean;
  landing?: boolean;
  section?: string;
  roles?: string[];
  permissions?: string[];
  fullSizeView?: boolean;
  external?: boolean;
}

export interface IRoutes {
  [others: string]: any;
}

export const navigationModules = {
  candidateSourcing: CANDIDATE_SOURCING_MODULE,
  recruitmentConnect: RECRUITMENT_CONNECT_MODULE,
};

export const USER_MODULES = [navigationModules.candidateSourcing, navigationModules.recruitmentConnect];

export const getLandingPageURL = () => {
  let landingPagePath = "";

  // Check recruiter permissions
  const userHasPermissionToSourcing = userHasPermissionToModule(navigationModules.candidateSourcing);
  const userHasPermissionToRecruitment = userHasPermissionToModule(navigationModules.recruitmentConnect);

  // If recruiter have no access module, return
  if (!userHasPermissionToSourcing && !userHasPermissionToRecruitment) return landingPagePath;

  const featureConfiguration = getFeatureConfiguration("General Settings", "recruiter-portal");
  if (featureConfiguration?.isEnabled) landingPagePath = "/";
  else {
    // If recruiter have access to bouth modules
    if (userHasPermissionToSourcing && userHasPermissionToRecruitment) {
      // Check the default cofigured
      if (featureConfiguration?.config?.defaultModule === CANDIDATE_SOURCING_MODULE) landingPagePath = getRoutePath("JOB_LISTING");
      else landingPagePath = getRoutePath("RECRUITMENT");
    }
    // If recruiter only have access to one module, redirect to that one
    else if (userHasPermissionToSourcing) landingPagePath = getRoutePath("JOB_LISTING");
    else if (userHasPermissionToRecruitment) landingPagePath = getRoutePath("RECRUITMENT");
  }

  return landingPagePath;
};

export const userHasPermissionToModule = (module: string) => {
  const userAllowedModules = getUserAllowedModules();
  if (!Object.keys(userAllowedModules).length) {
    return true;
  }
  if (userAllowedModules && userAllowedModules[module]) {
    return true;
  } else {
    return false;
  }
};

let cachedRoutes: NavItem[] = [];
export const getAllRoutes = () => {
  if (!cachedRoutes?.length) {
    let routesArray: any = ROUTES;
    let processedRoutes: NavItem[] = [];
    routesArray.forEach((element: IRoute) => {
      processedRoutes.push(new NavItem(element));
    });
    return processedRoutes;
  } else {
    return cachedRoutes;
  }
};

/*Generates the URL of the page for navigation propouses*/
export const getPageRelativeURL = (route: IRoute) => {
  let routesArray: any = ROUTES;
  let url = "";
  let parentPage = getParentPage(route, routesArray);
  if (parentPage != null) {
    url = getPageRelativeURL(parentPage);
    if (url.indexOf("#")) {
      url = url.split("#")[0];
    }
  }
  return route.nicename !== null ? url + "/" + route.nicename : url + "/";
};

/*retrieves the inmediate parent page*/
export const getParentPage = (navItem: IRoute, allPages: IRoute[]) => {
  if (navItem && navItem.parentId !== null) {
    let parentCandidates = allPages.find((a: IRoute) => a.id === navItem.parentId);
    if (parentCandidates) {
      return parentCandidates;
    }
  }
  return null;
};

const placeholderMatcher = /:[^\s/]+/g;
const replacePlaceholdersFromTitle = (pageTitle: string, params: any) => {
  const placeholders = pageTitle?.match(placeholderMatcher);
  if (!placeholders) {
    return pageTitle;
  } else if (placeholders?.length) {
    placeholders.forEach((element) => {
      const sanitizedPlaceholder = element.replace(":", "");
      pageTitle = pageTitle.replace(element, params[sanitizedPlaceholder]);
    });
  }
  return pageTitle;
};

export const getPageTitle = (navItem: IRoute, routerMatch: any) => {
  let pageTitle = navItem.breadcrumb ? navItem.breadcrumb : "";
  const routeParams = routerMatch?.params;
  if (typeof pageTitle === "function") {
    const pageTitleFn: Function = pageTitle;
    pageTitle = pageTitleFn(routerMatch?.url, routeParams);
    return pageTitle;
  } else {
    if (pageTitle?.indexOf(":") !== -1) {
      pageTitle = replacePlaceholdersFromTitle(pageTitle, routeParams);
      return pageTitle;
    }
  }
  return navItem.breadcrumb;
};

export const getRoutePath = (id: string) => {
  try {
    const allRoutes = getAllRoutes();
    const selectedRoute = allRoutes.find((a) => a.id === id);
    if (selectedRoute) {
      return selectedRoute.url;
    } else {
      console.warn("Rotue not found", id);
      return "/";
    }
  } catch (e) {
    console.error("Error getting route", id);
    return "/";
  }
};

const getParentNicename = (parentId?: string) => {
  let fullParentNicename = "";
  if (parentId) {
    try {
      const allRoutes = getAllRoutes();
      const selectedRoute = allRoutes.find((a) => a.id === parentId);
      if (selectedRoute) fullParentNicename = getParentNicename(selectedRoute.parentId) + selectedRoute.nicename.split("#")[0] + "/";
    } catch (e) {
      console.warn("getParentNicename.error.parentId", parentId);
    }
  }
  return fullParentNicename;
};

export const getFullPath = (route: string): string => {
  let fullPath = "";
  try {
    const allRoutes = getAllRoutes();
    const selectedRoute = allRoutes.find((a) => a.id === route);
    if (selectedRoute) fullPath = "/" + getParentNicename(selectedRoute.parentId) + selectedRoute.nicename.split("#")[0];
  } catch (e) {
    console.warn("getFullPath.error.route", route);
  }
  return fullPath;
};

let lastRoute: any = undefined;
let lastPath: string | undefined = undefined;
export const getCurrentRoute = (pathname: string): NavItem | any => {
  let currentRoute;
  if (lastPath === pathname) {
    return lastRoute;
  } else {
    try {
      const allRoutes = getAllRoutes();
      let i = 0;
      while (!currentRoute && i < allRoutes.length) {
        const route: NavItem = allRoutes[i];
        const match = matchPath(pathname, getFullPath(route.id));
        if (match && match.isExact) {
          currentRoute = route;
        }
        i++;
      }
    } catch (e) {
      console.warn("getCurrentRoute.error", pathname);
    }
    lastPath = pathname;
    lastRoute = currentRoute;
    return currentRoute;
  }
};

export const isPublicOrLogin = (currentRoute: NavItem) => {
  return currentRoute?.public || currentRoute?.id === "LOGIN" || currentRoute?.id === "LOGIN_TOKEN";
};
