import adeccoMainLogo from "../assets/images/adecco-theme/common/logo.png";
import adeccoMainLogo2 from "../assets/images/adecco-theme/common/logo-2.png";
import adeccoGroupMainLogo from "../assets/images/adecco-group-theme/common/logo2.png";
import adeccoGroupMainLogoColored from "../assets/images/adecco-group-theme/common/logo.png";
import adeccoMedicalMainLogo from "../assets/images/adecco-medical-theme/common/logo.png";
import adeccoMedicalMainLogoColored from "../assets/images/adecco-medical-theme/common/logo-color.png";
import adiaMainLogo from "../assets/images/adia-theme/common/logo.png";
import bcMainLogo from "../assets/images/bc-theme/common/logo.png";
import bcMainLogoColored from "../assets/images/bc-theme/common/logo-color.png";
import generalAssemblyMainLogo from "../assets/images/general-assembly-theme/common/logo.png";
import hiredMainLogo from "../assets/images/hired-theme/common/logo.png";
import lhhMainLogo from "../assets/images/lhh-theme/common/logo.png";
import lhhMainLogoClored from "../assets/images/lhh-theme/common/logo-color.png";
import modisMainLogo from "../assets/images/modis-theme/common/logo.png";
import modisMainLogoColored from "../assets/images/modis-theme/common/logo-color.png";
import onsiteMainLogo from "../assets/images/onsite-theme/common/logo.png";
import onsiteMainLogoColored from "../assets/images/onsite-theme/common/logo-color.jpg";
import pontoonMainLogo from "../assets/images/pontoon-theme/common/logo.png";
import springMainLogo from "../assets/images/spring-theme/common/logo.png";
import springMainLogoColored from "../assets/images/spring-theme/common/logo-color.png";
import vetteryMainLogo from "../assets/images/vettery-theme/common/logo.png";
import proservMainLogo from "../assets/images/proserv-theme/common/logo.png";
import proservMainLogoColored from "../assets/images/proserv-theme/common/logo-color.png";
import adeccoBusinessSolutionsMainLogo from "../assets/images/adecco-business-solutions-theme/common/logo.png";
import adeccoServiceCenterSolutionsMainLogo from "../assets/images/adecco-service-center-solutions-theme/common/logo.png";
import adeccoAustraliaMainLogo from "../assets/images/adecco-australia-theme/common/logo.png";
import adeccoAustraliaMainLogoColored from "../assets/images/adecco-australia-theme/common/logo-color.png";
import akkodisMainLogoColored from "../assets/images/akkodis-theme/common/logo-color.png";
import akkodisMainLogo from "../assets/images/akkodis-theme/common/logo.png";
import disagMainLogo from "../assets/images/disag-theme/common/logo.png";
import disagMainLogoColored from "../assets/images/disag-theme/common/logo-color.png";
import disConsultingMainLogo from "../assets/images/dis-consulting-theme/common/logo.png";
import { CAMMIO_BRANDING_ID_ADECCO_THEME } from "../constants";
export function getMainLogo(theme: string, coloredLogo?: boolean) {
  let currentBrand = BRANDS_LOGOS.find((brand) => brand.name === theme);
  return coloredLogo ? currentBrand?.mainLogoColored : currentBrand?.mainLogo;
}

export function getBrandName(theme: string) {
  let currentBrand = BRANDS_SETTINGS.find((brand: { name: string }) => brand.name === theme);
  return currentBrand?.displayName || currentBrand?.officialName;
}

export const BRANDS_LOGOS = [
  {
    id: 5,
    name: "adecco-group-theme",
    mainLogo: adeccoGroupMainLogo,
    mainLogoColored: adeccoGroupMainLogoColored,
  },
  {
    id: 4,
    name: "adecco-medical-theme",
    mainLogo: adeccoMedicalMainLogo,
    mainLogoColored: adeccoMedicalMainLogoColored,
  },
  {
    id: 2,
    name: "adecco-theme",
    mainLogo: adeccoMainLogo2,
    mainLogoColored: adeccoMainLogo,
  },
  {
    id: 83,
    name: "adia-theme",
    mainLogo: adiaMainLogo,
    mainLogoColored: adiaMainLogo,
  },
  {
    id: 75,
    name: "bc-theme",
    mainLogo: bcMainLogo,
    mainLogoColored: bcMainLogoColored,
  },
  {
    id: 76,
    name: "disag-theme",
    mainLogo: disagMainLogo,
    mainLogoColored: disagMainLogoColored,
  },
  {
    id: 311,
    name: "dis-consulting-theme",
    mainLogo: disConsultingMainLogo,
    mainLogoColored: disConsultingMainLogo,
  },
  {
    id: 82,
    name: "europe-assistant-theme",
    mainLogo: adeccoMainLogo,
    mainLogoColored: adeccoMainLogo,
  },
  {
    id: 99,
    name: "focore",
    mainLogo: adeccoMainLogo,
    mainLogoColored: adeccoMainLogo,
  },
  {
    id: 84,
    name: "general-assembly-theme",
    mainLogo: generalAssemblyMainLogo,
    mainLogoColored: generalAssemblyMainLogo,
  },
  {
    id: 85,
    name: "hired-theme",
    mainLogo: hiredMainLogo,
    mainLogoColored: hiredMainLogo,
  },
  {
    id: 77,
    name: "lhh-theme",
    mainLogo: lhhMainLogo,
    mainLogoColored: lhhMainLogoClored,
  },
  {
    id: 771,
    name: "bc-lhh-theme",
    mainLogo: lhhMainLogo,
    mainLogoColored: lhhMainLogoClored,
  },
  {
    id: 772,
    name: "spring-lhh-theme",
    mainLogo: lhhMainLogo,
    mainLogoColored: lhhMainLogoClored,
  },
  {
    id: 78,
    name: "modis-theme",
    mainLogo: modisMainLogo,
    mainLogoColored: modisMainLogoColored,
  },
  {
    id: 79,
    name: "office-angels-theme",
    mainLogo: adeccoMainLogo,
    mainLogoColored: adeccoMainLogo,
  },
  {
    id: 3,
    name: "onsite-theme",
    mainLogo: onsiteMainLogo,
    mainLogoColored: onsiteMainLogoColored,
  },
  {
    id: 80,
    name: "pontoon-theme",
    mainLogo: pontoonMainLogo,
    mainLogoColored: pontoonMainLogo,
  },
  {
    id: 109,
    name: "spring-theme",
    mainLogo: springMainLogo,
    mainLogoColored: springMainLogoColored,
  },
  {
    id: 81,
    name: "vettery-theme",
    mainLogo: vetteryMainLogo,
    mainLogoColored: vetteryMainLogo,
  },
  {
    id: 17,
    name: "proserv-theme",
    mainLogo: proservMainLogo,
    mainLogoColored: proservMainLogoColored,
  },
  {
    id: 18,
    name: "adecco-business-solutions-theme",
    mainLogo: adeccoBusinessSolutionsMainLogo,
    mainLogoColored: adeccoBusinessSolutionsMainLogo,
  },
  {
    id: 19,
    name: "adecco-service-center-solutions-theme",
    mainLogo: adeccoServiceCenterSolutionsMainLogo,
    mainLogoColored: adeccoServiceCenterSolutionsMainLogo,
  },
  {
    id: 20,
    name: "adecco-australia-theme",
    mainLogo: adeccoAustraliaMainLogo,
    mainLogoColored: adeccoAustraliaMainLogoColored,
  },
  {
    id: 21,
    name: "akkodis-theme",
    mainLogo: akkodisMainLogo,
    mainLogoColored: akkodisMainLogoColored,
  },
];

const LHH = {
  id: 77,
  name: "lhh-theme",
  officialId: 6,
  officialName: "LHH",
  primaryColor: "#6d40a8",
  secondaryColor: "#4f185a",
  backgroundColor: "#4f185a",
  fontColor: "#000",
  fontFamily: "NotoSans",
  brandColor: "#4F185A",
  mainLogo: `url(${lhhMainLogo})`,
  mainLogoColored: `url(${lhhMainLogoClored})`,
  brandImage: "https://i.ibb.co/0Bpd2RY/LHH.png",
  displayName: "LHH",
  cammioBrandingId: {
    PROD: 23683,
  },
};

export const PARTIAL_BRANDS_SETTINGS = [
  {
    id: 5,
    name: "adecco-group-theme",
    officialId: 2,
    officialName: "Adecco Group, The",
    displayName: "The Adecco Group",
    primaryColor: "#5cb8b2",
    secondaryColor: "#48a19b",
    backgroundColor: "#5cb8b2",
    fontColor: "#000",
    fontFamily: "Neutraface",
    brandColor: "#5CB8B2",
    mainLogo: `url(${adeccoGroupMainLogo})`,
    mainLogoColored: `url(${adeccoGroupMainLogoColored})`,
    brandImage: "https://i.ibb.co/4Wjdn6B/tag.png",
    cammioBrandingId: {
      PROD: 22742,
    },
  },
  {
    id: 4,
    name: "adecco-medical-theme",
    officialId: 3,
    officialName: "Adecco Medical",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoMedicalMainLogo})`,
    mainLogoColored: `url(${adeccoMedicalMainLogoColored})`,
    brandImage: "https://fzvcnw.stripocdn.email/content/guids/CABINET_c611b7d76a42c58cb8ce931f2a7e09d1/images/29191587984087888.png",
    cammioBrandingId: {},
  },
  {
    id: 2,
    name: "adecco-theme",
    officialId: 1,
    officialName: "Adecco",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoMainLogo})`,
    mainLogoColored: `url(${adeccoMainLogo})`,
    brandImage: "https://i.ibb.co/80tXSDt/29191587984087888-3.png",
    cammioBrandingId: {
      DEV: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
      UAT: CAMMIO_BRANDING_ID_ADECCO_THEME.UAT,
      PROD: CAMMIO_BRANDING_ID_ADECCO_THEME.PROD,
      TEST: CAMMIO_BRANDING_ID_ADECCO_THEME.TEST,
      LOCAL: CAMMIO_BRANDING_ID_ADECCO_THEME.LOCAL,
    },
  },
  {
    id: 83,
    name: "adia-theme",
    officialId: 13,
    officialName: "Adia",
    primaryColor: "#003d3a",
    secondaryColor: "#00a78d",
    backgroundColor: "#003d3a",
    fontColor: "#000",
    fontFamily: "Roboto",
    brandColor: "#1F907F",
    mainLogo: `url(${adiaMainLogo})`,
    mainLogoColored: `url(${adiaMainLogo})`,
    brandImage: "https://i.ibb.co/y0DbNDp/adia-header.png",
    cammioBrandingId: {
      PROD: 23682,
    },
  },
  {
    id: 75,
    name: "bc-theme",
    officialId: 4,
    officialName: "Badenoch & Clark",
    primaryColor: "#002A2A",
    secondaryColor: "#c6aa76",
    backgroundColor: "#002a2a",
    fontColor: "#000",
    fontFamily: "QuattrocentoSans",
    brandColor: "#002A2A",
    mainLogo: `url(${bcMainLogo})`,
    mainLogoColored: `url(${bcMainLogoColored})`,
    brandImage: "https://fzvcnw.stripocdn.email/content/guids/CABINET_740abdd5c5a7c3304191bdbf64f3d12c/images/83571590128491058.png",
    cammioBrandingId: {
      DEV: 15483,
      UAT: 20041,
      PROD: 20039,
      TEST: 15485,
      LOCAL: 15483,
    },
  },
  {
    id: 76,
    name: "disag-theme",
    officialId: 5,
    officialName: "DIS AG",
    primaryColor: "#5cb8b2",
    secondaryColor: "#48a19b",
    backgroundColor: "#5cb8b2",
    fontColor: "#000",
    fontFamily: "Kievit",
    brandColor: "#5cb8b2",
    mainLogo: `url(${disagMainLogo})`,
    mainLogoColored: `url(${disagMainLogoColored})`,
    brandImage: "https://i.ibb.co/9gP7yVx/DIS-Logo-rot.png",
    cammioBrandingId: {},
  },
  {
    id: 311,
    name: "dis-consulting-theme",
    officialId: 31,
    officialName: "DIS Consulting",
    primaryColor: "#5cb8b2",
    secondaryColor: "#48a19b",
    backgroundColor: "#5cb8b2",
    fontColor: "#000",
    fontFamily: "Kievit",
    brandColor: "#5cb8b2",
    mainLogo: `url(${disConsultingMainLogo})`,
    mainLogoColored: `url(${disConsultingMainLogo})`,
    brandImage: "https://i.ibb.co/LvM0FXT/dis-consulting-logo.png",
    cammioBrandingId: {},
  },
  {
    id: 82,
    name: "europe-assistant-theme",
    officialId: 12,
    officialName: "Europe Assistant",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoMainLogo})`,
    mainLogoColored: `url(${adeccoMainLogo})`,
    brandImage: "https://fzvcnw.stripocdn.email/content/guids/CABINET_c611b7d76a42c58cb8ce931f2a7e09d1/images/29191587984087888.png",
    cammioBrandingId: {},
  },
  {
    id: 99,
    name: "focore",
    officialId: 99,
    officialName: "FOCORE",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#5CB8B2",
    mainLogo: `url(${adeccoMainLogo})`,
    mainLogoColored: `url(${adeccoMainLogo})`,
    brandImage: "https://i.ibb.co/4Wjdn6B/tag.png",
    cammioBrandingId: {},
  },
  {
    id: 84,
    name: "general-assembly-theme",
    officialId: 14,
    officialName: "General Assembly",
    primaryColor: "#0e8390",
    secondaryColor: "#e51b24",
    backgroundColor: "#0e8390",
    fontColor: "#000",
    fontFamily: "CircularStd",
    brandColor: "#FA4542",
    mainLogo: `url(${generalAssemblyMainLogo})`,
    mainLogoColored: `url(${generalAssemblyMainLogo})`,
    brandImage: "https://i.ibb.co/wcKXX2T/GA.png",
    cammioBrandingId: {
      PROD: 23684,
    },
  },
  {
    id: 85,
    name: "hired-theme",
    officialId: 16,
    officialName: "Hired",
    primaryColor: "#6e5aea",
    secondaryColor: "#47249b",
    backgroundColor: "#6e5aea",
    fontColor: "#000",
    fontFamily: "Roboto",
    brandColor: "#6E5AEA",
    mainLogo: `url(${hiredMainLogo})`,
    mainLogoColored: `url(${hiredMainLogo})`,
    brandImage: "https://i.ibb.co/Rz8SLBp/hired-fondo-png.png",
    cammioBrandingId: {
      PROD: 24084,
    },
  },
  { ...LHH },
  {
    id: 78,
    name: "modis-theme",
    officialId: 7,
    officialName: "Modis",
    primaryColor: "#000000",
    secondaryColor: "#ffb81c",
    backgroundColor: "#373a36",
    fontColor: "#000",
    fontFamily: "",
    brandColor: "#FFB81C",
    mainLogo: `url(${modisMainLogo})`,
    mainLogoColored: `url(${modisMainLogoColored})`,
    brandImage: "https://i.ibb.co/Y88Tq1r/modis2.png",
    cammioBrandingId: {
      PROD: 23282,
    },
  },
  {
    id: 79,
    name: "office-angels-theme",
    officialId: 8,
    officialName: "Office Angels",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoMainLogo})`,
    mainLogoColored: `url(${adeccoMainLogo})`,
    brandImage: "https://fzvcnw.stripocdn.email/content/guids/CABINET_c611b7d76a42c58cb8ce931f2a7e09d1/images/29191587984087888.png",
    cammioBrandingId: {},
  },
  {
    id: 3,
    name: "onsite-theme",
    officialId: 15,
    officialName: "Adecco OnSite",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${onsiteMainLogo})`,
    mainLogoColored: `url(${onsiteMainLogoColored})`,
    brandImage: "https://fzvcnw.stripocdn.email/content/guids/CABINET_c611b7d76a42c58cb8ce931f2a7e09d1/images/29191587984087888.png",
    cammioBrandingId: {},
  },
  {
    id: 80,
    name: "pontoon-theme",
    officialId: 9,
    officialName: "Pontoon",
    primaryColor: "#221f20",
    secondaryColor: "#8d0e57",
    backgroundColor: "#8d0e57",
    fontColor: "#000",
    fontFamily: "OpenSans",
    brandColor: "#222",
    mainLogo: `url(${pontoonMainLogo})`,
    mainLogoColored: `url(${pontoonMainLogo})`,
    brandImage: "https://i.ibb.co/BZkVHbY/pontoon-fondo-png.png",
    cammioBrandingId: {
      PROD: 24083,
    },
  },
  {
    id: 109,
    name: "spring-theme",
    officialId: 10,
    officialName: "Spring Professional",
    primaryColor: "#004990",
    secondaryColor: "#FFF",
    backgroundColor: "#042466",
    fontColor: "#000",
    fontFamily: "Colfax",
    brandColor: "#004990",
    brandImage: "https://fzvcnw.stripocdn.email/content/guids/CABINET_740abdd5c5a7c3304191bdbf64f3d12c/images/16361590127733888.png",
    mainLogo: `url(${springMainLogo})`,
    mainLogoColored: `url(${springMainLogoColored})`,
    cammioBrandingId: {
      DEV: 15484,
      UAT: 20042,
      PROD: 20040,
      TEST: 15486,
      LOCAL: 15484,
    },
  },
  {
    id: 81,
    name: "vettery-theme",
    officialId: 11,
    officialName: "Vettery",
    primaryColor: "#0D9DDB",
    secondaryColor: "#000",
    backgroundColor: "#0D9DDB",
    fontColor: "#000",
    fontFamily: "Roboto",
    brandColor: "#0D9DDB",
    mainLogo: `url(${vetteryMainLogo})`,
    mainLogoColored: `url(${vetteryMainLogo})`,
    brandImage: "https://i.ibb.co/DbpCMRJ/vettery-header.png",
    cammioBrandingId: {
      PROD: 23685,
    },
  },
  {
    id: 17,
    name: "proserv-theme",
    officialId: 17,
    officialName: "ProServ",
    primaryColor: "#001d67",
    secondaryColor: "#ffd200",
    backgroundColor: "#001d67",
    fontColor: "#000",
    fontFamily: "Roboto",
    brandColor: "#DA291C",
    mainLogo: `url(${proservMainLogo})`,
    mainLogoColored: `url(${proservMainLogoColored})`,
    brandImage: "https://i.ibb.co/80tXSDt/29191587984087888-3.png",
    cammioBrandingId: {
      DEV: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
      UAT: CAMMIO_BRANDING_ID_ADECCO_THEME.UAT,
      PROD: CAMMIO_BRANDING_ID_ADECCO_THEME.PROD,
      TEST: CAMMIO_BRANDING_ID_ADECCO_THEME.TEST,
      LOCAL: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
    },
  },
  {
    id: 18,
    name: "adecco-business-solutions-theme",
    officialId: 18,
    officialName: "Adecco Business Solutions",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoBusinessSolutionsMainLogo})`,
    mainLogoColored: `url(${adeccoBusinessSolutionsMainLogo})`,
    brandImage: "https://i.ibb.co/80tXSDt/29191587984087888-3.png",
    cammioBrandingId: {
      DEV: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
      UAT: CAMMIO_BRANDING_ID_ADECCO_THEME.UAT,
      PROD: CAMMIO_BRANDING_ID_ADECCO_THEME.PROD,
      TEST: CAMMIO_BRANDING_ID_ADECCO_THEME.TEST,
      LOCAL: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
    },
  },
  {
    id: 19,
    name: "adecco-service-center-solutions-theme",
    officialId: 19,
    officialName: "Adecco Service Center Solutions",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoServiceCenterSolutionsMainLogo})`,
    mainLogoColored: `url(${adeccoServiceCenterSolutionsMainLogo})`,
    brandImage: "https://i.ibb.co/80tXSDt/29191587984087888-3.png",
    cammioBrandingId: {
      DEV: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
      UAT: CAMMIO_BRANDING_ID_ADECCO_THEME.UAT,
      PROD: CAMMIO_BRANDING_ID_ADECCO_THEME.PROD,
      TEST: CAMMIO_BRANDING_ID_ADECCO_THEME.TEST,
      LOCAL: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
    },
  },
  {
    id: 20,
    name: "adecco-australia-theme",
    officialId: 20,
    officialName: "Adecco",
    primaryColor: "#DA291C",
    secondaryColor: "#bf0d3e",
    backgroundColor: "#DA291C",
    fontColor: "#000",
    fontFamily: "HouschkaHead",
    brandColor: "#DA291C",
    mainLogo: `url(${adeccoAustraliaMainLogo})`,
    mainLogoColored: `url(${adeccoAustraliaMainLogoColored})`,
    brandImage: "https://i.ibb.co/80tXSDt/29191587984087888-3.png",
    cammioBrandingId: {
      DEV: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
      UAT: CAMMIO_BRANDING_ID_ADECCO_THEME.UAT,
      PROD: CAMMIO_BRANDING_ID_ADECCO_THEME.PROD,
      TEST: CAMMIO_BRANDING_ID_ADECCO_THEME.TEST,
      LOCAL: CAMMIO_BRANDING_ID_ADECCO_THEME.DEV,
    },
  },
  {
    id: 21,
    name: "akkodis-theme",
    officialId: 121,
    officialName: "Akkodis",
    primaryColor: "#1C304B",
    secondaryColor: "#FFB81C",
    backgroundColor: "#1C304B",
    candidatePublicBackgroundStart: "#FFFFFF",
    candidatePublicBackgroundEnd: "#FFFFFF",
    candidatePublicArrow: "none",
    fontColor: "#000",
    fontFamily: "Tiempos Text",
    brandColor: "#FFB81C",
    mainLogo: `url(${akkodisMainLogo})`,
    mainLogoColored: `url(${akkodisMainLogoColored})`,
    brandImage: "https://i.ibb.co/80tXSDt/29191587984087888-3.png",
    cammioBrandingId: {
      DEV: 18084,
      UAT: 28734,
      PROD: 28733,
      TEST: 18085,
      LOCAL: 18084,
    },
  },
];
/*rebrand #59112*/
/*rebrand #59114*/
const BC_LHH = { ...LHH, name: "bc-lhh-theme", officialName: "B&C LHH", code: "B&C LHH", externalCode: "Badenoch + Clark", displayName: "bc-lhh-theme" };
const SPRING_LHH = { ...LHH, name: "spring-lhh-theme" };
// , officialName: "Spring LHH", code: "Spring LHH", externalCode: "Spring Professional", displayName: "spring-lhh-theme" };
export const BRANDS_SETTINGS: any = [...PARTIAL_BRANDS_SETTINGS, BC_LHH, SPRING_LHH];
