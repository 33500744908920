import React, { ReactElement, createRef, useState } from "react";

import * as Api from "../../../../api";

import { toLiteral } from "../../../../helper/locale-utils";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@adeccoux/tag-ds/modal";
import Rating from "../../../shared/forms/rating/rating";
import { Form, serializeForm, TextArea } from "../../../shared/forms";
import SelectFilter, { Option } from "../../../shared/forms/select-filter/select-filter";

import { UserFeedback } from "../../../../types";

import "./user-feedback-modal.scss";
import { getTagDSTheme } from "../../../../utilities/theme";
import store from "../../../../redux/store";

export const commentOptions = [
  { id: "", name: "-" },
  { id: "recruiterExperience", name: toLiteral({ id: "user.feedback.option.recruiterExperience" }) },
  { id: "candidateExperience", name: toLiteral({ id: "user.feedback.option.candidateExperience" }) },
  { id: "recruitmentProcessOptimization", name: toLiteral({ id: "user.feedback.option.recruitmentProcessOptimization" }) },
  { id: "enhancements", name: toLiteral({ id: "user.feedback.option.enhancements" }) },
  { id: "others", name: toLiteral({ id: "user.feedback.option.others" }) },
];

/**
 * Component that renders the internal page
 */
const UserFeedbackModal: React.FC<any> = (props): ReactElement => {
  const { userFeedback, setUserFeedback } = props;

  const [scoreModified, setScoreModified] = useState<boolean>(false);
  const [score, setScore] = useState<number>();
  const [formData, setFormData] = useState<any>();

  async function saveFeedback(event: any) {
    const isValid_HTML = event?.target?.isValid;
    const values_HTML = serializeForm(event?.target);
    if (isValid_HTML) {
      const dismiss = event?.submitter?.value === "dismiss";
      const newFeedback: UserFeedback = {
        score: values_HTML?.score ? parseInt(values_HTML?.score) : undefined,
        comment: !dismiss ? values_HTML?.comment : undefined,
        commentOption: !dismiss ? values_HTML?.commentOption : undefined,
        dismiss: dismiss,
      };
      await postUserFeedback(newFeedback);
    }
  }

  function closeModal() {
    const newFeedback: UserFeedback = {
      score: formData?.score ? parseInt(formData?.score) : undefined,
      dismiss: true,
    };
    postUserFeedback(newFeedback);
  }

  async function postUserFeedback(feedback: UserFeedback) {
    try {
      await Api.postUserFeedback(feedback);
    } catch (error) {
      console.error(error);
    }
    setUserFeedback(false);
  }

  const handleRatingChange = (rate: number) => {
    setScore(rate);
    setScoreModified(true);
  };

  const renderScoreSlider = () => {
    return (
      <div>
        <p className="flex justify-content_center">{toLiteral({ id: "user.feedback.score.title" })}</p>
        <Rating
          name="score"
          amount={10}
          onRate={(rate: number) => handleRatingChange(rate)}
          ratingLabel={{ left: toLiteral({ id: "user.feedback.not.likely" }), right: toLiteral({ id: "user.feedback.highly.likely" }) }}
        />
        <hr className="mt3 mb3" />
      </div>
    );
  };

  const renderTextAreaTitle = (_score?: number) => {
    if (_score === undefined) return "";
    if (_score <= 3) return toLiteral({ id: "user.feedback.comment.title.0.3" });
    else if (_score >= 4 && _score <= 7) return toLiteral({ id: "user.feedback.comment.title" });
    else return toLiteral({ id: "user.feedback.comment.title.8.10" });
  };

  const formRef: any = createRef();
  const handleOnChange = async () => {
    const _form = formRef?.current as Form;
    const formValues = _form?.serialize();
    console.log(formValues);
    setFormData(formValues);
  };

  return (
    <Modal
      id="user-feedback-modal"
      renderAsPortal
      open={userFeedback}
      onClose={() => closeModal()}
      className={`tag-ds ${getTagDSTheme(store?.getState()?.tenant?.selectedTenant?.theme)} -feedback-modal`}
    >
      <ModalHeader className="flex_col">
        <h3>{toLiteral({ id: "user.feedback.title" })}</h3>
        <h5 className="-font-weight-bold mt1">{toLiteral({ id: "user.feedback.title.disclaimer" })}</h5>
      </ModalHeader>
      <hr className="mt2 mb2" />
      <Form ref={formRef} onChange={handleOnChange} onSubmit={saveFeedback}>
        <ModalBody>
          {renderScoreSlider()}
          {scoreModified && (
            <>
              <TextArea label={renderTextAreaTitle(score)} placeholder={toLiteral({ id: "user.feedback.comment.placeholder" })} name="comment" className="mb3" type="text" />
              <SelectFilter
                name="commentOption"
                className="mb2"
                label={toLiteral({ id: "user.feedback.commentOption.label" })}
                placeholder={toLiteral({ id: "user.feedback.commentOption.placeholder" })}
                validateOnChange={true}
              >
                {commentOptions?.map((option: any, i: number) => {
                  return <Option key={i} value={option.id} label={option.name} />;
                })}
              </SelectFilter>
              <div>
                <p className="-feedback-remember"> {toLiteral({ id: "user.feedback.persistance" })}</p>
              </div>
            </>
          )}
        </ModalBody>
        {scoreModified && (
          <ModalFooter>
            <button id="dismiss" value="dismiss" className="small button-secondary" type="submit">
              {toLiteral({ id: "user.feedback.dismiss" })}
            </button>
            <button disabled={!formData?.comment || !formData?.commentOption} id="save" value="save" className="small button-primary" type="submit">
              {toLiteral({ id: "user.feedback.save" })}
            </button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export default UserFeedbackModal;
