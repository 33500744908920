import { STORAGE_BASE_PATH } from "../constants";
import { ClientBrand } from "../types";

export function applyThemeCobrandResult(result: any) {
  const cobrand = result?.coBrand;
  applyThemeCobrand(cobrand);
}

export function applyThemeClientBrandResult(result: any) {
  applyThemeClientBrand(result?.clientBrand);
}

const applyThemeByVariables = (themeData: {
  primaryColor?: string;
  secondaryColor?: string;
  mainLogo?: string;
  mainLogoColored?: string;
  fontFamily?: string;
  fontColor?: string;
  cssVarSubname?: string;
  backgroundColor?: string;
  candidatePublicBackgroundStart?: string;
  candidatePublicBackgroundEnd?: string;
  candidatePublicArrow?: string;
}) => {
  // If the primary color same as font color, the secondary color will be used for the primary button
  const primaryButtonColor = themeData.fontColor && themeData.primaryColor?.startsWith(themeData.fontColor) ? themeData.secondaryColor : themeData.primaryColor;
  /*PRIMARY COLOUR*/
  if (themeData.primaryColor) {
    applyValueToCSSVariable("--candidatePublicBackgroundStart" + (themeData.cssVarSubname || ""), themeData.candidatePublicBackgroundStart ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonPrimaryActiveColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonPrimaryBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonSecondaryBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--primaryButtonColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--primaryButtonBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--componentColor" + (themeData.cssVarSubname || ""), themeData.primaryColor);
  }
  /*ALMOST PRIMARY COLOUR*/
  if (themeData.primaryColor) {
    applyValueToCSSVariable("--primaryButtonHoverColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--primaryButtonHoverBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonSecondaryActiveFontColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonPrimaryHoverColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonPrimaryHoverBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonSecondaryHoverBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--buttonSecondaryHoverFontColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--secondaryButtonFontColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--secondaryButtonBorderColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
    applyValueToCSSVariable("--secondaryButtonHoverFontColor" + (themeData.cssVarSubname || ""), primaryButtonColor ?? themeData.primaryColor);
  }

  if (themeData.backgroundColor) applyValueToCSSVariable("--candidatePublicBackgroundEnd" + (themeData.cssVarSubname || ""), themeData.candidatePublicBackgroundEnd ?? themeData.backgroundColor);

  /*Font family*/
  if (themeData.fontFamily) applyValueToCSSVariable("--fontFamily" + (themeData.cssVarSubname || ""), themeData.fontFamily);

  /*Secondary colour*/
  if (themeData.secondaryColor) {
    applyValueToCSSVariable("--secondaryColor" + (themeData.cssVarSubname || ""), themeData.secondaryColor);
    applyValueToCSSVariable("--secondaryButtonHoverBorderColor" + (themeData.cssVarSubname || ""), themeData.secondaryColor);
  }

  /*fontColor*/
  if (themeData.fontColor) applyValueToCSSVariable("--fontColor" + (themeData.cssVarSubname || ""), themeData.fontColor);

  /*fixes and simplifications*/
  applyValueToCSSVariable("--candidateDesktopArrowTop" + (themeData.cssVarSubname || ""), "none");
  applyValueToCSSVariable("--candidateDesktopArrowBottom" + (themeData.cssVarSubname || ""), "none");
  if(themeData.candidatePublicArrow){
    applyValueToCSSVariable("--candidateLightArrowTop" + (themeData.cssVarSubname || ""),  themeData.candidatePublicArrow);
    applyValueToCSSVariable("--candidateLightArrowBottom" + (themeData.cssVarSubname || ""),  themeData.candidatePublicArrow);
  }
 

  /*Logos*/
  if (themeData.mainLogo) applyValueToCSSVariable("--mainLogo" + (themeData.cssVarSubname || ""), themeData.mainLogo);
  if (themeData.mainLogoColored) applyValueToCSSVariable("--mainLogoColored" + (themeData.cssVarSubname || ""), themeData.mainLogoColored);
};

export const applyThemeCobrand = (cobrand: any, cssVarSubname?: string) =>
  applyThemeByVariables({
    cssVarSubname,
    primaryColor: cobrand?.variables?.primaryColor || cobrand?.primaryColor,
    secondaryColor: cobrand?.variables?.secondaryColor || cobrand?.secondaryColor,
    fontColor: cobrand?.variables?.fontColor || cobrand?.fontColor,
    fontFamily: cobrand?.variables?.fontFamily || cobrand?.fontFamily,
    mainLogo: cobrand?.variables?.mainLogo || cobrand?.mainLogo,
    mainLogoColored: cobrand?.variables?.mainLogoColored || cobrand?.mainLogoColored,
    backgroundColor: cobrand?.variables?.backgroundColor || cobrand?.backgroundColor,
    candidatePublicBackgroundStart: cobrand?.variables?.candidatePublicBackgroundStart,
    candidatePublicBackgroundEnd: cobrand?.variables?.candidatePublicBackgroundEnd,
    candidatePublicArrow: cobrand?.variables?.candidatePublicArrow,
  });

export const applyThemeClientBrand = (clientBrand: ClientBrand, cssVarSubname?: string) =>
  applyThemeByVariables({
    cssVarSubname,
    primaryColor: clientBrand?.brandData?.primaryColor,
    secondaryColor: clientBrand?.brandData?.secondaryColor,
    mainLogo: getClientLogoCssVar(clientBrand?.brandData?.primaryLogo),
    mainLogoColored: getClientLogoCssVar(clientBrand?.brandData?.secondaryLogo),
    backgroundColor: clientBrand?.brandData?.primaryColor,
  });

export function applyValueToCSSVariable(variable: string, value: string) {
  document?.getElementById("recruitment-connect-container")?.style?.setProperty(variable, value);
}

export function getClientLogoUrl(url: string) {
  if (url) {
    let urlBase = STORAGE_BASE_PATH + "/logos/";
    urlBase += url;
    return urlBase;
  }
}

const getClientLogoCssVar = (url: string) => `url(${getClientLogoUrl(url)})`;
