import allFields from "../../tenantsData/formFields";
import store from "../../../../redux/store";
import { isPublishJobSchedule } from "../../candidate-sourcing-service";
import { toLiteral } from "../../../../helper/locale-utils";

export interface IField {
  id: string;
  label: string;
  labelSmall: string;
  name: string;
  position: string;
  tooltip?: string;
  type?: string;
  visible?: boolean;
  disabled?: boolean;
  disableRelated?: string[];
  requireRelated?: string[];
  valueRelated?: string[];
  placeholder?: string;
  validations?: string[] | never[];
  options?: SelectFieldOption[];
  multiple?: boolean;
  isSimple?: boolean; // Text save how option (Select)
  disableFutureDates?: boolean;
  disablePastDates?: boolean;
  value?: string;
  customFieldProps?: any;
  configuration?: boolean;
  blockForUsers?: boolean;
  notDisabledForSuperAdmin?: boolean;
  emptyOption?: boolean;
  customOnChange?: string;
  defaultValue?: number | string;
  readonly?: boolean;
}

export interface SelectFieldOption {
  label: string;
  value: string;
}

export interface ITenantFields {
  tenantId: string;
  fields: IField[] | never | undefined;
}

export const maxSectionsReached = (selected: any[], allOptions: any[]) => {
  const _selected = selected || [];
  const _allOptions = allOptions || [];
  // All posible sections to select have one select, not show more
  const allOptionsShow = _allOptions.filter((allOption) => allOption.show !== false);
  const selectedShow = _selected.filter((select) => select?.title === "" || allOptionsShow.findIndex((option) => option.key === select?.title) !== -1);
  return selectedShow.length === allOptionsShow.length;
};

export const optionAvaibleToSelect = (selected: any[], allOptions: any[]): boolean => {
  const _selected = selected || [];
  const _allOptions = allOptions || [];
  let allOptionsSelected = _selected?.length === 0 && _allOptions?.length > 0 ? false : true;
  _allOptions.forEach((option) => {
    // Not take in account the not showed sections
    if (option?.show !== false) {
      const find = _selected.find((optionSelected) => option.key === optionSelected?.title);
      if (!find) allOptionsSelected = false;
    }
  });
  return !allOptionsSelected; // If not all options are selected, it is avaible
};
export const getOptionFieldLabel = (fieldName: string, optionValue: string): string => {
  const _field = getTenantField(fieldName);
  if (!_field) return optionValue;
  const _option = _field?.options?.find((_opt) => _opt?.value === optionValue);
  if (!_option) return optionValue;
  return toLiteral({ id: _option?.label });
};

export const getTenantField = (name: string): IField | undefined => {
  let _field: IField | undefined = undefined;
  const fields = getTenantFields();
  _field = fields?.find((field) => field.name === name);
  return _field;
};
export const getTenantFields = (position: string = "allFields"): IField[] | undefined => {
  let tenantID = store.getState().tenant.selectedTenant.alias;
  const _allTenants: any = allFields;
  let _selectedTenant: ITenantFields | undefined = undefined;
  let _displayedFields: IField[] | undefined = undefined;
  if (tenantID) {
    _selectedTenant = _allTenants && _allTenants.find((a: ITenantFields) => a.tenantId === tenantID);
    //if there isn't fields for this tenant select default
    _selectedTenant = _selectedTenant ? _selectedTenant : _allTenants.find((a: ITenantFields) => a.tenantId === "default");
    if (_selectedTenant) {
      if (position !== "allFields") {
        _displayedFields = _selectedTenant.fields && _selectedTenant.fields.filter((a) => a.position === position);
      } else {
        _displayedFields = _selectedTenant.fields ? _selectedTenant.fields : _displayedFields;
      }
    }
  }
  return _displayedFields;
};

export const getJobDataComplete = (jobDataFields: any) => {
  const leftValues = getTenantFields("step-1-left");
  const rightValues = getTenantFields("step-1-right");
  let requiredValues: any[] = leftValues ? leftValues.filter((leftValue) => leftValue.visible !== false && leftValue.validations && (leftValue.validations as string[]).includes("required")) : [];
  requiredValues = requiredValues.concat(
    rightValues ? rightValues.filter((rightValue) => rightValue.visible !== false && rightValue.validations && (rightValue.validations as string[]).includes("required")) : []
  );
  requiredValues = requiredValues.map((requiredValue: any) => requiredValue.name);
  for (const value of requiredValues) {
    if (value === "dataApplyURL") {
      const valueField = jobDataFields?.dataCustomFields?.find((field: any) => field?.key === value);
      if (!valueField || !valueField?.value) return false;
    } else {
      if (!jobDataFields[`${value}`]) return false;
    }
  }
  return true;
};
export const getJobDescriptionComplete = (jobDescriptionFields: any) => {
  const leftValues = getTenantFields("step-2-left");
  let requiredValues: any[] = leftValues ? leftValues.filter((leftValue) => leftValue.visible !== false && leftValue.validations && (leftValue.validations as string[]).includes("required")) : [];
  requiredValues = requiredValues.map((requiredValue: any) => requiredValue.name);
  for (const value of requiredValues) {
    if (!jobDescriptionFields[`${value}`]) {
      return false;
    }
  }
  return true;
};
export const getJobPostingComplete = (statusId: number) => {
  return isPublishJobSchedule(statusId);
};
