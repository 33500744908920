import React, { ReactElement } from "react";

import Routes from "../router/routes";

/**
 * Component that renders the public page
 */
const LandingContainer: React.FC<any> = (props): ReactElement => {
  return (
    <div id="landing-container">
      <Routes onShowSnackbar={props.onShowSnackbar} getAppInsights={props.getAppInsights} />
    </div>
  );
};

export default LandingContainer;
