import React, { ReactElement, useEffect, useState } from "react";
import { CopilotActionSteps } from "../copilot-actions";
import { Loader } from "@adeccoux/tag-ds";
import { toLiteral } from "../../../../../helper/locale-utils";

/**
 * Component that renders the simple options answer type
 */
const SimpleOptionAnswer: React.FC<any> = (props: any): ReactElement => {

    const { dialogStep, triggerAction } = props;

    const [answerOptions, setAnswerOptions] = useState<any[]>()

    useEffect(() => {
        if (dialogStep?.answers) {
            const isPredefinedAnswers = Array.isArray(dialogStep?.answers)
            // If answers is already a predefined array, set it as the final options, else, fetch them
            if (isPredefinedAnswers) setAnswerOptions(dialogStep?.answers)
            else fetchAnswers(dialogStep?.answers)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogStep])

    async function fetchAnswers(answersFunction: Function) {
        try {
            const answers = await answersFunction()
            setAnswerOptions(answers)
        } catch (e) {
            setAnswerOptions([])
        }
    }

    const renderAnswerContainer = (_dialogStep: CopilotActionSteps, _answerOptions?: any[]) => {
        if (!_answerOptions) {
            return <Loader />
        } else {
            return (
                <React.Fragment>
                    {_answerOptions?.map((answer) => (
                        <button className="chip ml1" key={answer?.value} onClick={() => triggerAction(answer?.value, answer?.nextStep)}>
                            {answer?.icon && <span className="material-icons left mr1">
                                {answer?.icon}
                            </span>}
                            {toLiteral({ id: answer?.label })}
                        </button>
                    ))}
                </React.Fragment>
            );
        }

    }

    return (
        <div className="flex">
            {renderAnswerContainer(dialogStep, answerOptions)}
        </div>
    );
};

export default SimpleOptionAnswer;
