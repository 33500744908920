import { ContextAction } from "../../../../../redux/slices/copilotSlice";
import { CopilotActionConditional, CopilotActionSteps } from "../copilot-actions";

export function replaceContextVariables(literal: string, data: any) {
  let _literal = literal;
  Object.keys(data)?.forEach((param) => {
    _literal = _literal?.replace(`{{${param}}}`, data?.[param]);
  });
  return _literal;
}

export function handleSkipStep(contextAction: ContextAction, userContextParamns: any, actionStep?: CopilotActionSteps) {
  if (actionStep?.skip) {
    let skipReason: CopilotActionConditional | undefined;
    actionStep?.skip?.forEach((_skipReason) => {
      if (_skipReason?.values?.includes(userContextParamns?.[_skipReason?.name]) || (!_skipReason?.values && userContextParamns?.[_skipReason?.name])) {
        skipReason = _skipReason;
      }
    });
    // If there is a reason to skip the step, set next step defined on the skippable reason
    if (skipReason) actionStep = contextAction?.steps?.find((step) => step?.id === skipReason?.nextStep);
  }
  return actionStep;
}

export function addAIGeneratedStyle(id: string) {
  setTimeout(() => document?.getElementById(id)?.classList?.add("ai-generated"), 1);
  setTimeout(() => document?.getElementById(id)?.classList?.remove("ai-generated"), 3000);
}
