import { _env } from "../config-env.js";

export const isDev = () => {
  let isDevEnviroment: boolean = false;
  if (_env.REACT_APP_ENV_PREFIX) {
    const env = _env.REACT_APP_ENV_PREFIX;
    isDevEnviroment = ["a", "a2", "d"].includes(env);
  }
  return isDevEnviroment;
};

export const isTest = () => {
  let isTestEnviroment: boolean = false;
  if (_env.REACT_APP_ENV_PREFIX) {
    const env = _env.REACT_APP_ENV_PREFIX;
    isTestEnviroment = ["t", "tau"].includes(env);
  }
  return isTestEnviroment;
};

export const isUAT = () => {
  let isUATEnviroment: boolean = false;
  if (_env.REACT_APP_ENV_PREFIX) {
    const env = _env.REACT_APP_ENV_PREFIX;
    isUATEnviroment = ["u", "uau"].includes(env);
  }
  return isUATEnviroment;
};

export const isProd = () => {
  let isUATEnviroment: boolean = false;
  if (_env.REACT_APP_ENV_PREFIX) {
    const env = _env.REACT_APP_ENV_PREFIX;
    isUATEnviroment = ["p", "pau"].includes(env);
  }
  return isUATEnviroment;
};

export const isIframe = () => window?.location !== window?.parent?.location;
