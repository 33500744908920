import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { toLiteral } from "../../../helper/locale-utils";
import { NavigationState } from "../../../redux/slices/navigationSlice";
import store from "../../../redux/store";
import Breadcrumb, { BreadcrumbItem } from "@adeccoux/tag-ds/breadcrumb";
import { getPageTitle, getParentPage, IRoute, NavItem } from "../../../utilities/navigation";
import { getTagDSTheme } from "../../../utilities/theme";

import "./breadcrumbs.scss";
import { CombinedState } from "../../../redux/store/state";

interface BreadCrumbProps {
  name?: string;
  url?: string;
}
export interface IProps extends RouteComponentProps<any> {
  currentPage?: NavItem;
  pages: any;
  className?: string;
  customPath?: { url: string; name: string }[];
  [others: string]: any;
}

const BreadcrumbsComponent = (props: IProps) => {
  const { pages, currentPage, match, customPath, className, ...rest } = props;
  const state: NavigationState = useSelector((combState: CombinedState) => combState.navigation);
  const history = useHistory();
  const [hierarchy, setHierarchy] = useState<any>([]);

  const external = store.getState()?.auth?.external;

  useEffect(() => {
    renderBreadcrumbHierarchy(state?.breadCrumbNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.breadCrumbNames, currentPage]);

  const getTrueUrl = (url: string) => {
    let urlArr = url.split("/");
    let trueUrl = "";
    urlArr.forEach((partUrl: string, index: number) => {
      if (partUrl.match(/:[^\s/]+/g)) {
        trueUrl += `${match.params[partUrl.replace(":", "")]}${index < urlArr.length - 1 ? "/" : ""}`;
      } else {
        trueUrl += `${partUrl}${index < urlArr.length - 1 ? "/" : ""}`;
      }
    });
    return trueUrl;
  };

  // Build breadcrumb hierarchy
  const renderBreadcrumbHierarchy = (breadCrumbNames: { pageId: string; name: string }[]) => {
    //{name: toLiteral({ id: hasParentPage?.breadcrumb }), url: hasParentPage?.url}
    let currentHierarchy = [];
    currentHierarchy.push(currentPage);
    let hasParentPage = getParentPage(currentPage as IRoute, pages);

    while (hasParentPage) {
      //While hasParent find the
      currentHierarchy.push(hasParentPage);
      hasParentPage = getParentPage(hasParentPage, pages);
    }

    currentHierarchy = [...currentHierarchy]?.reverse();
    const newHierarchy = currentHierarchy?.map((navItem, i) => {
      if (navItem) {
        const routeMatch = match;
        const originalTitle = navItem.breadcrumb;
        let _pageTitle = getPageTitle(navItem, routeMatch);
        //avoid internazionalization  for formated page titles
        if (originalTitle === _pageTitle) {
          _pageTitle = toLiteral({ id: _pageTitle });
        }
        // Replace uuid for names
        const newTitle = breadCrumbNames?.find((breadcrumbName) => breadcrumbName.pageId === _pageTitle);
        _pageTitle = newTitle ? newTitle.name : _pageTitle;
        if (navItem.id === "EDIT_PROCESS") {
          _pageTitle = state?.breadCrumbNames[0]?.phase ? state?.breadCrumbNames[0]?.phase : _pageTitle;
        }
        //Build it

        return { name: _pageTitle, url: currentPage !== navItem ? getTrueUrl(navItem.url) : "#" } as BreadCrumbProps;
      }
    });
    setHierarchy(newHierarchy);
  };

  return (customPath?.length || hierarchy?.length) && currentPage && !external ? (
    <nav aria-label="breadcrumb">
      <Breadcrumb id="breadcrumb" className={`tag-ds ${getTagDSTheme(store.getState()?.tenant?.selectedTenant?.theme)} ${className || ""}`}>
        {(customPath || hierarchy)?.map((child: BreadCrumbProps) => (
          <BreadcrumbItem
            onClick={(e: any) => {
              e.preventDefault();
              history.push(child.url || "#");
            }}
            key={`breadcrumb-hierarchy-${child?.name}`}
            href={child.url || "#"}
            id={`breadcrumb-${child?.name}`}
            title={`${child?.name}`}
            data-testid={rest && rest["data-testid"] ? `${rest["data-testid"]}` : undefined}
          ></BreadcrumbItem>
        ))}
      </Breadcrumb>
    </nav>
  ) : (
    <></>
  );
};

export default withRouter(BreadcrumbsComponent);
