import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { checkAndSetLocale, manageInternalTenant, managePublicTenant } from "../../../../helper/app-helper";
import { toLiteral } from "../../../../helper/locale-utils";

import { NavigationState } from "../../../../redux/slices/navigationSlice";
import { TenantState } from "../../../../redux/slices/tenantSlice";
import { CombinedState } from "../../../../redux/store/state";
import { checkRoleAndPermissions } from "../../../../utilities/authentication";
import { getRoutePath, isPublicOrLogin } from "../../../../utilities/navigation";
import { addNotification } from "../../../shared/notifications/notifications-service";

import Loading from "../../loading/loading";

/**
 * Component that handle the auth context
 */
const TenantHandler: React.FC<any> = (props): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const tenantsState: TenantState = useSelector((state: CombinedState) => state.tenant);
  const navigationState: NavigationState = useSelector((state: CombinedState) => state.navigation);
  const selectedUserProfile = tenantsState.selectedUserProfile;
  const tenants = tenantsState.tenants;
  const selectedTenant = tenantsState?.selectedTenant;
  const tenantId = selectedTenant?.tenant;
  const tenantAlias = selectedTenant?.alias;
  const availableLanguages = selectedTenant?.languages;
  const loadingTenantData = tenantsState?.loadingTenantData;

  const currentRoute = navigationState.currentRoute;
  const _isPublicOrLogin = isPublicOrLogin(currentRoute);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!currentRoute?.public || currentRoute?.id === "LOGIN") {
      // handle privite route tenant
      manageInternalTenant(history, dispatch, selectedTenant, currentRoute);
    } else {
      // handle public route tenant
      // Some landing pages need to skip all tenants checks (the ones that dont have tenant alis, brand or country)
      if (currentRoute?.id === "CAMMIO_LANDING" || currentRoute?.id === "LANDING_SMS_REDIRECT" || currentRoute?.id === "NOT_FOUND") setLoading(false);
      else managePublicTenant(history, dispatch, currentRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To set language when tenant has changed
  useEffect(() => {
    if (tenantId !== -1) {
      checkAndSetLocale(availableLanguages, tenantsState.selectedLanguage, _isPublicOrLogin, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  useEffect(() => {
    if (!currentRoute?.landing && currentRoute?.id !== "NOT_FOUND") {
      setLoading(loadingTenantData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTenantData]);

  // To set language when there are not language loaded
  // And kick loading in public and login pages
  useEffect(() => {
    if (tenantId === -1 && availableLanguages?.length > 0) {
      checkAndSetLocale(availableLanguages, tenantsState.selectedLanguage, _isPublicOrLogin, dispatch);
      if (_isPublicOrLogin) setLoading(false); // Kick loading in public or loading page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isPublicOrLogin, availableLanguages]);

  // To handle regular url redirection when tenants are loaded (Internal)
  useEffect(() => {
    if (tenants.length > 0) setLoading(false);
  }, [tenants]);

  // To handle tenant role and permissions
  useEffect(() => {
    if (tenants.length > 0 && tenantAlias) {
      const hasNoPermission = !checkRoleAndPermissions(tenants, tenantAlias, currentRoute.roles, currentRoute.permissions, selectedUserProfile);
      if (hasNoPermission) {
        addNotification({ type: "error", content: toLiteral({ id: "login.token.authtokenusermissingerror" }), timer: 3 });
        history.push(getRoutePath("RECRUITMENT"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants, tenantAlias, currentRoute.id, selectedUserProfile]);

  return <>{loading ? <Loading id="tenant-handler" /> : props.children}</>;
};

export default TenantHandler;
