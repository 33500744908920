import { _env } from "../config-env";
import React, { useEffect, useRef, useState } from "react";
import { createTelemetryService } from "./telemetryService";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useAppInsightsTracking } from "./useAppInsightsTracking";
import { History } from "history";

interface ITelemetryProvider extends RouteComponentProps {
  history: History;
  children?: React.ReactNode;
  after: Function;
  classTheme: string;
}

const TelemetryProviderWithoutRouter: React.FC<ITelemetryProvider> = ({ history, children, after, classTheme }): JSX.Element => {
  const appInsight: React.MutableRefObject<any> = useRef({});
  const trackActivity = useAppInsightsTracking(appInsight, history);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized && _env.REACT_APP_ENV_PREFIX !== "a") {
      appInsight.current = createTelemetryService(history);
      after();
      setIsInitialized(true);
    }
  }, [after, history, isInitialized]);

  if (!isInitialized) {
    return <div className={classTheme}>{children}</div>;
  }

  return (
    <div onKeyDown={trackActivity} onMouseMove={trackActivity} onScroll={trackActivity} onMouseDown={trackActivity} onTouchStart={trackActivity} onTouchMove={trackActivity} className={classTheme}>
      {children}
    </div>
  );
};

export const TelemetryProvider = withRouter(TelemetryProviderWithoutRouter);
