import React, { ReactElement } from "react";
import "./maintenance-mode.scss";

const MaintenanceMode: React.FC<any> = (): ReactElement => {
  return (
    <div className="maintenance-holder">
      <p>We are under maintenance. We will be back soon with new features.</p>
    </div>
  );
};

export default MaintenanceMode;
