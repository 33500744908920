import { ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR, ROLE_ATTRACTION, ROLE_SCREENING, ROLE_SOURCING, ROLE_USER } from "./../constants/index";
import {
  ADMIN_SECTION,
  CANDIDATE_SOURCING_MODULE,
  CANDIDATE_SOURCING_SECTION,
  HOME_SECTION,
  RECRUITMENT_CONNECT_MODULE,
  RECRUITMENT_CONNECT_SECTION,
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_CS_ADMIN,
} from "../constants";

interface MatchType {
  id_process?: string;
  id_candidate?: string;
}

export const ROUTES = [
  {
    id: "NOT_FOUND",
    nicename: "not-found",
    breadcrumb: null,
    public: true,
  },
  {
    id: "RECRUITER_PORTAL",
    nicename: "",
    section: HOME_SECTION,
  },
  {
    id: "RECRUITMENT",
    nicename: "recruitment",
    breadcrumb: "breadcrumbs.home",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "DETAILS_PROCESS",
    parentId: "RECRUITMENT",
    nicename: "processes/:id_process",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_process"),
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "EDIT_PROCESS",
    parentId: "DETAILS_PROCESS",
    nicename: "edit",
    breadcrumb: "breadcrumbs.edit",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "DETAILS_CANDIDATE",
    parentId: "DETAILS_PROCESS",
    nicename: "candidates/:id_candidate",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_candidate"),
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "LIVE_INTERVIEW_EVALUATION",
    nicename: "interview/:tenant_alias/:id_candidate/:id_liveInterview/:phase_index",
    breadcrumb: "breadcrumbs.liveIntEvaluation",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    fullSizeView: true,
  },
  {
    id: "JOB_LISTING",
    nicename: "sourcing",
    breadcrumb: "breadcrumbs.candidatesourcing",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "JOB_POSTING_LISTING",
    nicename: "job-posting",
    breadcrumb: "breadcrumbs.jobPosting",
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "JOB_DESCRIPTION_GENERATOR",
    nicename: "job-description-generator",
    breadcrumb: "breadcrumbs.jdGenerator",
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "JOB_DESCRIPTION",
    parentId: "JOB_LISTING",
    nicename: ":id",
    breadcrumb: ":id",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: CANDIDATE_SOURCING_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "JOB_DESCRIPTION_LOCAL_TEMPLATE",
    parentId: "JOB_LISTING",
    nicename: "templates/:id",
    breadcrumb: ":id",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "ADMINISTRATOR_HOME",
    nicename: "administrator",
    breadcrumb: "breadcrumbs.admin.home",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  },
  {
    id: "ADMINISTRATOR_USERS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "users",
    breadcrumb: "breadcrumbs.admin.users",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["users"],
  },
  {
    id: "ADMINISTRATOR_TEMPLATES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "templates",
    breadcrumb: "breadcrumbs.admin.templates",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["templates"],
  },
  {
    id: "ADMINISTRATOR_TENANTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "tenants",
    breadcrumb: "breadcrumbs.admin.tenants",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_CATEGORIES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "categories",
    breadcrumb: "breadcrumbs.admin.categories",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["categories"],
  },
  {
    id: "ADMINISTRATOR_LANGUAGES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "languages",
    breadcrumb: "breadcrumbs.admin.languages",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["languages"],
  },
  {
    id: "ADMINISTRATOR_SERVICES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "services",
    breadcrumb: "breadcrumbs.admin.services",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_TERMS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "terms",
    breadcrumb: "breadcrumbs.admin.terms",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["terms"],
  },
  {
    id: "ADMINISTRATOR_COOKIE_BANNER",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "cookie-banner",
    breadcrumb: "breadcrumbs.admin.cookie.banner",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["cookieBanner"],
  },
  {
    id: "ADMINISTRATOR_STATISTICS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "statistics",
    breadcrumb: "breadcrumbs.admin.statistics",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_ACTIVITY",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "activity",
    breadcrumb: "breadcrumbs.admin.activity",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_INDUSTRIES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "industries",
    breadcrumb: "breadcrumbs.admin.industries",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_CATEGORIES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-categories",
    breadcrumb: "breadcrumbs.admin.categories",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_MAILJET",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "notifications",
    breadcrumb: "breadcrumbs.admin.mailProvider",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_SKILLS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-skills",
    breadcrumb: "breadcrumbs.admin.skills",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_LOCAL_SKILLS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "skills",
    breadcrumb: "breadcrumbs.admin.skills",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["skills"],
  },
  {
    id: "ADMINISTRATOR_CLIENTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "clients",
    breadcrumb: "breadcrumbs.admin.clients",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_LOCAL_CLIENTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "local-clients",
    breadcrumb: "breadcrumbs.admin.clients",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["clients"],
  },
  {
    id: "ADMINISTRATOR_LOCAL_CLIENTS_STATS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "clients-stats",
    breadcrumb: "administrator.home.client.stats",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["clientStats"],
  },
  {
    id: "ADMINISTRATOR_TAGS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "tags",
    breadcrumb: "breadcrumbs.admin.tags",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_ADDITIONAL_DOCUMENTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "additional-documents",
    breadcrumb: "breadcrumbs.admin.additional.documents",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["additionalDocuments"],
  },
  {
    id: "ADMINISTRATOR_LOCAL_CANDIDATE_FEEDBACK",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "local-candidate-feedback",
    breadcrumb: "breadcrumbs.admin.local.client.feedback",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["candidateFeedback"],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_CANDIDATE_FEEDBACK",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-candidate-feedback",
    breadcrumb: "breadcrumbs.admin.local.client.feedback",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
    permissions: ["candidateFeedback"],
  },
  {
    id: "ADMINISTRATOR_MANAGE_TENANT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "manage-tenant",
    breadcrumb: "breadcrumbs.admin.manage.tenant",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["tenants"],
  },
  {
    id: "ADMINISTRATOR_OFFER_TEMPLATES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "offer-templates",
    breadcrumb: "breadcrumbs.admin.offer.templates",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["offerTemplates"],
  },
  {
    id: "ADMINISTRATOR_XPERT_CATALOG",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "xpert-catalog",
    breadcrumb: "breadcrumbs.admin.xpert.catalog",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["xpertCatalog"],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_COMPETENCY_FRAMEWORK_SKILL",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "xpert-global-competency-framework-skill",
    breadcrumb: "admin.local.globalCompetencyFrameworkSkill",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_SCREENING_QUESTIONS_LIBRARY",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "screening-questions",
    breadcrumb: "admin.local.screeningQuestions.library",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["screeningQuestions"],
  },
  {
    id: "ADMINISTRATOR_REFERENCE_CHECK_LIBRARY",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "reference-check-library",
    breadcrumb: "admin.local.referenceCheck.library",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["referenceCheck"],
  },
  {
    id: "ADMINISTRATOR_POSITION",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "position",
    breadcrumb: "admin.local.position",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["referenceCheck"],
  },
  {
    id: "ADMINISTRATOR_CONTRACT_TYPE",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "contract-type",
    breadcrumb: "admin.local.contractType",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["referenceCheck"],
  },
  {
    id: "ADMINISTRATOR_SCREENING_QUESTION_TYPES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "screening-question-types",
    breadcrumb: "admin.local.screeningQuestions.types",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_VIDEO_TYPES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "video-types",
    breadcrumb: "admin.local.video.types",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_VIDEO_LIBRARY",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "video-library",
    breadcrumb: "admin.local.videoLibrary",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["videoLibrary"],
  },
  {
    id: "ADMINISTRATOR_LOCAL_RECRUITER_STATS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "recruiters-stats",
    breadcrumb: "administrator.home.recruiter.stats",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["recruiterStats"],
  },
  {
    id: "ADMINISTRATOR_LOCAL_QUESTIONS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "video-questions",
    breadcrumb: "admin.local.video.questions",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["videoQuestions"],
  },
  {
    id: "ADMINISTRATOR_TENANT_TAGS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "tenant-tags",
    breadcrumb: "admin.local.tags",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["tenantTags"],
  },

  {
    id: "ADMINISTRATOR_CALENDAR",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "calendars",
    breadcrumb: "admin.local.calendars",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["calendars"],
  },
  {
    id: "ADMINISTRATOR_CALENDAR_DETAILS",
    parentId: "ADMINISTRATOR_CALENDAR",
    nicename: "details",
    breadcrumb: "Details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  },
  {
    id: "ADMINISTRATOR_SMS_TESTING_TOOL",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "sms-testing-tool",
    breadcrumb: "Details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_CACHE_MANAGER",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "cache-manager",
    breadcrumb: "Details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_DEVELOPER_POCS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "developer-pocs",
    breadcrumb: "Developer POCs",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_TENANT_SCRIPTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "tenant-scripts",
    breadcrumb: "administrator.home.tenant.scripts",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_EVENT_MANAGER",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "events-manager",
    breadcrumb: "Details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_JD_BU_TENANT_MANAGER",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "business-unit-manager",
    breadcrumb: "Details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_HOLIDAY_CALENDAR",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "holiday-calendars",
    breadcrumb: "administrator.local.nonAvailabilityCalendars",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["calendars"],
  },
  {
    id: "ADMINISTRATOR_RELEASE_NOTES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "release-notes-admin",
    breadcrumb: "administrator.local.release.notes",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: [],
  },
  {
    id: "RELEASE_NOTE",
    parentId: "ADMINISTRATOR_RELEASE_NOTES",
    nicename: ":id",
    breadcrumb: ":id",
    section: ADMIN_SECTION,
  },
  {
    id: "RELEASE_NOTES",
    nicename: "release-notes",
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CS_ADMIN, ROLE_USER],
  },
  {
    id: "ADMINISTRATOR_TENANT_EMAILS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "tenant-emails",
    breadcrumb: "admin.local.emails",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["tenants"],
  },
  {
    id: "JOB_DESCRIPTION_BOARDS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "job-desc-boards",
    breadcrumb: "breadcrumbs.jobDescriptionBoards",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_CS_ADMIN],
  },
  {
    id: "JOB_DESCRIPTION_DOWNLOAD_REPORT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "job-desc-report-download",
    breadcrumb: "breadcrumbs.candidatesourcing",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_CS_ADMIN],
  },
  {
    id: "JOB_DESCRIPTION_TEMPLATES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "templates#sourcing",
    breadcrumb: "Job description templates",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: ADMIN_SECTION,
  },
  {
    id: "JOB_DESCRIPTION_TEMPLATE",
    parentId: "JOB_DESCRIPTION_TEMPLATES",
    nicename: ":id",
    breadcrumb: ":id",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: ADMIN_SECTION,
  },
  {
    id: "ADMINISTRATOR_VARIABLES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "variables",
    breadcrumb: "administrator.local.variables",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["variables"],
  },
  {
    id: "ADMINISTRATOR_NOTIFICATION_TEMPLATES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "manage-notification-templates",
    breadcrumb: "administrator.local.notification.status",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["notification"],
  },
  {
    id: "ADMINISTRATOR_SMS_CANDIDATES_STATUS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "sms/candidateStatus",
    breadcrumb: "administrator.local.sms.candidate.status",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["notification"],
  },
  {
    id: "CREATE_PROCESS",
    parentId: "RECRUITMENT",
    nicename: "process/new",
    breadcrumb: "breadcrumbs.creator",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "CREATE_SERVICE",
    parentId: "RECRUITMENT",
    nicename: "service/new",
    breadcrumb: "breadcrumbs.creator.service",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "RECRUITERS_MODULES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "recruiters_modules",
    breadcrumb: "breadcrumbs.admin.recruitersModules",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["userProfileModules"],
  },
  {
    id: "CREATE_SERVICE_TEMPLATE",
    parentId: "RECRUITMENT",
    nicename: "service/new/template",
    breadcrumb: "breadcrumbs.creator.template",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "EDIT_SERVICE",
    parentId: "RECRUITMENT",
    nicename: "service/new/:id_service/:type",
    breadcrumb: "breadcrumbs.edit.service",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "NEW_EDIT_SERVICE",
    parentId: "RECRUITMENT",
    nicename: "service/:id_service/edit",
    breadcrumb: "breadcrumbs.edit.service",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "QUICK_APPLY_VERIFICATION_WEB_PUBLIC",
    nicename: "quick-apply/:tenant_alias/verification",
    public: true,
  },
  {
    id: "QUICK_APPLY_WEB_PUBLIC",
    nicename: "quick-apply/:tenant_alias/:id_job_description",
    public: true,
  },
  {
    id: "CANDIDATE_WEB",
    nicename: "process/:tenant_alias/:id_process/:id_candidate",
    public: true,
  },
  {
    id: "CANDIDATE_WEB_PUBLIC",
    nicename: "process/:tenant_alias/:id_process",
    public: true,
  },
  {
    id: "LIVE_INTERVIEW",
    nicename: "process/:tenant_alias/:id_process/:id_candidate/:id_liveInterview",
    public: true,
  },
  {
    id: "UI_LIBRARY",
    nicename: "ui-library",
  },
  {
    id: "DEVELOPMENT_GUIDELINE",
    nicename: "development-guidelines",
    breadcrumb: "Development Guidelines",
  },
  {
    id: "CALENDARS_PAGE",
    nicename: "calendars",
    breadcrumb: "breadcrumb.calendars",
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "CALENDARS_DETAILS_PAGE",
    parentId: "CALENDARS_PAGE",
    nicename: "details",
    breadcrumb: "Details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "SHORTLIST_PUBLIC",
    nicename: "client/:tenant_alias/:groupId",
    public: true,
  },
  {
    id: "CANDIDATE_WEB_REDIRECT",
    nicename: "redirect/:tenant_alias/:id_process/:id_candidate/:phase_index",
    public: true,
  },
  {
    id: "SHORTLIST_PAGE",
    parentId: "DETAILS_PROCESS",
    nicename: "shortlist",
    breadcrumb: "breadcrumb.shortlist",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "LOGIN_TOKEN",
    nicename: "login/token",
    fullSizeView: true,
  },
  {
    id: "LOGIN",
    nicename: "login",
    fullSizeView: true,
    public: true,
  },
  {
    id: "TERMS",
    nicename: "terms/:tenant_alias",
    public: true,
  },
  {
    id: "POLICY",
    nicename: "policy/:tenant_alias",
    public: true,
  },
  {
    id: "MARKETING",
    nicename: "marketing/:tenant_alias",
    public: true,
  },
  {
    id: "HEALTHY",
    nicename: "health",
  },
  {
    id: "LANDING_INTERVIEW",
    nicename: "landing/:tenant_alias/:id",
    public: true,
    landing: true,
  },
  {
    id: "LANDING_SMS_REDIRECT",
    nicename: "redirect",
    public: true,
    landing: true,
  },
  {
    id: "SHARING_CANDIDATE_WEB_PUBLIC",
    nicename: "tenants/:tenant_alias/candidates/:id_candidate/sharing/:id",
    public: true,
  },
  {
    id: "RECRUITER_PROFILE",
    nicename: "recruiter-profile",
    breadcrumb: "literals.profile",
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "PROCESS_FUNNELING",
    parentId: "RECRUITMENT",
    nicename: "funneling/:id_process",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_process"),
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "EXTRA_LIVE_REDIRECT",
    nicename: "live/redirect/:tenant_alias/:id_process/:id_candidate/:id_liveInterview",
    public: true,
    landing: true,
  },
  {
    id: "CANDIDATE_WEB_CONFIRMATION",
    nicename: "confirmation/:tenant_alias/:id_process/:id_tempPerson",
    public: true,
  },
  {
    id: "CANDIDATE_WEB_UNSUBSCRIBE",
    nicename: "unsubscribe/:tenant_alias/:id_person",
    public: true,
  },
  {
    id: "PROCESS_CREATION_LANDING",
    nicename: "external-landing/process-creation",
    landing: true,
  },
  {
    id: "CANDIDATE_QUICK_APPLY",
    nicename: "external-landing/candidate-quick-apply",
    public: true,
    landing: true,
  },
  {
    id: "PROCESS_DETAILS_LANDING",
    nicename: "external-landing/process-details",
    landing: true,
  },
  {
    id: "PROCESS_TEMPLATES_LANDING",
    nicename: "external-landing/process-templates",
    landing: true,
  },
  {
    id: "CANDIDATE_ATTACHMENT_LANDING",
    nicename: "external-landing/candidate-attachment",
    landing: true,
  },
  {
    id: "PERSON_DETAILS_LANDING",
    nicename: "external-landing/candidate-details",
    landing: true,
  },
  {
    id: "NEW_REQUISITION_LANDING",
    nicename: "external-landing/new-requistion",
    landing: true,
  },
  {
    id: "CANDIDATE_PUBLIC_LANDING",
    nicename: "external-landing/candidate",
    public: true,
    landing: true,
  },
  {
    id: "CAMMIO_LANDING",
    nicename: "external-landing/cammio",
    public: true,
    landing: true,
  },
  {
    id: "PROCESSES_LANDING",
    nicename: "external-landing/processes",
    landing: true,
  },
  {
    id: "JOB_DETAILS",
    parentId: "RECRUITMENT",
    nicename: "jobs/:id",
    breadcrumb: "home.jobs.BreadcrumTitle",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "JOB_DETAILS_PROCESS",
    parentId: "JOB_DETAILS",
    nicename: "processes/:id_process",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_process"),
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "JOB_DETAILS_PROCESS_CANDIDATE",
    parentId: "JOB_DETAILS_PROCESS",
    nicename: "candidates/:id_candidate",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_candidate"),
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "JOB_EDIT",
    parentId: "RECRUITMENT",
    nicename: "job/new/:id",
    breadcrumb: "home.jobsForm.BreadcrumTitle.edit",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "JOB_NEW",
    parentId: "RECRUITMENT",
    nicename: "job/new",
    breadcrumb: "home.jobsForm.BreadcrumTitle",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_SCREENING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR],
  },
  {
    id: "CANDIDATE_DETAILS_LANDING",
    nicename: "candidate/details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "EXTERNAL_LANDING_CANDIDATE_DETAILS",
    nicename: "external-landing/candidate/details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "EXTERNAL_LANDING_PERSON_SERVICE_DETAILS",
    nicename: "external-landing/service/details",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
    landing: true,
  },
  {
    id: "CANDIDATE_DETAILS",
    parentId: "RECRUITMENT",
    nicename: "persons/:id",
    breadcrumb: "home.candidate.BreadcrumTitle",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "CANDIDATE_DETAILS_PROCESS",
    parentId: "CANDIDATE_DETAILS",
    nicename: "processes/:id_process/candidates/:id_candidate",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_process"),
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "VIDEO_LIBRARY_PAGE",
    nicename: "video-library",
    breadcrumb: "media.load.library",
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "ADMINISTRATOR_OPERATIONAL_PROCESS_REPORTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "operational-processes-reports",
    breadcrumb: "administrator.local.operational.process.reports",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["operational"],
  },
  {
    id: "ADMINISTRATOR_OPERATIONAL_RECRUITERS_REPORTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "operational-recruiters-reports",
    breadcrumb: "administrator.local.operational.recruiters.reports",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["operational"],
  },
  {
    id: "ADMINISTRATOR_MANAGE_TAG_CATEGORIES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "manage-tag-categories",
    breadcrumb: "admin.local.manage.tenant.tags",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["categoryTag"],
  },
  {
    id: "SERVICE_ASSESSMENT",
    nicename: "candidate/:tenant_alias/:serviceId/:personId",
    public: true,
  },
  {
    id: "SERVICE_DETAIL",
    parentId: "RECRUITMENT",
    nicename: "services/:serviceId",
    breadcrumb: "service.detail",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "SERVICE_ASSESSMENT_DETAIL",
    parentId: "SERVICE_DETAIL",
    nicename: "assessments/:personId",
    breadcrumb: "service.assessment.detail",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "JOBDESCRIPTION_CREATION_LANDING",
    nicename: "external-landing/job-description-creation",
    landing: true,
  },
  {
    id: "JOBDESCRIPTION_LISTING_EXTERNAL_LANDING",
    nicename: "external-landing/job-description-listing",
    landing: true,
  },
  {
    id: "JOBPOSTING_LISTING_EXTERNAL_LANDING",
    nicename: "external-landing/job-post-listing",
    landing: true,
  },
  {
    id: "JOBDESCRIPTION_LISTING_EXTERNAL_LANDING_USER",
    nicename: "external-landing/job-descriptions",
    landing: true,
  },
  {
    id: "ADMINISTRATOR_TENANT_SCREENING_ATTRIBUTES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "tenant-screening-attribute",
    breadcrumb: "administrator.home.screening.attributes",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["screeningAttributes"],
  },
  {
    id: "ADMINISTRATOR_SCREENING_ATTRIBUTES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "screening-attribute",
    breadcrumb: "administrator.home.screening.attributes",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_BRANDS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-brands",
    breadcrumb: "breadcrumbs.admin.brands",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_COBRANDS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-cobrands",
    breadcrumb: "breadcrumbs.admin.cobrands",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["cobrands"],
  },
  {
    id: "ADMINISTRATOR_PERSONS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-persons",
    breadcrumb: "breadcrumbs.admin.persons",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_LOCAL_COBRANDS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "cobrands",
    breadcrumb: "breadcrumbs.admin.cobrands",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["cobrands"],
  },
  {
    id: "REFERENCE_CHECK",
    nicename: "candidate/reference/:tenant_alias/:id_candidate/:id_reference",
    public: true,
  },
  {
    id: "ADMINISTRATOR_MANAGE_EXTERNAL_SYSTEMS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-external-systems/external-systems",
    breadcrumb: "breadcrumbs.admin.ex",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_MANAGE_OPERATIONS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-external-systems/operations",
    breadcrumb: "breadcrumbs.admin.ex",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_MANAGE_TENANT_SYSTEM_OPERATION",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-external-systems/external-system-operation-tenant",
    breadcrumb: "breadcrumbs.admin.ex",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["externalSystemOperations"],
  },
  {
    id: "ADMINISTRATOR_MANAGE_LINKS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "links",
    breadcrumb: "breadcrumb.admin.links",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_MANAGE_INTEGRATION_ERRORS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "integration-error-logs",
    breadcrumb: "breadcrumb.admin.integration.errors",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_MYA_EVENTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "mya-events",
    breadcrumb: "breadcrumb.admin.mya.events",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_PROACTIVE_SOURCING",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "proactive-sourcing",
    breadcrumb: "breadcrumbs.proactiveSourcing",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_UPLOAD_JD_MANUALLY",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "upload-manually-jds",
    breadcrumb: "breadcrumbs.upload.manually.jds",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "PROACTIVE_SOURCING",
    nicename: "proactive-sourcing",
    breadcrumb: "breadcrumbs.proactiveSourcing",
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "PERSON_VIDEOS_SHARING_LANDING",
    nicename: "external-landing/person-videos",
    landing: true,
  },
  {
    id: "PERSON_VIDEOS_SHARING",
    parentId: "RECRUITMENT",
    nicename: "person-videos",
    breadcrumb: "person.video.sharing",
    allowedModules: RECRUITMENT_CONNECT_MODULE,
    section: RECRUITMENT_CONNECT_SECTION,
  },
  {
    id: "ADMINISTRATOR_BUDGETS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "manage-budgets",
    breadcrumb: "administrator.home.budgets",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["budget"],
  },
  {
    id: "ADMINISTRATOR_USER_FEEDBACK_REPORTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "user-feedback",
    breadcrumb: "user.feedbak.reports",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
    permissions: ["userFeedback"],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_USER_FEEDBACK_REPORTS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "global-user-feedback",
    breadcrumb: "user.global.feedback.reports.new",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_OPERATIONAL_RECRUITERS_REPORTS_GLOBAL",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "operational-processes-recruiters-global",
    breadcrumb: "administrator.local.operational.recruiters.reports-global",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_CAMMIO_REPORT_TENANT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "cammio-report-tenant",
    breadcrumb: "administrator.cammio.report.tenant",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_CAMMIO_REPORT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "cammio-report",
    breadcrumb: "administrator.cammio.report",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_REPORTS_TENANT_STATS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "administrator-global-reports-tenant-stats-new",
    breadcrumb: "administrator-global-reports-tenant-stat-new",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_CLIENT_BRAND",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "client-brand",
    breadcrumb: "breadcrumbs.admin.clientBrand",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_MANAGE_LITERALS",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "manage-literals",
    breadcrumb: "breadcrumbs.admin.manageLiterals",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "CANDIDATE_PROCESS_DETAILS",
    nicename: "external-landing/candidate-process-details",
    public: true,
    landing: true,
    external: true,
  },
  {
    id: "JOB_DESCRIPTION_SECTIONS_CATEGORIES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "job-desc-sections-categories",
    breadcrumb: "breadcrumbs.candidatesourcing",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_GLOBAL_JOB_POSTING_REPORT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "job-postings-report-global",
    breadcrumb: "job.posting.report",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "ADMINISTRATOR_TENANT_JOB_POSTING_REPORT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "job-postings-report",
    breadcrumb: "job.posting.report",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_CS_ADMIN],
  },
  {
    id: "ADMINISTRATOR_TENANT_CONVERSION_RATES_REPORT",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "conversion-rates-report",
    breadcrumb: "overall.conversion.rates.report",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  },
  {
    id: "LEADS_MANAGEMENT",
    nicename: "leads-management",
    breadcrumb: "Leads Management",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "LEADS_DETAILS",
    parentId: "LEADS_MANAGEMENT",
    nicename: "leads/:id_lead",
    breadcrumb: (url: string, match: MatchType) => generateBreadcrum(match, "id_lead"),
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: CANDIDATE_SOURCING_SECTION,
  },
  {
    id: "QUERIES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "queries",
    breadcrumb: "Queries",
    section: ADMIN_SECTION,
  },
  {
    id: "DASHBOARD",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "dashboard",
    breadcrumb: "Dashboard",
    section: ADMIN_SECTION,
  },
  {
    id: "LEADS_REGISTRATION",
    nicename: "registration/:tenant_alias/:id_lead",
    public: true,
  },
  {
    id: "LEADS_UNSUBSCRIBE",
    nicename: "registration/:tenant_alias/:id_lead/unsubscribe",
    public: true,
  },
  {
    id: "ADMINISTRATOR_CANDIDATE_IMPORT_FAILURES",
    parentId: "ADMINISTRATOR_HOME",
    nicename: "candidate-import-failures.reports",
    breadcrumb: "administrator.candidate.import.failures",
    section: ADMIN_SECTION,
    roles: [ROLE_SUPER_ADMIN],
  },
  {
    id: "PDF_RENDER_PAGE",
    nicename: "pdf/:tenant_alias/:pdf_id/generate",
    public: true,
  },
  {
    id: "JOB_DESCRIPTION_PDF_GENERATOR",
    parentId: "JOB_LISTING",
    nicename: ":jobDescriptionId/pdf-generator",
    breadcrumb: ":jobDescriptionId/pdf-generator",
    allowedModules: CANDIDATE_SOURCING_MODULE,
    section: CANDIDATE_SOURCING_SECTION,
  },
];

const generateBreadcrum = (match: any, key: string): string => {
  let breadcrumb = "";
  // Return split with name process
  const parts = match[key] ? match[key].split("-") : [];
  if (parts.length > 0) {
    if (/^[a-fA-F0-9]{8}$/.test(parts[0])) {
      breadcrumb = match[key];
    } else {
      breadcrumb = parts[0].replace(/\+/g, " ");
    }
  }
  return breadcrumb;
};
