import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { notifications } from "../../../redux/slices/notificationsSlice";
import { INotificationitem } from "./notifications-service";
import NotfificationMessage from "./notification";
const NotificationsContainer: React.FC<any> = (props: any): ReactElement => {
  const _notificationsState: any = useSelector(notifications);
  return (
    <div className={`tag-ds notifications-container`} role="alertdialog">
      {props.children}
      {_notificationsState?.notifications?.map((item: INotificationitem) => {
        return <NotfificationMessage type={item.type} id={item.id} key={item.id} timer={item.timer} richText={item.richText} content={item.content} showTimer={item.showTimer} onClick={item.onClick} textLink={item.textLink} />;
      })}
    </div>
  );
};

export default NotificationsContainer;
