import React from "react";
import { FormContext } from "./form";
import { validateInput } from "./utilities/validations";
import BaseInput, { IState } from "./base-input";

class TagInput extends BaseInput {
  type = "TagInput";
  state: Readonly<IState> = {
    disabled: this.props.disabled,
    errors: this.props.errors,
    isValid: undefined,
    type: "checkbox",
    value: this.props.value ? this.props.value : "value",
    checked: this.props.checked,
    validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.context && typeof this.context.addInputToContext === "function") {
      this.context.addInputToContext(this);

      this.updateParentGroup();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props) || JSON.stringify(prevState) !== JSON.stringify(this.state)) {
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        let isChecked = typeof this.props.checked !== "undefined" ? this.props.checked : false;
        if (this.props.checked !== prevProps.checked && this.state.checked !== isChecked) {
          this.setState({ checked: isChecked });
        }
        if (this.props.defaultValue !== prevProps.defaultValue) {
          this.setState({ value: this.props.defaultValue });
        }
        if (this.props.disabled !== prevProps.disabled) {
          this.setState({ disabled: this.props.disabled });
        }
      }
      if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
        this.updateParentGroup();
      }
    }
  }

  updateParentGroup = () => {
    if (this.props.changeParentGroup && typeof this.props.changeParentGroup === "function") {
      let isChecked = typeof this.state.checked !== "undefined" ? this.state.checked : false;
      this.props.changeParentGroup(this, isChecked);
    }
  };

  onChange = async (SyntheticEvent: any) => {
    const self = this;
    SyntheticEvent.persist();

    //Will behave as a radio
    if (this.props.multiple === false) {
      let siblings: any = []; //had to use any because type changes depending on if there is context (react) or not (vanilla html)
      //event triggered by user
      if (this.context && this.context.inputs && this.context.inputs.length) {
        //radios inside form
        siblings = this.context.inputs.filter((el: any) => el !== this && el.type === "TagInput" && el.props.name === this.props.name);
        siblings.forEach((element: any) => {
          if (element.state.checked) {
            element.setState({ checked: false });
          }
        });
      }
    }
    //will behave as a checkbox
    this.setState({ checked: !this.state.checked }, () => {
      validateInput(self, this.context);
      //validate siblings
      if (self.context && self.context.inputs && self.context.inputs.length) {
        //checkboxes inside form
        const siblings = self.context.inputs.filter((el: any) => el !== self && el.type === "TagInput" && el.props.name === self.props.name);
        siblings.forEach((element: any) => {
          validateInput(element, self.context);
        });
      }
      this.updateParentGroup();
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(SyntheticEvent);
    }

    if (SyntheticEvent && !SyntheticEvent?.target?.fakeEvent && typeof this.state.parentFormOnChange === "function") {
      this.state.parentFormOnChange(SyntheticEvent);
    }
  };

  render() {
    this.processCSSClasses();

    const checked = typeof this.state.checked !== "undefined" && this.state.checked !== null ? this.state.checked : false;
    const disabledClass = this.state.disabled ? "disabled" : "";
    const activeClass = checked ? "active" : "";
    return (
      <>
        <div className={`input-wrapper input-wrapper-checkbox ${this.validationClass} ${this.loadingClass} ${this.props.className || ""}`}>
          <div className={`chip ${disabledClass} ${activeClass}`} style={{ position: "relative" }}>
            {this.props.label}
            <input
              style={{ position: "absolute", width: "100%", height: "100%", opacity: "0", left: "0", top: "0" }}
              disabled={this.state.disabled}
              name={this.props.name}
              value={this.props.value}
              checked={checked}
              id={this.props.id}
              data-testid={this.props.id}
              type="checkbox"
              onChange={this.onChange}
            />
          </div>
        </div>
      </>
    );
  }
}

TagInput.contextType = FormContext;
export default TagInput;
