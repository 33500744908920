import { createIntl, IntlShape } from "react-intl";

export interface CountryValue {
  label: string;
  value: string; //isoCode
  forcedLabel?: string;
}
export interface CountryCode extends CountryValue {
  name: string;
  dial_code: string;
  code: string; // isoCode
  displayName?: string;
  order?: number;
}

function createCountry(name: string, dial_code: string, code: string, index: number): CountryCode {
  const label = name;
  const value = code;
  const displayName = `${name} (${dial_code})`;
  const order = index + 1;
  const forcedLabel = "";
  return { name, dial_code, code, label, value, displayName, order, forcedLabel };
}

export const _countryCodes: CountryCode[] = [
  createCountry("Afghanistan", "+93", "AF", 0),
  createCountry("Aland Islands", "+358", "AX", 1),
  createCountry("Albania", "+355", "AL", 2),
  createCountry("Algeria", "+213", "DZ", 3),
  createCountry("American Samoa", "+1684", "AS", 4),
  createCountry("Andorra", "+376", "AD", 5),
  createCountry("Angola", "+244", "AO", 6),
  createCountry("Anguilla", "+1264", "AI", 7),
  createCountry("Antarctica", "+672", "AQ", 8),
  createCountry("Antigua and Barbuda", "+1268", "AG", 9),
  createCountry("Argentina", "+54", "AR", 10),
  createCountry("Armenia", "+374", "AM", 11),
  createCountry("Aruba", "+297", "AW", 12),
  createCountry("Australia", "+61", "AU", 13),
  createCountry("Austria", "+43", "AT", 14),
  createCountry("Azerbaijan", "+994", "AZ", 15),
  createCountry("Bahamas", "+1242", "BS", 16),
  createCountry("Bahrain", "+973", "BH", 17),
  createCountry("Bangladesh", "+880", "BD", 18),
  createCountry("Barbados", "+1246", "BB", 19),
  createCountry("Belarus", "+375", "BY", 20),
  createCountry("Belgium", "+32", "BE", 21),
  createCountry("Belize", "+501", "BZ", 22),
  createCountry("Benin", "+229", "BJ", 23),
  createCountry("Bermuda", "+1441", "BM", 24),
  createCountry("Bhutan", "+975", "BT", 25),
  createCountry("Bolivia, Plurinational State of", "+591", "BO", 26),
  createCountry("Bosnia and Herzegovina", "+387", "BA", 27),
  createCountry("Botswana", "+267", "BW", 28),
  createCountry("Brazil", "+55", "BR", 29),
  createCountry("British Indian Ocean Territory", "+246", "IO", 30),
  createCountry("Brunei Darussalam", "+673", "BN", 31),
  createCountry("Bulgaria", "+359", "BG", 32),
  createCountry("Burkina Faso", "+226", "BF", 33),
  createCountry("Burundi", "+257", "BI", 34),
  createCountry("Cambodia", "+855", "KH", 35),
  createCountry("Cameroon", "+237", "CM", 36),
  createCountry("Canada", "+1", "CA", 37),
  createCountry("Cape Verde", "+238", "CV", 38),
  createCountry("Cayman Islands", "+ 345", "KY", 39),
  createCountry("Central African Republic", "+236", "CF", 40),
  createCountry("Chad", "+235", "TD", 41),
  createCountry("Chile", "+56", "CL", 42),
  createCountry("China", "+86", "CN", 43),
  createCountry("Christmas Island", "+61", "CX", 44),
  createCountry("Cocos (Keeling) Islands", "+61", "CC", 45),
  createCountry("Colombia", "+57", "CO", 46),
  createCountry("Comoros", "+269", "KM", 47),
  createCountry("Congo", "+242", "CG", 48),
  createCountry("Congo, The Democratic Republic of the Congo", "+243", "CD", 49),
  createCountry("Cook Islands", "+682", "CK", 50),
  createCountry("Costa Rica", "+506", "CR", 51),
  createCountry("Cote d'Ivoire", "+225", "CI", 52),
  createCountry("Croatia", "+385", "HR", 53),
  createCountry("Cuba", "+53", "CU", 54),
  createCountry("Cyprus", "+357", "CY", 55),
  createCountry("Czech Republic", "+420", "CZ", 56),
  createCountry("Denmark", "+45", "DK", 57),
  createCountry("Djibouti", "+253", "DJ", 58),
  createCountry("Dominica", "+1767", "DM", 59),
  createCountry("Dominican Republic", "+1849", "DO", 60),
  createCountry("Ecuador", "+593", "EC", 61),
  createCountry("Egypt", "+20", "EG", 62),
  createCountry("El Salvador", "+503", "SV", 63),
  createCountry("Equatorial Guinea", "+240", "GQ", 64),
  createCountry("Eritrea", "+291", "ER", 65),
  createCountry("Estonia", "+372", "EE", 66),
  createCountry("Ethiopia", "+251", "ET", 67),
  createCountry("Falkland Islands (Malvinas)", "+500", "FK", 68),
  createCountry("Faroe Islands", "+298", "FO", 69),
  createCountry("Fiji", "+679", "FJ", 70),
  createCountry("Finland", "+358", "FI", 71),
  createCountry("France", "+33", "FR", 72),
  createCountry("French Guiana", "+594", "GF", 73),
  createCountry("French Polynesia", "+689", "PF", 74),
  createCountry("Gabon", "+241", "GA", 75),
  createCountry("Gambia", "+220", "GM", 76),
  createCountry("Georgia", "+995", "GE", 77),
  createCountry("Germany", "+49", "DE", 78),
  createCountry("Ghana", "+233", "GH", 79),
  createCountry("Gibraltar", "+350", "GI", 80),
  createCountry("Greece", "+30", "GR", 81),
  createCountry("Greenland", "+299", "GL", 82),
  createCountry("Grenada", "+1473", "GD", 83),
  createCountry("Guadeloupe", "+590", "GP", 84),
  createCountry("Guam", "+1671", "GU", 85),
  createCountry("Guatemala", "+502", "GT", 86),
  createCountry("Guernsey", "+44", "GG", 87),
  createCountry("Guinea", "+224", "GN", 88),
  createCountry("Guinea-Bissau", "+245", "GW", 89),
  createCountry("Guyana", "+595", "GY", 90),
  createCountry("Haiti", "+509", "HT", 91),
  createCountry("Holy See (Vatican City State)", "+379", "VA", 92),
  createCountry("Honduras", "+504", "HN", 93),
  createCountry("Hong Kong", "+852", "HK", 94),
  createCountry("Hungary", "+36", "HU", 95),
  createCountry("Iceland", "+354", "IS", 96),
  createCountry("India", "+91", "IN", 97),
  createCountry("Indonesia", "+62", "ID", 98),
  createCountry("Iran, Islamic Republic of Persian Gulf", "+98", "IR", 99),
  createCountry("Iraq", "+964", "IQ", 100),
  createCountry("Ireland", "+353", "IE", 101),
  createCountry("Isle of Man", "+44", "IM", 102),
  createCountry("Israel", "+972", "IL", 103),
  createCountry("Italia", "+39", "IT", 104),
  createCountry("Jamaica", "+1876", "JM", 105),
  createCountry("Japan", "+81", "JP", 106),
  createCountry("Jersey", "+44", "JE", 107),
  createCountry("Jordan", "+962", "JO", 108),
  createCountry("Kazakhstan", "+77", "KZ", 109),
  createCountry("Kenya", "+254", "KE", 110),
  createCountry("Kiribati", "+686", "KI", 111),
  createCountry("Korea, Democratic People's Republic of Korea", "+850", "KP", 112),
  createCountry("Korea, Republic of South Korea", "+82", "KR", 113),
  createCountry("Kuwait", "+965", "KW", 114),
  createCountry("Kyrgyzstan", "+996", "KG", 115),
  createCountry("Laos", "+856", "LA", 116),
  createCountry("Latvia", "+371", "LV", 117),
  createCountry("Lebanon", "+961", "LB", 118),
  createCountry("Lesotho", "+266", "LS", 119),
  createCountry("Liberia", "+231", "LR", 120),
  createCountry("Libyan Arab Jamahiriya", "+218", "LY", 121),
  createCountry("Liechtenstein", "+423", "LI", 122),
  createCountry("Lithuania", "+370", "LT", 123),
  createCountry("Luxembourg", "+352", "LU", 124),
  createCountry("Macao", "+853", "MO", 125),
  createCountry("Macedonia", "+389", "MK", 126),
  createCountry("Madagascar", "+261", "MG", 127),
  createCountry("Malawi", "+265", "MW", 128),
  createCountry("Malaysia", "+60", "MY", 129),
  createCountry("Maldives", "+960", "MV", 130),
  createCountry("Mali", "+223", "ML", 131),
  createCountry("Malta", "+356", "MT", 132),
  createCountry("Marshall Islands", "+692", "MH", 133),
  createCountry("Martinique", "+596", "MQ", 134),
  createCountry("Mauritania", "+222", "MR", 135),
  createCountry("Mauritius", "+230", "MU", 136),
  createCountry("Mayotte", "+262", "YT", 137),
  createCountry("Mexico", "+52", "MX", 138),
  createCountry("Micronesia, Federated States of Micronesia", "+691", "FM", 139),
  createCountry("Moldova", "+373", "MD", 140),
  createCountry("Monaco", "+377", "MC", 141),
  createCountry("Mongolia", "+976", "MN", 142),
  createCountry("Montenegro", "+382", "ME", 143),
  createCountry("Montserrat", "+1664", "MS", 144),
  createCountry("Morocco", "+212", "MA", 145),
  createCountry("Mozambique", "+258", "MZ", 146),
  createCountry("Myanmar", "+95", "MM", 147),
  createCountry("Namibia", "+264", "NA", 148),
  createCountry("Nauru", "+674", "NR", 149),
  createCountry("Nepal", "+977", "NP", 150),
  createCountry("Netherlands", "+31", "NL", 151),
  createCountry("Netherlands Antilles", "+599", "AN", 152),
  createCountry("New Caledonia", "+687", "NC", 153),
  createCountry("New Zealand", "+64", "NZ", 154),
  createCountry("Nicaragua", "+505", "NI", 155),
  createCountry("Niger", "+227", "NE", 156),
  createCountry("Nigeria", "+234", "NG", 157),
  createCountry("Niue", "+683", "NU", 158),
  createCountry("Norfolk Island", "+672", "NF", 159),
  createCountry("Northern Mariana Islands", "+1670", "MP", 160),
  createCountry("Norway", "+47", "NO", 161),
  createCountry("Oman", "+968", "OM", 162),
  createCountry("Pakistan", "+92", "PK", 163),
  createCountry("Palau", "+680", "PW", 164),
  createCountry("Palestinian Territory, Occupied", "+970", "PS", 165),
  createCountry("Panama", "+507", "PA", 166),
  createCountry("Papua New Guinea", "+675", "PG", 167),
  createCountry("Paraguay", "+595", "PY", 168),
  createCountry("Peru", "+51", "PE", 169),
  createCountry("Philippines", "+63", "PH", 170),
  createCountry("Pitcairn", "+872", "PN", 171),
  createCountry("Poland", "+48", "PL", 172),
  createCountry("Portugal", "+351", "PT", 173),
  createCountry("Puerto Rico", "+1939", "PR", 174),
  createCountry("Qatar", "+974", "QA", 175),
  createCountry("Romania", "+40", "RO", 176),
  createCountry("Russia", "+7", "RU", 177),
  createCountry("Rwanda", "+250", "RW", 178),
  createCountry("Reunion", "+262", "RE", 179),
  createCountry("Saint Barthelemy", "+590", "BL", 180),
  createCountry("Saint Helena, Ascension and Tristan Da Cunha", "+290", "SH", 181),
  createCountry("Saint Kitts and Nevis", "+1869", "KN", 182),
  createCountry("Saint Lucia", "+1758", "LC", 183),
  createCountry("Saint Martin", "+590", "MF", 184),
  createCountry("Saint Pierre and Miquelon", "+508", "PM", 185),
  createCountry("Saint Vincent and the Grenadines", "+1784", "VC", 186),
  createCountry("Samoa", "+685", "WS", 187),
  createCountry("San Marino", "+378", "SM", 188),
  createCountry("Sao Tome and Principe", "+239", "ST", 189),
  createCountry("Saudi Arabia", "+966", "SA", 190),
  createCountry("Senegal", "+221", "SN", 191),
  createCountry("Serbia", "+381", "RS", 192),
  createCountry("Seychelles", "+248", "SC", 193),
  createCountry("Sierra Leone", "+232", "SL", 194),
  createCountry("Singapore", "+65", "SG", 195),
  createCountry("Slovakia", "+421", "SK", 196),
  createCountry("Slovenia", "+386", "SI", 197),
  createCountry("Solomon Islands", "+677", "SB", 198),
  createCountry("Somalia", "+252", "SO", 199),
  createCountry("South Africa", "+27", "ZA", 200),
  createCountry("South Sudan", "+211", "SS", 201),
  createCountry("South Georgia and the South Sandwich Islands", "+500", "GS", 202),
  createCountry("Spain", "+34", "ES", 203),
  createCountry("Sri Lanka", "+94", "LK", 204),
  createCountry("Sudan", "+249", "SD", 205),
  createCountry("Suriname", "+597", "SR", 206),
  createCountry("Svalbard and Jan Mayen", "+47", "SJ", 207),
  createCountry("Swaziland", "+268", "SZ", 208),
  createCountry("Sweden", "+46", "SE", 209),
  createCountry("Switzerland", "+41", "CH", 210),
  createCountry("Syrian Arab Republic", "+963", "SY", 211),
  createCountry("Taiwan", "+886", "TW", 212),
  createCountry("Tajikistan", "+992", "TJ", 213),
  createCountry("Tanzania, United Republic of Tanzania", "+255", "TZ", 214),
  createCountry("Thailand", "+66", "TH", 215),
  createCountry("Timor-Leste", "+670", "TL", 216),
  createCountry("Togo", "+228", "TG", 217),
  createCountry("Tokelau", "+690", "TK", 218),
  createCountry("Tonga", "+676", "TO", 219),
  createCountry("Trinidad and Tobago", "+1868", "TT", 220),
  createCountry("Tunisia", "+216", "TN", 221),
  createCountry("Turkey", "+90", "TR", 222),
  createCountry("Turkmenistan", "+993", "TM", 223),
  createCountry("Turks and Caicos Islands", "+1649", "TC", 224),
  createCountry("Tuvalu", "+688", "TV", 225),
  createCountry("Uganda", "+256", "UG", 226),
  createCountry("Ukraine", "+380", "UA", 227),
  createCountry("United Arab Emirates", "+971", "AE", 228),
  createCountry("United Kingdom", "+44", "GB", 229),
  createCountry("United States", "+1", "US", 230),
  createCountry("Uruguay", "+598", "UY", 231),
  createCountry("Uzbekistan", "+998", "UZ", 232),
  createCountry("Vanuatu", "+678", "VU", 233),
  createCountry("Venezuela, Bolivarian Republic of Venezuela", "+58", "VE", 234),
  createCountry("Vietnam", "+84", "VN", 235),
  createCountry("Virgin Islands, British", "+1284", "VG", 236),
  createCountry("Virgin Islands, U.S.", "+1340", "VI", 237),
  createCountry("Wallis and Futuna", "+681", "WF", 238),
  createCountry("Yemen", "+967", "YE", 239),
  createCountry("Zambia", "+260", "ZM", 240),
  createCountry("Zimbabwe", "+263", "ZW", 241),
];

export const countryValues: CountryValue[] = [
  //Focore is treated like a country, for some reason
  { label: "Focore", value: "FC", forcedLabel: "Focore" },
  ..._countryCodes,
  //legacy CS Country List
  { label: "Bouvet Island", value: "BV" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Heard and McDonald Islands", value: "HM" },
  { label: "US Minor Outlying Islands", value: "UM" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yugoslavia", value: "YU" },
];

export const countryCodes = countryValues.filter((cv: any) => cv.dial_code && cv.name && cv.code) as CountryCode[];

export const getCountryLocaleName = (country: CountryCode, locale: string, externalIntl?: IntlShape) => {
  const _intl = externalIntl || createIntl({ locale: locale || "en-US" });
  try {
    //non standarized values will produce error
    const countryName = country?.forcedLabel || _intl?.formatters?.getDisplayNames(locale || "en-US", { type: "region" })?.of(country?.value || country?.code) || country.label || country.name;
    return countryName;
  } catch (error) {
    console.error(error);
    return country?.forcedLabel || country?.label || country?.name;
  }
};

export const getArrayCountriesInLocaleName: any = (countryArray: CountryCode[], locale = "en-US") => {
  const _intl: IntlShape = createIntl({ locale });
  const arrayCountries = countryArray?.map((country: CountryCode) => ({ ...country, label: getCountryLocaleName(country, locale, _intl) }));
  arrayCountries.sort((a, b) => {
    if (a?.label < b?.label) {
      return -1;
    }
    if (a?.label > b?.label) {
      return 1;
    }
    return 0;
  });
  return arrayCountries;
};

export const parseCountriesForQAP = (countryArray: { label: string; dial_code: string }[]) => {
  const _parsedCountries = countryArray.map((country) => ({ label: `${country?.label} (${country?.dial_code})`, value: country?.dial_code }));
  return _parsedCountries;
};
