import store from "../redux/store";
import { PhaseStatus } from "../types";
import { CURRENT_LOCALE } from "./locale-utils";

interface FeatureConfiguration {
  channel: string;
  feature: string;
  isEnabled: boolean;
  config: any;
  enableByValue?: boolean;
}

export function getFeatureConfigurationIsEnabled(channel: string, feature: string, featuresConfigurations?: any[]): boolean {
  const featureFound = getFeatureConfiguration(channel, feature, featuresConfigurations);
  return featureFound ? featureFound.isEnabled : false;
}

export function getFeatureIsEnabledNoChannel(feature: string): boolean {
  //simpler method, no channel needed
  const tenantFeatureConfig = store?.getState()?.tenant?.selectedTenant?.selectedTenantConfiguration;
  const featureFound = tenantFeatureConfig?.find((tenantFeature: FeatureConfiguration) => tenantFeature.feature === feature);
  return featureFound?.isEnabled === true;
}

export function getFeatureConfigurationValues(channel: string, feature: string): any {
  const featureFound = getFeatureConfiguration(channel, feature);
  return featureFound ? featureFound.config : undefined;
}

export function getFeatureConfiguration(channel: string, feature: string, featuresConfigurations?: any[]): FeatureConfiguration {
  const tenantFeatureConfig = featuresConfigurations || store?.getState()?.tenant?.selectedTenant?.selectedTenantConfiguration;
  return tenantFeatureConfig && tenantFeatureConfig.find((tenantFeature: FeatureConfiguration) => tenantFeature.feature === feature && tenantFeature.channel === channel);
}

export function _getFeatureConfiguration(channel: string, feature: string, featuresConfigurations: any[]): FeatureConfiguration {
  return featuresConfigurations?.find((tenantFeature: FeatureConfiguration) => tenantFeature.feature === feature && tenantFeature.channel === channel);
}
export function isSurnameFirstFeatureEnabled(channel: string, feature: string, language?: string, featuresConfigurations?: any[]): boolean {
  if (!language) language = CURRENT_LOCALE;
  const featureFound = getFeatureConfiguration(channel, feature, featuresConfigurations);
  const _isEnabledLanguage = featureFound?.config?.enabledLanguages?.includes(language) === true;
  return featureFound?.isEnabled === true && _isEnabledLanguage;
}

export function getstatesTranslationsObj() {
  const tenantFeatureConfig = store.getState().tenant.selectedTenant.selectedTenantConfiguration;
  let customizeStatesTranslationsConfig =
    tenantFeatureConfig && tenantFeatureConfig.find((featureConfFind: any) => featureConfFind?.channel === "Process Settings" && featureConfFind?.feature === "states-translations");
  const statesTranslationsObj = customizeStatesTranslationsConfig?.config?.[CURRENT_LOCALE]
    ? customizeStatesTranslationsConfig?.config?.[CURRENT_LOCALE]
    : {
        [PhaseStatus.waiting]: PhaseStatus.waiting,
        [PhaseStatus.invited]: PhaseStatus.invited,
        [PhaseStatus.opened]: PhaseStatus.opened,
        [PhaseStatus.started]: PhaseStatus.started,
        [PhaseStatus.processing]: PhaseStatus.processing,
        [PhaseStatus.pending]: PhaseStatus.pending,
        [PhaseStatus.completed]: PhaseStatus.completed,
        [PhaseStatus.failed]: PhaseStatus.failed,
      };
  return statesTranslationsObj;
}

export function getStartPhaseCustomization() {
  const startPhaseCustomization = getFeatureConfigurationIsEnabled("Candidate Settings", "start-phase-customization");
  const startPhaseCustomizeEnabled = getFeatureConfigurationIsEnabled("Candidate Settings", "start-phase-customize");
  const startPhaseCustomizeValues = getFeatureConfigurationValues("Candidate Settings", "start-phase-customize");
  if (startPhaseCustomization && startPhaseCustomizeEnabled && startPhaseCustomizeValues?.[CURRENT_LOCALE]?.message) {
    return startPhaseCustomizeValues?.[CURRENT_LOCALE]?.message;
  } else {
    return {};
  }
}
