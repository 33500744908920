/* PRIVATE PART */
interface IValuesResponse {
  fieldRelated: string | undefined;
  value: any;
  checkbox?: boolean;
}
const notChangeField = undefined;

const valueRelatedInput = (context: any, name: string, value: any, checkbox?: boolean) => {
  const inputComponent: any = context?.inputs?.find((a: any) => a.props.name === name && a._isMounted);
  if (inputComponent) {
    const toState = { value: value } as any;
    if (checkbox) toState.checked = value === "true" ? true : false;
    inputComponent.setState(toState);
  }
};
/* END PRIVATE PART */

/* BASE FUNCTION PART */
export async function checkValueInput(inputComponent: any, context: any) {
  const relatedValues = inputComponent.props.valueRelated || [];
  for await (const relatedValue of relatedValues) {
    if (typeof relatedValue === "string") {
      let valueRelated: IValuesResponse;
      switch (relatedValue) {
        case "dataApplicationMethodValues":
          valueRelated = defaultApplicationMethodWhenPublishGFLO(inputComponent, context, relatedValue);
          break;
        case "checkConfidential":
          valueRelated = checkedConfidential(inputComponent, context, relatedValue);
          break;
        default:
          valueRelated = { fieldRelated: notChangeField, value: "" };
      }

      if (typeof valueRelated?.fieldRelated !== "undefined") {
        // Change value of the related field
        valueRelatedInput(context, valueRelated.fieldRelated, valueRelated.value, valueRelated?.checkbox);
      }
    }
  }
}
/* END BASE FUNCTION PART */

/* FUNCTIONS PART */
// If GFLO Yes -> defaultApplication must have online
const defaultApplicationMethodWhenPublishGFLO = (input: any, _context: any, type: string) => {
  let result: IValuesResponse = { fieldRelated: undefined, value: "" };
  const value = input.state.value;
  result.fieldRelated = type.replace("Values", "");
  let applicationMethodValue = _context?.prevInputs?.find((item: any) => item?.props?.name === result?.fieldRelated)?.value;
  if (value === "true") {
    if (applicationMethodValue !== "online" && !applicationMethodValue?.includes("online")) {
      if (applicationMethodValue === "") {
        applicationMethodValue = ["online"];
      } else {
        if (Array.isArray(applicationMethodValue)) {
          applicationMethodValue?.push("online");
        } else {
          applicationMethodValue = [applicationMethodValue, "online"];
        }
      }
    }
  }
  result.value = applicationMethodValue;
  return result;
};

const checkedConfidential = (input: any, _context: any, type: string) => {
  let result: IValuesResponse = { fieldRelated: undefined, value: "", checkbox: true };
  const value = input.state.value;
  if (value === "talentPool") {
    result.value = "true";
    result.fieldRelated = "dataConfidential"; // Only check when is talent pool
  }
  return result;
};
