import React, { ReactElement, useRef, useState } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import * as Api from "../../../api";
import store from "../../../redux/store";
import { FiltersResults } from "../../../constants";
import { defaultRefactorColumns } from "./constants";
import CammioReportTable from "./cammio-report-table/cammio-report-table";
import CammioReportFilters from "./cammio-report-table/cammio-report-filters";
import { toLiteral } from "../../../helper/locale-utils";
import Results from "../../shared/results/results";
import { getTagDSTheme } from "../../../utilities/theme";
import { getDateFormatFromFeatureConfiguration } from "../../../helper/date-format-helper";
import { Tooltip } from "@adeccoux/tag-ds";
import * as XLSX from "xlsx";
import "./cammio-report.scss";
import MainComponent from "../../master/components/main-component/main-component";

const CammioReportTenant: React.FC<any> = (): ReactElement => {
  const selectedTenant = store.getState().tenant.selectedTenant;
  const dateFormat = getDateFormatFromFeatureConfiguration();

  const ref = useRef<HTMLButtonElement>(null);
  const [cammioCount, setCammioStats] = useState(0);
  const [tenantUsersCount, setTenantUsersCount] = useState(0);
  const [downloadAll, setDownloadAll] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const defaultFiltersResults: any = { dates: initDates(), sortBy: "name", sortDirection: "asc", perPage: "20", page: "1" };
  const recruitersReprotsColumns = initProcessesReportsColumns();

  function initDates() {
    const initDate = moment(moment().startOf("month").valueOf()).format(dateFormat);
    const endDate = moment(moment().valueOf()).format(dateFormat);
    return initDate + "," + endDate;
  }

  function initProcessesReportsColumns() {
    let columns: any[] = [];
    defaultRefactorColumns?.forEach((column) => {
      columns.push({ title: column.name, columnKey: column.name, sortable: column.sortable, displayed: column.default });
    });
    return columns;
  }

  const transformDataDate = (dates: any) => {
    if (Array.isArray(dates)) {
      return dates;
    }
    if (typeof dates === "string") {
      const values = dates.split(",");
      if (values.length === 2) {
        return values;
      } else {
        return undefined;
      }
    }
  };

  const fetchRecruitersReports = async (filters: FiltersResults) => {
    const { dates, rolesFilters, sortBy, sortDirection, page, perPage } = filters;
    let _dates = transformDataDate(dates);
    const tenantId = selectedTenant?.id;
    let response;
    if (_dates) {
      response = await Api.fetchCammioStats(_dates[0], _dates[1], rolesFilters, sortBy, sortDirection, page, perPage, downloadAll, tenantId);
    }
    setCammioStats(response?.totalCammioCount || 0);
    setTenantUsersCount(response?.totalUsersCount);
    if (downloadAll) {
      downloadExcell(response?.cammioData);
      setDownloadAll(false);
    }
    return { count: response?.totalUsersCount || 0, data: response?.cammioData || [] };
  };

  const renderItems = (results: any, visibleColumns: any) => {
    return <CammioReportTable results={results} columns={visibleColumns ? visibleColumns : recruitersReprotsColumns} />;
  };

  const downloadExcell = (data: any[]) => {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "CammioReport.xlsx", { type: "buffer" });
  };

  const renderTitles = () => {
    return (
      <>
        <div className="header-table">
          <h4>
            {" "}
            {toLiteral({ id: "Cammio Stats" })}{" "}
            <>
              <span ref={ref} className="material-icons info-icon ml2">
                info
              </span>
              <Tooltip parentRef={ref} renderAsPortal>
                <div>
                  <h5>
                    This report highlights the activity related to the creation, updating, and deletion of templates as well as their assignment as an interviewer in the cases of Video Interview and
                    Live Interview.
                  </h5>
                  <h6>Legend:</h6>
                  <ul>
                    <li>
                      <strong>Total number of active cammio users:</strong> Users who have participated in a cammio activity on the selected date.
                    </li>
                    <li>
                      <strong>Total number of users:</strong> Total users associated with the current tenant.
                    </li>
                    <li>
                      <strong>LVIInterviewer:</strong> Details the number of times a recruiter has been assigned as an interviewer for a phase of Live Video Interview. In the case of LVI, the Cammio
                      template is created each time a candidate schedules an interview with a recruiter.
                    </li>
                    <li>
                      <strong>VICammioTemplateCreated:</strong> Details the number of times a recruiter has created a Cammio phase in a process creation. In VI, the Cammio template is created/updated
                      in the process creation by the recruiter.
                    </li>
                  </ul>
                </div>
              </Tooltip>
            </>
          </h4>
          <div className="modal-data-count-parent">
            <div className="modal-data-count-container pb4 pl2">
              <div className="modal-data-count">
                <span className="material-icons right mr2">person</span>
                <span className="data-count">
                  {toLiteral({ id: "administrator.operational.reports.total.recruiter.cammio" })}: <b>{cammioCount}</b>
                </span>
              </div>
            </div>
            <div className="modal-data-count-container pb4 pl2">
              <div className="modal-data-count">
                <span className="material-icons right mr2">person</span>
                <span className="data-count">
                  {toLiteral({ id: "administrator.operational.reports.total.recruiter" })}: <b>{tenantUsersCount}</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderMainContent = () => {
    return (
      <div id="cammio-reports" className={`tag-ds ${getTagDSTheme(selectedTenant?.theme)} general-page-container `}>
        {renderTitles()}
        <Results
          resultsId={"recruiters-reports"}
          columns={recruitersReprotsColumns}
          defaultColumns={recruitersReprotsColumns}
          fetchResults={fetchRecruitersReports}
          renderItems={renderItems}
          disableURLFilters={false}
          noFiltersNoFetch={false}
          clientFiltering={false}
          refresh={refresh}
          defaultFiltersResults={defaultFiltersResults}
          filterOnChange
        >
          <CammioReportFilters dateFormat={dateFormat} setRefresh={setRefresh} refresh={refresh} downloadExcell={downloadExcell} setDownloadAll={setDownloadAll} />
        </Results>
      </div>
    );
  };
  return <MainComponent mainContent={renderMainContent} newUI />;
};

export default withRouter(CammioReportTenant);
