import moment from "moment";
import { DATE_FORMAT } from "../constants";
import { getFeatureConfiguration } from "./feature-configuration-helper";

export const getDateFormatFromBrowser = () => {
  let dateFormat = "DD/MM/yyyy";
  if (window?.navigator?.language) {
    const _locale = window.navigator.language;
    const localeData = moment.localeData(_locale);
    dateFormat = localeData.longDateFormat("L");
  }
  return dateFormat;
};

export const getDateFormatFromFeatureConfiguration = (returnDefault = true) => {
  const dateFormatFeature = getFeatureConfiguration("General Settings", "date-format");
  const dateFormat = DATE_FORMAT[dateFormatFeature?.config?.dateFormat]?.numericDay;
  const defaultValue = DATE_FORMAT.default?.numericDay;
  return dateFormat || (returnDefault ? defaultValue : "");
};

export const getDateFormatStringMonthFromFeatureConfiguration = (returnDefault = true) => {
  const dateFormatFeature = getFeatureConfiguration("General Settings", "date-format");
  const dateFormat = DATE_FORMAT[dateFormatFeature?.config?.dateFormat]?.stringMonth;
  const defaultValue = DATE_FORMAT.default?.stringMonth;
  return dateFormat || (returnDefault ? defaultValue : "");
};

export const getDateFormatStringDayFromFeatureConfiguration = (returnDefault = true) => {
  const dateFormatFeature = getFeatureConfiguration("General Settings", "date-format");
  const dateFormat = DATE_FORMAT[dateFormatFeature?.config?.dateFormat]?.stringDay;
  const defaultValue = DATE_FORMAT.default?.stringDay;
  return dateFormat || (returnDefault ? defaultValue : "");
};

export const getDateFormatFromTenantOrBrowser = () => {
  try {
    const dateFormat = getDateFormatFromFeatureConfiguration(false) || getDateFormatFromBrowser() || "DD/MM/yyyy";
    return dateFormat;
  } catch {
    return "DD/MM/yyyy";
  }
};

export const valueOfFormDate = (date: string, format: string, timezone?: string) => {
  const _timezone = timezone ?? moment.tz.guess();
  // To create the moment object we need to specify the format we are using in the form
  const momentDateObject = moment(date, format);
  // Reformat to force the timezone to work with a string where moment can be interpreted correctly
  const stringDateFormated = momentDateObject?.format("YYYY-MM-DD HH:mm");
  // Set timezone
  const momentTZDateObject = moment.tz(stringDateFormated, _timezone);
  // Return timestamp
  return momentTZDateObject?.valueOf();
};
