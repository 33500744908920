import { IReleaseNoteContent } from "../../../../types";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import { toLiteral } from "../../../../helper/locale-utils";
import { TenantStorage } from "../../../../helper/tenant-storage";
import { getRoutePath } from "../../../../utilities/navigation";

interface IReleaseNote {
  content: IReleaseNoteContent[];
  createdAt: string;
  id: string;
  tenantId: number;
  updatedAt: string;
}

export const getNotificationsItems = (history: any, releaseNotes: any[], _lastReleaseReaded: string | null, tenantIsAvailability: boolean): any[] => {
  const items: any[] = [];
  const tenantStorage = new TenantStorage();
  const language = tenantStorage?.getLanguage() || DEFAULT_LANGUAGE;

  if (releaseNotes?.length > 0) {
    // If not last read of the user - only show one
    if (!_lastReleaseReaded) {
      const noteContent = releaseNotes[0]?.content?.find((content: any) => content.language === language);
      items.push(addNewReleaseNoteNotification(history, noteContent?.title));
    } else {
      const relaseNotesToShow = [];
      let iterator = 0;
      let lastReadedFound = false;
      // Add only no readed notes
      while (!lastReadedFound && iterator < releaseNotes.length) {
        const note = releaseNotes[iterator];
        if (note.id === _lastReleaseReaded) {
          lastReadedFound = true;
        } else {
          relaseNotesToShow.push(note);
          iterator++;
        }
      }
      // Messages to show
      relaseNotesToShow.forEach((note: IReleaseNote) => {
        const noteContent = note?.content?.find((content: IReleaseNoteContent) => content.language === language);
        items.push(addNewReleaseNoteNotification(history, noteContent?.title || ""));
      });
    }
  }

  if (!tenantIsAvailability) {
    items.push({
      header: toLiteral({ id: "header.tooltip.availability.title" }),
      body: toLiteral({ id: "header.tooltip.availability.2" }),
      type: "read",
    });
  }

  return items;
};

/* Helpers */
const addNewReleaseNoteNotification = (history: any, title: string) => {
  return {
    header: `${toLiteral({ id: "New release" })}: ${title}`,
    body: toLiteral({ id: "Click in the notification to get more details" }),
    type: "read",
    onClick: () => history.push(getRoutePath("RELEASE_NOTES")),
  };
};
