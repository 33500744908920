import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

// For wysiwyg tests to work
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "../src/utilities/polifills/node.remove";
import "../src/utilities/polifills/formData";

import "./index.scss";
import "./assets/styles/main.scss";

import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./redux/store";

import { createBrowserHistory } from "history";

import App from "./components/app/app";

export const history = createBrowserHistory();

function loadIconsAsync(linkUrl: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = linkUrl;
    link.onload = () => resolve(true);
    link.onerror = () => resolve(false);
    const titleElement = document.head.getElementsByTagName("title")?.[0];
    if (titleElement) document.head.insertBefore(link, titleElement);
    else document.head.appendChild(link);
  });
}

(async function renderApp() {
  await Promise.all([
    loadIconsAsync("https://fonts.googleapis.com/icon?family=Material+Icons"),
    loadIconsAsync("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"),
    loadIconsAsync("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"),
  ]);
  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <Router history={history}>
          <App />
        </Router>
      </React.StrictMode>
    </Provider>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
