import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Api from "../../../../api";
import { setPhotoO365 } from "../../../../redux/slices/tenantSlice";
import Loading from "../../loading/loading";
import { CombinedState, RootState } from "../../../../redux/store";
import { NavigationState } from "../../../../redux/slices/navigationSlice";
/**
 * Component that handle the auth context
 */
const UserHandler: React.FC<any> = (props): ReactElement => {
  const dispatch = useDispatch();
  const navigationState: NavigationState = useSelector((state: CombinedState) => state.navigation);

  const currentRoute = navigationState.currentRoute;

  const authData = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentRoute?.public && authData.idToken) fetchO365Photo();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData.idToken]);

  async function fetchO365Photo() {
    try {
      const photo = await Api.getPhotoO365();
      //If is incorrect -> undefined
      dispatch(setPhotoO365(photo === 'Incorrect Image' ? undefined : photo));
    } catch (e) {
      console.error("O365 photo error.");
    }
  }

  return <>{loading ? <Loading id="user-handler" /> : props.children}</>;
};

export default UserHandler;
