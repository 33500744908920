import { useEffect, useRef } from "react";
import { IMetricTelemetry, SeverityLevel, ApplicationInsights } from "@microsoft/applicationinsights-web";
import { History } from "history";

export const useAppInsightsTracking = (appInsight: React.MutableRefObject<ApplicationInsights>, history: History) => {
  const firstActiveTimestamp: React.MutableRefObject<number> = useRef(0);
  const idleStartTimestamp: React.MutableRefObject<number> = useRef(0);
  const lastActiveTimestamp: React.MutableRefObject<number> = useRef(0);
  const totalIdleTime: React.MutableRefObject<number> = useRef(0);
  const idleCount: React.MutableRefObject<number> = useRef(0);
  const idleTimeout: React.MutableRefObject<number> = useRef(5000);
  const intervalId: any = useRef(undefined);

  if (!history || !appInsight) {
    throw new Error("Could not initialize Application Insights tracking");
  }

  const getEngagementTimeSeconds = (): number => {
    return (Date.now() - firstActiveTimestamp.current - totalIdleTime.current - idleCount.current * idleTimeout.current) / 1000;
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (lastActiveTimestamp.current > 0 && idleStartTimestamp.current === 0 && Date.now() - lastActiveTimestamp.current >= idleTimeout.current) {
        idleStartTimestamp.current = Date.now();
        idleCount.current++;
      }
    }, 100);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (firstActiveTimestamp.current === 0) {
      return;
    }

    const engagementTime = getEngagementTimeSeconds();
    const metricData: IMetricTelemetry = {
      name: "Route Engaged Time (seconds)",
      sampleCount: 1,
      average: engagementTime,
    };

    const additionalProperties: { [key: string]: any } = { "Trace of": history.location.pathname };
    appInsight.current.trackTrace({
      message: `Candidate app route: ${history.location.pathname} is engaged ${engagementTime} seconds `,
      severityLevel: SeverityLevel.Information,
    });

    appInsight.current.trackMetric(metricData, additionalProperties);

    // reset values
    firstActiveTimestamp.current = 0;
    idleStartTimestamp.current = 0;
    lastActiveTimestamp.current = 0;
    totalIdleTime.current = 0;
    idleCount.current = 0;

    return () => {
      clearInterval(intervalId.current);
    };
  }, [history.location.pathname, appInsight]);

  const trackActivity = (): void => {
    if (firstActiveTimestamp.current === 0) {
      firstActiveTimestamp.current = Date.now();
      lastActiveTimestamp.current = firstActiveTimestamp.current;
    } else {
      lastActiveTimestamp.current = Date.now();
    }
    if (idleStartTimestamp.current > 0) {
      const lastIdleTime = lastActiveTimestamp.current - idleStartTimestamp.current;
      totalIdleTime.current += lastIdleTime;
      idleStartTimestamp.current = 0;
    }
  };

  return trackActivity;
};
