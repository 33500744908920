import React, { ReactElement, useState } from "react";

import { useSelector } from "react-redux";
import { ContextAction, CopilotState } from "../../../../redux/slices/copilotSlice";
import { CombinedState } from "../../../../redux/store/state";

import { Card, CardBody } from "@adeccoux/tag-ds";

import { toLiteral } from "../../../../helper/locale-utils";

import CopilotAction from "./components/copilot-action";

import "./recruiter-copilot.scss";

/**
 * Component that renders the recruiter copilot
 */
const RecruiterCopilot: React.FC<any> = (): ReactElement => {
  const copilotState: CopilotState = useSelector((state: CombinedState) => state.copilot);

  const { contextActions, userContextParamns } = copilotState;

  const [selectedAction, setSelectedAction] = useState<ContextAction>();

  const [dialogOpen, setDialogOpen] = useState(true);

  function closeDialog() {
    setDialogOpen(false)
    setSelectedAction(undefined)
  }

  return (
    <div id="recruiter-copilot" className={`tag-ds ${contextActions?.length > 0 ? "" : "hidden"}`}>
      {dialogOpen && (
        <Card id="copilot-dialog">
          <CardBody>
            <button className="button-card-icon" onClick={() => closeDialog()}>
              <span className="material-icons">
                close
              </span>
            </button>
            {!selectedAction && <h5 className="mb3">{toLiteral({ id: "recruiter.copilot.main.title" })}</h5>}
            {contextActions?.map((contextAction) => (
              <CopilotAction key={contextAction?.id} contextAction={contextAction} userContextParamns={userContextParamns} setDialogOpen={setDialogOpen} setSelectedAction={setSelectedAction} selectedAction={selectedAction} />
            ))}
          </CardBody>
        </Card>
      )}
      <button type="button" id="copilot-bubble" className={dialogOpen ? "shine-class" : ""} onClick={() => setDialogOpen(true)} onMouseEnter={() => setDialogOpen(true)}>
        <span className="material-icons">auto_awesome</span>
      </button>
    </div>
  );
};

export default RecruiterCopilot;
