import { idInsights, IInsight, SPELLING_SCORE } from "../../../../helper/inclusive-insights-helper";
import { htmlExtraSpacesRegExp, htmlOnlyOneExtraSpace, htmlRegExp } from "../../../../helper/regexp-helper";
import { clearInsightErrors, getClassErrorHTMLInsights, getErrorHTMLInsights } from "./insights";
import { clearSpellingErrors, generateRemoveTagInsightErrorRegexByIndex, getClassErrorHTMLSpelling, getErrorHTMLSpelling } from "./spelling";

// ----------------------------------------------- WINDOW FUNCTIONS -----------------------------------------------
//This function binds the function to fix insight mistake
//allows to trigger the insight fixes OUTSIDE react (due to the HTML post-processing + dangerousHTMLinjection for the WYSIWYG)
export function bindInsightFixEvent() {
  const windowObject: any = window;
  if (typeof windowObject.correctInsightError !== "function") {
    windowObject.correctInsightError = fixInsightMistake;
  }
}

//This function is triggered when the spelling editor tooltip is clicked
// it removes the error and replaces it by the correction
export function fixInsightMistake(element: any, suggestion: string, index: string, _type: string) {
  // element is spelling-tooltip,  parent is spelling-error, grandparent is the container of spelling error
  let editorElement = null;
  const elementInsight = element?.closest(".insight-error");
  if (_type === SPELLING_SCORE) editorElement = element?.closest(".editor-content");

  if (editorElement) {
    const value = getCleanValue(elementInsight);
    const formbox = editorElement?.closest(".input-wrapper");
    // Change the error token to the selected
    const parentContentHTML = editorElement?.innerHTML;
    // THIS IS ONLY NEED TO CHANGE ONE
    const valueToReplace = generateRemoveTagInsightErrorRegexByIndex(index);
    let noTooltipparentContentHTML = parentContentHTML.replace(valueToReplace, suggestion);
    editorElement.innerHTML = noTooltipparentContentHTML;
    editorElement.focus();

    // Remove error from list
    const key = formbox?.id ? formbox.id.replace(idInsights, "") : "";
    const customEvent = new CustomEvent("correctInsight", { detail: { key: key, type: _type, token: value, newText: noTooltipparentContentHTML } });
    editorElement.dispatchEvent(customEvent);
  }
}

// ----------------------------------------------- HTML FUNCTIONS -----------------------------------------------
// Inject code HTML
export function injectHTMLInsights(originalText: string, insights: IInsight[], index: number, spelling: boolean): string {
  let newText = "";
  if (originalText) {
    //clean from previous errors
    newText = clearAllInsightsErrors(originalText);

    insights?.forEach((insight: IInsight, indexError: number) => {
      const suggestion = insight.suggestions && insight.suggestions[0]?.suggestion ? insight.suggestions[0].suggestion : "";
      const suggestionEscape = suggestion.replace(/'/g, "\\'").replace(/"/g, '\\"');

      let errorToken = insight.text;
      errorToken = errorToken?.replace("\n\t", "");
      errorToken = errorToken?.replace("\n\r", "");
      errorToken = errorToken?.replace("\n", "");
      errorToken = errorToken?.replace("\r", "");

      if (newText) {
        //create HTML that will replace the error and wrapp it in the error styles and tooltip
        let classErrorHTML = "";
        let errorHTML = "";
        if (spelling) {
          classErrorHTML = getClassErrorHTMLSpelling(indexError, index);
          errorHTML = getErrorHTMLSpelling(classErrorHTML, errorToken, indexError, suggestion, suggestionEscape);
        } else {
          classErrorHTML = getClassErrorHTMLInsights(indexError, index);
          errorHTML = getErrorHTMLInsights(classErrorHTML, errorToken, indexError);
        }
        //replace the new HTML by the error
        let regexString = errorToken.replace(")", "\\)").replace("(", "\\(");
        regexString = `(?<!insight-error">)${regexString}`;
        const regexAvoidInsights = new RegExp(regexString, "i");
        newText = newText.replace(regexAvoidInsights, errorHTML);
      }
    });
  }
  return newText;
}

// Clear value from tooltip
export function getCleanValue(elementInsight: any): string {
  let text = "";
  if (elementInsight?.childNodes) {
    for (let i = 0; i < elementInsight.childNodes.length; ++i) {
      if (elementInsight?.childNodes[i].nodeType === Node.TEXT_NODE) text += elementInsight?.childNodes[i].textContent;
    }
  }
  return text;
}
// Clear all errors
export function clearAllInsightsErrors(text: string = ""): string {
  let _text = clearSpellingErrors(text);
  _text = clearInsightErrors(_text);
  _text = clearExtraSpaces(_text);
  return _text;
}

export function clearExtraSpaces(text: string = ""): string {
  return text.replace(htmlExtraSpacesRegExp, htmlOnlyOneExtraSpace);
}
export function clearTooltipInsightsErrors(text: string = ""): string {
  return clearSpellingErrors(text);
}

export function cleanHTMLBeforeSend(value: string = "", insightClean: boolean = false): string {
  const htmlReplace = insightClean ? "" : " ";
  const characterSpace = insightClean ? "\n" : "";
  const spaceReplace = `${characterSpace} `;
  let text = value;
  if (insightClean) text = text?.replace(/<\/div>/g, `${characterSpace}</div>`); // Fix problem not space added to insights
  text = text?.replace(htmlRegExp, htmlReplace); //remove rest of dangerous characters
  text = text?.replace(/\n\t/g, spaceReplace); //remove breaklines
  text = text?.replace(/\n\r/g, spaceReplace); //remove breaklines
  text = text?.replace(/\r\n/g, spaceReplace); // Fix problem not space added to insights -> remove concatenated \n
  text = text?.replace(/\n/g, spaceReplace); //remove breaklines
  text = text?.replace(/\r/g, spaceReplace); //remove breaklines
  text = text?.replace(/\n( )?\n( )?/g, spaceReplace); //remove multiple breaklines
  text = text?.replace(/\\/g, " "); //remove bars
  text = text?.replace(/&nbsp;/g, " "); //reduce extra spaces
  text = text?.replace(/ {2}/g, " "); //reduce extra spaces
  text = text?.replace(/\n $/g, ""); //Kick last jump where not more text
  if (!insightClean) text = text?.replace(/’/g, "'"); //remove diff chars
  return text;
}
