import Axios, { CancelTokenSource } from "axios";

export enum Types_Request {
  INSIGHTS = "insights",
  SPELLING = "spelling",
}
interface ICurrentRequest {
  key: string;
  type: string;
  cancelToken: CancelTokenSource;
}

const currentRequestInsights: ICurrentRequest[] = [];
export function abortRequest(key: string = "", types: string[] = []) {
  if (key) {
    // Foreach type and key cancel request if it is not ended
    for (const type of types) {
      const requestToAbort = currentRequestInsights.find((request) => request.key === key && request.type === type);
      if (requestToAbort?.cancelToken) {
        // Action to abort request
        requestToAbort.cancelToken.cancel("Abort by app logic");
      }
    }
  }
}
export function addRequest(key: string, type: string) {
  const cancelTokenSource = Axios.CancelToken.source();
  currentRequestInsights.push({ key, type, cancelToken: cancelTokenSource });
  return cancelTokenSource;
}
export function removeRequest(key: string, type: string) {
  const index = currentRequestInsights.findIndex((request) => request.key === key && request.type === type);
  if (index >= 0) currentRequestInsights.splice(index, 1);
}

export function checkIsCancel(err: any) {
  return Axios.isCancel(err);
}
