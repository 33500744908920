import { _env } from "../config-env";
import { toLiteral } from "./locale-utils";
import { setCookieWithOptions } from "./cookie-storage";
import { getRefreshToken, getToken } from "../api";
import { authSlice } from "../redux/slices/authSlice";

const TENANT_ID = _env.REACT_APP_O365_TENANTID || `e70310d4-e68b-447c-889a-fcedd54041fb`;
const CLIENT_ID = _env.REACT_APP_O365_CLIENTID || `0227be26-4593-44ad-a09d-41a2fe76e5c2`;
const RESPONSE_TYPE = `code`;
const SCOPE = `openid%20offline_access`;

const URL_BASE = _env.REACT_APP_O365_URL || `https://login.microsoftonline.com`;
const URL_LOGIN_O365 = `${URL_BASE}/${TENANT_ID}/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&Response_type=${RESPONSE_TYPE}&Redirect_uri={REDIRECT_URI}&Scope=${SCOPE}&state={URL_STATE}`;

/* FUNCTIONS */
export function getADLoginLink(location: any) {
  const REDIRECT_URI = redirectUri();
  const URL_STATE = getUrlState(location);
  window.location.href = URL_LOGIN_O365.replace("{REDIRECT_URI}", REDIRECT_URI).replace("{URL_STATE}", URL_STATE);
}

export function getUrlState(location: any) {
  let redirectURL = "";
  let params = "";
  // Generate redirect URL and params
  if (location?.search?.split) {
    redirectURL = location.search.split("?ref=")[1];
    if (redirectURL?.split("?")[1]) {
      params = encodeURIComponent(redirectURL.split("?")[1]);
      redirectURL = redirectURL.split("?")[0];
    }
  }
  // Return prev location
  let prevLocation = "";
  if (redirectURL && params) {
    prevLocation = `${redirectURL}?${params}`;
  } else if (location && location.search && location.search.indexOf("?ref=") !== -1) {
    prevLocation = location.search.replace("?ref=", "");
  }
  return prevLocation;
}

export function redirectUri() {
  return `${window.location.origin}${window.location.pathname}/token`;
}

export function getEnvName() {
  const envNames: any = { a: "LOCAL", a2: "LOCAL_DEV_DB", b: "LOCAL", d: "DEVELOP", t: "TEST", u: "UAT", p: "PROD", tau: "INT AUS", uau: "UAT AUS" };
  return envNames[_env.REACT_APP_ENV_PREFIX!] || "";
}

export function logout(history: any, message: string, dispatch: Function, callback?: Function) {
  let ref = "";
  if (window.location.pathname !== "/" && window.location.pathname !== "/login" && window.location.pathname !== "/login/token") {
    if (window.location.search) {
      ref = "?ref=" + window.location.pathname + window.location.search;
    } else {
      ref = "?ref=" + window.location.pathname;
    }
  } else if (window.location.pathname === "/login" && window.location.search) {
    ref = window.location.search;
  }
  history.push("/login" + ref, {
    error: message,
  });

  // shortValidity = true for logout
  const data = { id_token: "", expires_in: 5, refresh_token: "" };
  assignTokens(data, dispatch, true);

  if (callback) callback();
}
export function getErrorMessage(error: string): string {
  switch (error) {
    case "AuthHeaderNoBearerError":
      return toLiteral({ id: "login.token.authheadernobearererror" });
    case "AuthTokenUndecodablePayloadError":
      return toLiteral({ id: "login.token.authtokenundecodablepayloaderror" });
    case "AuthTokenNoIssuerError":
      return toLiteral({ id: "login.token.authtokennoissuererror" });
    case "AuthTokenExpiredError":
      return toLiteral({ id: "login.token.authtokenexpirederror" });
    case "SessionExpiredError":
      return toLiteral({ id: "login.error.sessionexpirederror" });
    case "AuthTokenUnknownIssuerError":
      return toLiteral({ id: "login.token.authtokenunknownissuererror" });
    case "AuthTokenSubMissingError":
      return toLiteral({ id: "login.token.authtokensubmissingerror" });
    case "AuthMultipleErrorsError":
      return toLiteral({ id: "login.token.authmultipleerrorserror" });
    case "AuthTokenUserMissingError":
    case "Insufficient scope":
      return toLiteral({ id: "login.token.authtokenusermissingerror" });
    case "Login to access":
      return toLiteral({ id: "Login to access" });
    case "MicrosoftError":
      return toLiteral({ id: "login.microsoft.error" });
    default:
      return toLiteral({ id: error });
  }
}

export async function getTokens(code: string, dispatch: Function): Promise<void> {
  getToken({ code })
    .then((data) => verifyData(data, dispatch))
    .catch((e) => {
      dispatch(authSlice.actions.setError({ error: "Microsoft error" }));
    });
}

export async function getTokensByRefreshToken(dispatch: Function): Promise<number> {
  return getRefreshToken()
    .then((data) => verifyData(data, dispatch))
    .catch((e) => {
      dispatch(authSlice.actions.setError({ error: "Microsoft error" }));
    });
}

function verifyData(data: any, dispatch: Function) {
  if (data.error) {
    dispatch(authSlice.actions.setError({ error: "Microsoft error" }));
    return data;
  }
  return assignTokens(data, dispatch);
}

function assignTokens(data: any, dispatch: Function, shortValidity: boolean = false) {
  if (data.id_token && data.expires_in && data.refresh_token) {
    // Set expiration time based on shortValidity flag
    const expirationTime = shortValidity ? Date.now() + 5 * 1000 : Date.now() + data.expires_in * 1000;
    const cookieExpiration = new Date(expirationTime + 5 * 24 * 60 * 60 * 1000);
    // Use cookie helper
    const options = {
      cookieExpiration: cookieExpiration.toUTCString(),
      sameSite: "None",
      secure: true,
    };
    const timeStampCookie = Buffer.from(Date.now().toString()).toString("base64");
    setCookieWithOptions("lastCheck", timeStampCookie, options);
    dispatch(authSlice.actions.setNewToken({ token: data.id_token, tokenExpiry: expirationTime, idToken: data.id_token }));
    return expirationTime;
  } else {
    console.error("Error, ", data);
    return 0;
  }
}

export function assignExternalToken(token: any, dispatch: Function) {
  const expirationTime = Date.now() + 5 * 60 * 1000;
  dispatch(authSlice.actions.setNewToken({ token: token, tokenExpiry: expirationTime, idToken: token, external: true }));
  return expirationTime;
}
