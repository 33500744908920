import React, { createRef } from "react";
import _ from "lodash";
import { IValidationSummary, IValidationSummaryItem, validateInputVerbose } from "./utilities/validations";
import { serializeForm, getNestedObjectValueByString } from "./utilities/serialize";

import { addNotification, removeNotification } from "../notifications/notifications-service";
import { toLiteral } from "../../../helper/locale-utils";
import { IFormMultilanguageOption } from "./multilanguage/multi-language-service";
export interface IProps {
  debounceTimer?: number;
  currentLanguage?: IFormMultilanguageOption;
  availableLanguages?: IFormMultilanguageOption[];
  [others: string]: any;
}
export interface IState {
  currentLanguage?: IFormMultilanguageOption;
  availableLanguages?: IFormMultilanguageOption[];
  [others: string]: any;
}

export const FormContext = React.createContext({});

export default class Form extends React.Component<IProps, IState> {
  inputs: React.Component[];
  prevInputs: React.Component[];
  isSubmitted: boolean;
  valuesHaveBeenSet = false;
  constructor(props: any) {
    super(props);
    this.state = {
      error: {},
      currentLanguage: this.props.currentLanguage,
      availableLanguages: this.props.availableLanguages,
    };
    this.inputs = [];
    this.prevInputs = this.inputs;
    this.isSubmitted = false;
  }

  componentDidMount() {
    this.setvalues();

    if (this.props.currentLanguage) {
      this.setLanguage(this.props.currentLanguage);
    }
    if (this.props.availableLanguages) {
      this.setAvailableLanguages(this.props.availableLanguages);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const _self = this; //easier debugging
    if (JSON.stringify(_self.props.values) !== JSON.stringify(prevProps.values) || _self.props.step !== prevProps.step || (_self.inputs !== this.prevInputs && !_self.valuesHaveBeenSet)) {
      _self.setvalues();
    }

    //the following if statement is to set the for the multilangiage functionality, if the props change, they update the current language
    if (JSON.stringify(_self.props.currentLanguage) !== JSON.stringify(prevProps.currentLanguage)) {
      _self.setLanguage(_self.props.currentLanguage);
    }
    //the following if statements is to set the for the multilangiage functionality, if the props change, they update the available languages
    if (JSON.stringify(_self.props.availableLanguages) !== JSON.stringify(prevProps.availableLanguages)) {
      _self.setAvailableLanguages(_self.props.availableLanguages);
    }
  }

  /**
   * This shows the relevant language change notifications and updates the current language in the form state,
   * which then updates the values of all fields after validating the current language is correct
   * @param language
   * @returns  true if the language can be set and false if not
   */
  setLanguage: (language: IFormMultilanguageOption | undefined) => Promise<boolean> = async (language: IFormMultilanguageOption | undefined) => {
    const _self = this; //debugging made easier

    if (language?.isoCode !== _self.state?.currentLanguage?.isoCode) {
      // If component is just mounted and the language is not set yet, we don't need to validate the form
      const _isValid = await _self.isValid(); //first we validate that the current form is valid, otherwise we prevent the user to change language
      if (_isValid) {
        const values = _self.serialize(); //we serialize the current form values, later we'll append them to the updated form to don't lose them
        if (language?.isoCode !== _self.state?.currentLanguage?.isoCode) {
          _self.setState({ currentLanguage: language }); //set the language to the form
        }
        if (_self.inputs && _self.inputs.length) {
          _self.inputs.forEach((element: React.Component) => {
            //set the language to all children inputs
            element.setState({
              currentLanguage: language,
            });
          });
        }
        _self.setvalues(values); //this is done so we don't lose the values when switching from languages
        //const notificationMessage = language?.name ? `Language switched to ${language?.name}` : `Language switched to default`;
        const newLanguage = language?.name || "default";
        removeNotification("language-switch");
        addNotification({ type: "success", id: "language-switch", content: toLiteral({ id: "form.notification.language.switched" }) + newLanguage, 
        timer: 5 });
        return true;
      } else {
        removeNotification("language-switch");
        addNotification({ type: "error", id: "language-switch", content: toLiteral({ id: "You need to check the entries in current language prior to switching to another one." }), timer: 5 });
      }
    }
    return false;
  };
  /**
   * This function updates the state of a form adding the list of available languages
   * @param availableLanguages
   */
  setAvailableLanguages = async (availableLanguages: IFormMultilanguageOption[] | undefined) => {
    const _self = this; //debugging made easier
    //I removed this line because overrides the right values when adding additional languages -> const values = this.serialize(); //we serialize the current form values, later we'll append them to the updated form to don't lose them
    //I removed this line because overrides the right values when adding additional languages -> _self.setvalues(values); //this is done so we don't lose the values when switching from languages
    if (availableLanguages && availableLanguages.length > 1) {
      _self.setState({ availableLanguages: availableLanguages });
    } else {
      _self.setState({ availableLanguages: [] });
      _self.setLanguage(undefined);
    }
  };

  addInputToContext = (input: React.Component) => {
    this.prevInputs = this.inputs;
    const element: any = input;
    this.setInputValue(element);
    this.inputs = [...this.prevInputs, input];
  };

  removeInputFromContext = (input: React.Component) => {
    this.prevInputs = this.inputs;
    if (this.prevInputs && this.inputs.length) {
      this.inputs = this.inputs.filter((_item: any) => {
        return _item !== input;
      });
    }
  };

  resetFields = () => {
    this.inputs.forEach((element: any) => {
      if ((element.state.type && element.state.type === "checkbox") || element.state.type === "radio") {
        element.setState({
          // value: element.props.value,
          isValid: null,
          errors: null,
          checked: element.props.checked,
        });
      } else {
        element.setState({
          value: element.props.value,
          isValid: null,
          errors: null,
        });
      }
    });
  };

  bindChangeInInputs = (e: any) => {
    this.debounceInputChange(e);
  };

  debounceInputChange = _.debounce(
    (e) => {
      const self = this;
      if (e && this.props.onChange && typeof this.props.onChange === "function") {
        self.props.onChange(e);
      }
    },
    this.props.debounceTimer !== undefined ? this.props.debounceTimer : 200
  );

  setvalues = (values = this.props.values) => {
    if (this.inputs && this.inputs.length) {
      this.inputs.forEach((element: React.Component) => {
        if (values) {
          this.setInputValue(element, values);
        }
      });
      this.valuesHaveBeenSet = true;
    }
  };

  addParentFormOnChangeToInput = (element: any) => {
    const parentFormOnChange = typeof this.props?.onChange === "function" ? this.bindChangeInInputs : undefined;
    if (parentFormOnChange) {
      if (element.state?.parentFormOnChange !== parentFormOnChange) {
        element.setState({
          parentFormOnChange: parentFormOnChange,
        });
      }
    }
  };

  setInputValue = (element: any, values = this.props.values) => {
    if (element._isMounted) {
      const key = element.state.name ?? element.props.name;
      let value = getNestedObjectValueByString(values, key);

      try {
        //if there is no value and the key of the input is multilanguage (ie: name__it-IT)
        //we try to fetch the value from the original/default field (ie: name)
        // #81242 => just checking if value is undefined, because if we check if is empty string, then, we will not be able to remove the text
        if (value === undefined && key && key.indexOf("__")) {
          let substring = key.split("__")[0]; //trim the ISO code from the key if the value was not detected-
          //remove multilanguage subtring to check default value of the form
          if (substring?.startsWith("multilanguage")) substring = substring?.replace("multilanguage.", "");
          value = getNestedObjectValueByString(values, substring);
        }
      } catch (e) {}

      this.addParentFormOnChangeToInput(element);
      //checkboxes and radios
      switch (element.type) {
        case "SwitchInput":
        case "CheckboxInput":
        case "TagInput":
        case "RadioInput":
          let isChecked =
            (value === true && element.props.value === "true") || (value && value === element.props.value) || (value && typeof value === "object" && value.indexOf(element.props.value) !== -1);
          if (element.state.checked !== isChecked && element.props.name) {
            element.setState({
              isValid: null,
              checked: isChecked,
              errors: null,
              value: value,
            });
          }
          break;
        case "FileDrop":
        case "FileSelector":
          if (value) {
            element.setState({
              isValid: null,
              value: value,
              errors: null,
            });
          }
          break;
        case "Wysiwyg":
          element.state.value !== value && element.change(value);
          break;
        default:
          if (!element.props.value && value !== null && value !== undefined) {
            if (element.state.value !== value) element.change(value);
          } else {
            if (element.props?.defaultValue && value === null) {
              element.change(element.props.defaultValue);
            } else if (element.state.value !== element.props.value) {
              element.change(element.props.value);
            }
          }
          break;
      }
    }
  };

  handleSubmit = async (SyntheticEvent: any) => {
    SyntheticEvent.preventDefault();
    SyntheticEvent.persist();
    const self = this;
    this.isSubmitted = true;
    SyntheticEvent.nativeEvent.target.isValid = await this.isValid();
    self.inputs.forEach((element: any) => {
      if (element._isMounted) {
        //once the form is submitted, always validate on change for every input
        element.setState({ validateOnChange: true });
      }
    });
    if (typeof this.props.onSubmit === "function") {
      await this.props.onSubmit(SyntheticEvent.nativeEvent);
    }
  };

  /**
   * This function triggers the validation (this.getValidation) and returns just true or false depending if all form inputs are valid
   * @returns boolean true/false
   */
  isValid = async () => {
    const validatonResponse: IValidationSummary = await this.getValidation();
    return validatonResponse.valid;
  };

  /**
   * This function goes through all inputs contained inside the form, checking their validation functions and comparing them with its value, also marks the inputs as invalid if they don't match the conditions
   * @returns an object that will contain the following
   *  IValidationSummary  { valid: boolean; items:  IValidationSummaryItem[]; }
   */
  getValidation = async () => {
    const self = this;
    let formIsValid = true;
    let validationSummary: IValidationSummary = {
      valid: true,
      items: [],
    };
    if (self.inputs) {
      for await (const element of self.inputs) {
        const _element: any = element; //avoid compilation issue declaring typescript type in for await
        if (_element._isMounted) {
          let validationResponse: IValidationSummaryItem | undefined = await validateInputVerbose(_element, self);
          validationSummary.items.push(validationResponse);
          if (validationResponse && !validationResponse.valid) formIsValid = validationResponse.valid; // Only change to false when error founded
        }
      }
    }
    validationSummary.valid = formIsValid;
    return validationSummary;
  };

  formRef: any = createRef();

  serialize = (excludeEmptyValues: boolean | undefined = false) => {
    let formHTMLElement = this.formRef && this.formRef.current ? this.formRef.current : undefined;
    return serializeForm(formHTMLElement, excludeEmptyValues);
  };

  submit = () => {
    let formHTMLElement: any = this.formRef && this.formRef.current ? this.formRef.current : undefined;
    if (formHTMLElement) {
      formHTMLElement.dispatchEvent(new Event("submit"));
    }
  };

  render() {
    return (
      <FormContext.Provider value={this}>
        <form
          id={this.props.id}
          data-testid={this.props.id}
          ref={this.formRef}
          className={`${this.props.className ? this.props.className : ""}${this.props.loading ? " loading" : ""}`}
          noValidate
          onSubmit={this.handleSubmit}
          aria-label={this.props.id}
        >
          {this.props.children}
        </form>
      </FormContext.Provider>
    );
  }
}
