import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import Modal, { ModalHeader, ModalFooter } from "@adeccoux/tag-ds/modal";

import { toLiteral } from "../../../../helper/locale-utils";
import { logout } from "../../../../helper/auth-helper";
import { getCurrentRoute } from "../../../../utilities/navigation";

const InactiveContainer: React.FC<any> = (props): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentRoute = getCurrentRoute(location?.pathname);

  // Inactive timeout set to 30 minutes (minutes * seconds * miliseconds)
  const INACTIVE_TIMEOUT = 30 * 60 * 1000;
  // Remaining time to display warning set to 1 minute (minutes * seconds * miliseconds)
  const WARNING_TIMEOUT = 1 * 60 * 1000;

  const onIdle = () => {
    if (currentRoute?.public) return;
    logout(history, "login.error.sessionexpirederror", dispatch);
  };

  const onMessage = (inactivityMessage: any) => {
    if (inactivityMessage === "logout") {
      logout(history, "login.error.logout", dispatch);
    }
  };


  const onAction = (e: any) => {
    if (e?.target.id === "inactivity-modal-logout-button") {
      logout(history, "login.error.logout", dispatch);
    }
  };

  const { getRemainingTime, message } = useIdleTimer({
    onIdle,
    onMessage,
    onAction,
    timeout: INACTIVE_TIMEOUT,
    crossTab: true,
    syncTimers: 200,
    debounce: 200,
    events: ["keydown", "mousedown", "touchstart", "touchmove", "MSPointerDown", "MSPointerMove"],
  });

  const [remaining, setRemaining] = useState("00");

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(`${String(((getRemainingTime() % WARNING_TIMEOUT) / 1000).toFixed(0)).padStart(2, "0")}`);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const getDescription = () => {
    return `${toLiteral({ id: "timeout.warning.modal.text.1" })} 00:${remaining} ${toLiteral({ id: "timeout.warning.modal.text.2" })}`;
  };

  const handleLogout = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    message("logout");
    logout(history, "login.error.logout", dispatch);
  };

  return (
    <>
      {getRemainingTime() < WARNING_TIMEOUT - 500 && location?.pathname !== "/login" && !currentRoute?.public && (
        <Modal open renderAsPortal>
          <ModalHeader>
            <h4 className="g-item_12">{toLiteral({ id: "timeout.warning.modal.title" })}</h4>
          </ModalHeader>
          <div className="g-item_12 mb2">{getDescription()}</div>
          <ModalFooter className="-footer-margin">
            <button id="inactivity-modal-logout-button" data-testid="cancel-button" className="button-secondary" type="button" onClick={handleLogout}>
              {toLiteral({ id: "timeout.warning.modal.logout" })}
            </button>
            <button data-testid="save-button" className="button-primary" type="button" onClick={() => {}}>
              {toLiteral({ id: "timeout.warning.modal.stayloggedin" })}
            </button>
          </ModalFooter>
        </Modal>
      )}
      {props.children}
    </>
  );
};

export default InactiveContainer;
