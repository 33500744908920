const regexBefore = /( [^\s]*$|^[^\s]*$)/;
const regexAfter = /^([^\s]*|^[^\s]*)/;
const regexFindError = /insight-error"$/;
const regexFindDataKey = /data-key="(\d)+" class="[a-z|\-| ]+"$/g;
const regexFindWord = (word: string) => `(?<=( |>))${word}(?=(<| ))`;

interface IWordAround {
  previousWord: string;
  currentWord: string;
  afterWord: string;
  text: string;
}

export function splitStringByIndex(text: string = "", index: number = -1): string[] {
  let stringSplit: [string, string];
  if (index <= 0) {
    stringSplit = ["", text];
  } else if (!text || index > text.length) {
    stringSplit = [text, ""];
  } else {
    stringSplit = [text.substring(0, index), text.substring(index)];
  }
  return stringSplit;
}

// Get word around position
export function getWordsAround(textSplited: string[] = ["", ""]): IWordAround {
  let [startPart = "", endPart = ""] = textSplited;
  let indexFirstCurrent = startPart.search(regexBefore) || 0;
  let currentWord = startPart.substring(indexFirstCurrent).trim();
  let previousText = startPart.substring(0, indexFirstCurrent);

  let matchEndPart = endPart.match(regexAfter) || [""];
  let indexLastCurrent = matchEndPart[0].length;
  currentWord = currentWord + endPart.substring(0, indexLastCurrent).trim();
  let afterText = endPart.substring(indexLastCurrent).trim();

  let previousWord = "";
  if (previousText) {
    let indexPrevious = previousText.search(regexBefore) || 0;
    previousWord = previousText.substring(indexPrevious).trim();
  }
  let afterWord = "";
  if (afterText) {
    let matchAfter = afterText.match(regexAfter) || [""];
    let indexAfter = matchAfter[0].length;
    afterWord = afterText.substring(0, indexAfter).trim();
  }

  return {
    previousWord,
    currentWord,
    afterWord,
    text: [previousWord, currentWord, afterWord].join(" ").trim(),
  };
}

// Get the position in the HTML content
export function searchInHTML(html: string, textToSearch: IWordAround, index: number): number {
  let indexMoreNear = -1;
  const currentWord = textToSearch?.currentWord || "";
  if (currentWord) {
    let distance = 0;
    const regex = new RegExp(regexFindWord(currentWord), "g");
    // Bucle to find more near to index
    let find;
    while ((find = regex.exec(html)) !== null) {
      const _index = find.index;
      // Calculate distance from index
      if (indexMoreNear === -1 || Math.abs(index - _index) < distance) {
        indexMoreNear = _index;
        distance = Math.abs(index - _index);
      }
    }
  }
  return indexMoreNear;
}

// Get current position of carrent in TextContent
export function getCaretPosition(e: any): number {
  const doc = e.target.ownerDocument || e.target.document;
  const win = doc.defaultView || doc.parentWindow;
  var range = win.getSelection().getRangeAt(0);
  var preCaretRange = range.cloneRange();
  preCaretRange.selectNodeContents(e.target);
  preCaretRange.setEnd(range.endContainer, range.endOffset);
  return preCaretRange.toString().length || 0;
}

// In HTML search the closest tag of html close, check if it is error and get data-key
// Return number in string format
export function searchIndexError(html: string, index: number): string {
  let key = "-1";
  const indexCloseHTMLTag = html.lastIndexOf(">", index);
  if (indexCloseHTMLTag > -1) {
    const stringToSearch = html.substring(0, indexCloseHTMLTag);
    const isInError = regexFindError.test(stringToSearch);
    // If is inside InsightErrorTag
    if (isInError) {
      const result = regexFindDataKey.exec(stringToSearch);
      // Avoid "0" and other problems with checks
      if (result !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_find, keyFinded] = result;
        key = keyFinded;
      }
    }
  }
  return key;
}

export function capitalize(text: string, onlyFirst?: boolean): string {
  const string = text || "";
  let _string = string.charAt(0).toUpperCase();
  if (string.length > 0) {
    if (onlyFirst) {
      _string = _string + string.slice(1);
    } else {
      _string = _string + string.slice(1).toLowerCase();
    }
  }
  return _string;
}
