import { lazy } from "react";
import { getFeatureConfigurationIsEnabled } from "../../../helper/feature-configuration-helper";
import cammioReportTenant from "../../administrator/cammio-report/cammio-report-tenant";

const CandidatePage = lazy(() => import("../../../components/candidate/candidate"));
const CreatorIntroPage = lazy(() => import("../../../components/creator-intro/creator-intro"));
const CreatorPage = lazy(() => import("../../../components/creator/creator"));
const DetailsPage = lazy(() => import("../../../components/details/details"));
const HomePage = lazy(() => import("../../../components/home/home"));
const LoginPage = lazy(() => import("../../../components/login/login"));
const NotFoundPage = lazy(() => import("../../../components/shared/not-found/not-found"));
const TermsRedirectPage = lazy(() => import("../../../components/terms/terms"));
const QuickApplyPublicPage = lazy(() => import("../../quick-apply-web-public/quick-apply-public-page"));
const QuickApplyVerificationPublicPage = lazy(() => import("../../quick-apply-web-public/quick-apply-verification-public-page"));
const CandidateWebPage = lazy(() => import("../../../components/candidate-web/candidate-web"));
const CandidatePublicPage = lazy(() => import("../../candidate-web-public/candidate-web-public"));
const CandidateRedirectPage = lazy(() => import("../../../components/candidate-web-redirect/candidate-web-redirect"));
const AdminSettings = lazy(() => import("../../../components/administrator/admin-settings/admin-settings"));
const AdministratorProcessTemplatesPage = lazy(() => import("../../administrator/manage-templates/manage-templates"));
const FeaturesConfiguration = lazy(() => import("../../../components/administrator/features-configuration/features-configuration"));
const AdministratorTermsPage = lazy(() => import("../../../components/administrator/terms/terms"));
const CookieBannerPage = lazy(() => import("../../../components/administrator/cookie-banner/cookie-banner-config"));
const AdministratorActivityLogsPage = lazy(() => import("../../../components/administrator/activity-logs/activity-logs"));
const HealthyPage = lazy(() => import("../../../components/healthy/healthy"));
const LandingInterviewRedirectPage = lazy(() => import("../../../components/landing/landing"));
const AdministratorMailsPage = lazy(() => import("../../../components/administrator/email-notifications/email-notifications"));
const ShareCandidateWebPublicPage = lazy(() => import("../../../components/sharing-candidate-web-public/sharing-candidate-web-public"));
const RecruiterProfilePage = lazy(() => import("../../../components/recruiter-profile/recruiter-profile"));
const AdministratorClientsPage = lazy(() => import("../../administrator/clients/clients"));
const AdministratorLocalClientsPage = lazy(() => import("../../../components/administrator/local-clients/local-clients"));
const AdministratorClientsStatsPage = lazy(() => import("../../administrator/clients-stats/clients-stats"));
const AdministratorRecruiterStatsPage = lazy(() => import("../../../components/administrator/recruiter-stats/recruiter-stats"));
const ProcessFunnelingPage = lazy(() => import("../../../components/process-funneling/process-funneling"));
const LiveInterviewPage = lazy(() => import("../../../components/live-interview/live-interview"));
const ExtraLiveRedirectPage = lazy(() => import("../../../components/extra-live-redirect/extra-live-redirect"));
const AdministratorOfferTemplatesPage = lazy(() => import("../../../components/administrator/offer-templates/offer-templates"));
const AdministratorXpertCatalogPage = lazy(() => import("../../../components/administrator/xpert-catalog/xpert-catalog"));
const AdministratorGlobalCompetencyFrameworkSkillPage = lazy(() => import("../../../components/administrator/xpert-global-competency-framework-skill/xpert-global-competency-framework-skill"));
const AdministratorReferenceCheckLibraryPage = lazy(() => import("../../../components/administrator/reference-check-library/reference-check-library"));
const AdministratorPositionPage = lazy(() => import("../../../components/administrator/position/position"));
const AdministratorContractTypePage = lazy(() => import("../../../components/administrator/contract-type/contract-type"));
const AdministratorScreeningQuestionTypesPage = lazy(() => import("../../../components/administrator/screening-question-types/screening-question-types"));
const AdministratorVideoTypesPage = lazy(() => import("../../../components/administrator/video-types/video-types"));
const AdministratorStatsPage = lazy(() => import("../../../components/administrator/stats/stats"));
const CandidatePublicConfirmationPage = lazy(() => import("../../../components/candidate-web-confirmation/candidate-web-confirmation"));
const AdministratorVideoQuestionsLibraryPage = lazy(() => import("../../administrator/video-questions/video-questions"));
const AdministratorCalendarsPage = lazy(() => import("../../../components/administrator/manage-calendars/manage-calendars"));
const JobListingPage = lazy(() => import("../../../components/candidate-sourcing-ui/routing/job-listing-routing"));
const JobPostingPage = lazy(() => import("../../../components/candidate-sourcing-ui/routing/job-listing-posting-routing"));
const JobDescriptionGenerator = lazy(() => import("../../candidate-sourcing-ui/job-desc-generator/job-description-generator"));
const JobDescription = lazy(() => import("../../../components/candidate-sourcing-ui/routing/job-description-routing"));
const PDFJobDescriptionPage = lazy(() => import("../../candidate-sourcing-ui/job-description/pdf-job-description/pdf-job-description"));
const JobDescriptionsReportPage = lazy(() => import("../../../components/candidate-sourcing/job-descriptions-report/job-descriptions-report"));
const JobDescriptionsSectionsCategoriesPage = lazy(() => import("../../../components/candidate-sourcing/job-description-sections-categories/job-descriptions-sections-categories"));
const JobDescriptionsBoardsPage = lazy(() => import("../../../components/candidate-sourcing/job-desc-boards/job-description-boards"));
const UiLibraryPage = lazy(() => import("../../../components/shared/ui-library/ui-library"));
const CandidatePublicUnsubscribePage = lazy(() => import("../../../components/candidate-web-unsubscribe/candidate-web-unsubscribe"));
const UserCalendarsPage = lazy(() => import("../../calendars-old/calendars"));
const HomeJobsDetailsPage = lazy(() => import("../../../components/home/components/home-jobs/components/home-jobs-details/home-jobs-details"));
const HomeJobsFormPage = lazy(() => import("../../../components/home/components/home-jobs/components/home-jobs-form/home-jobs-form"));
const UserVideoLibraryPage = lazy(() => import("../../../components/personal-video-library/video-library"));
const HomeCandidatesDetailsPage = lazy(() => import("../../../components/home/components/home-candidates/components/home-candidates-details/home-candidates-details"));
const ShortlistPage = lazy(() => import("../../../components/shorlist/shortlist"));
const ShortlistPublicPage = lazy(() => import("../../../components/shortlist-public/shortlist-public"));
const RecruiterPortalPage = lazy(() => import("../../../components/recruiter-portal/recruiter-portal"));
const AdministratorOperationalProcessesReportsPage = lazy(() => import("../../../components/administrator/operational-processes-reports/operational-processes-reports"));
const AdministratorOperationalRecruitersReportsPage = lazy(() => import("../../../components/administrator/operational-recruiters-reports/operational-recruiters-reports"));
const AdministratorTenantScreeningAttributesPage = lazy(() => import("../../../components/administrator/tenant-screening-attributes/tenant-screening-attributes"));
const AdministratorGlobalScreeningAttributesPage = lazy(() => import("../../../components/administrator/global-screening-attributes/global-screening-attributes"));
const AdministratorGlobalCobrandsPage = lazy(() => import("../../../components/administrator/global-cobrands/global-cobrands"));
const ReferenceCheckPublicPage = lazy(() => import("../../../components/reference-check/reference-check"));
const AdministratorGlobalExternalSystems = lazy(() => import("../../administrator/global-external-systems/external-systems/external-systems"));
const AdministratorGlobalOperationsComponent = lazy(() => import("../../administrator/global-external-systems/operations/operations"));
const AdministratorExternalSystemOperationTenant = lazy(() => import("../../administrator/global-external-systems/external-systems-operations-tenant/external-systems-operation-tenant"));
const AdministratorLinks = lazy(() => import("../../administrator/links/links"));
const TenantEmails = lazy(() => import("../../../components/administrator/tenant-emails/tenant-emails"));
const AdministratorMyaEvents = lazy(() => import("../../../components/administrator/mya-events/mya-events"));
const AdministratorProactiveSourcing = lazy(() => import("../../administrator/proactive-sourcing/proactive-sourcing-admin"));
const AdministratorUploadJDManually = lazy(() => import("../../administrator/upload-jd-manually-tool/upload-jd-manually-tool"));
const ProactiveSourcing = lazy(() => import("../../../components/proactive-sourcing/proactive-sourcing"));
const ServiceCreationPage = lazy(() => import("../../../components/service-creation/service-creation"));
const ServiceAssessmentPage = lazy(() => import("../../../components/service-assessment/service-assessment"));
const ServiceAssessmentDetailPage = lazy(() => import("../../../components/service-assessment-detail/service-assessment-detail"));
const ServiceDetailPage = lazy(() => import("../../../components/service-detail/service-detail"));
const PersonVideoSharingPage = lazy(() => import("../../../components/person-video-sharing/person-video-sharing"));
const JobDescriptionTemplatesListing = lazy(() => import("../../candidate-sourcing-ui/job-desc-templates/listing/job-desc-templates-listing"));
const JobDescriptionTemplateUI = lazy(() => import("../../candidate-sourcing-ui/job-desc-templates/job-desc-template"));
const ManageSmsCandidateStatus = lazy(() => import("../../administrator/sms/candidate-status/candidate-status"));
const ManageBudgets = lazy(() => import("../../administrator/manage-budgets/manage-budgets"));
const OperationalFeedbackReports = lazy(() => import("../../administrator/operational-feedback-reports/operational-feedback-reports"));
const ClientBrand = lazy(() => import("../../../components/administrator/client-brand/client-brand"));
const JobPostingReports = lazy(() => import("../../administrator/job-posting-reports/job-posting-reports-download"));
const ConversionRatesReport = lazy(() => import("../../administrator/conversion-rates-report/conversion-rates-report"));
const LoginToken = lazy(() => import("../../login-token/login-token"));

// New design system pages
const ProcessCreationIntro = lazy(() => import("../../recruitment/processes/new/process-intro"));
const ProcessCreationPhases = lazy(() => import("../../recruitment/processes/edit/process-phases"));
const RecruitmentHome = lazy(() => import("../../recruitment-home/recruitment-home"));
const ProcessDetails = lazy(() => import("../../process-details/process-details"));
const CandidatesDetails = lazy(() => import("../../candidate-details/candidate-details"));
const Assessments = lazy(() => import("../../recruitment/services/assessments/assessments"));
const jobDetails = lazy(() => import("../../recruitment/jobs/job-details/job-details"));
const JobCreation = lazy(() => import("../../recruitment/jobs/job-creation/job-creation-form"));
const ServiceDetailNewPage = lazy(() => import("../../recruitment/services/service-details/service-details"));
const PersonDetailsPage = lazy(() => import("../../recruitment/persons/person-details"));
const ShortlistUIPage = lazy(() => import("../../recruitment/processes/shortlist/shortlist-ui"));
const VideoLibraryPage = lazy(() => import("../../video-library/video-library-page"));
const ServiceCreationIntro = lazy(() => import("../../recruitment/services/new/service-creation-init"));
const ServiceCreation = lazy(() => import("../../recruitment/services/edit/service-creation"));
const Calendars = lazy(() => import("../../calendars/calendars"));
const ComplexCalendar = lazy(() => import("../../calendars/details/complex-calendar"));
const UserProfile = lazy(() => import("../../recruitment/profile/recruiter-profile"));
const RecruitersModulesPage = lazy(() => import("../../administrator/recruiters-modules/recruiters-modules"));
const AdministratorGlobalTagsPage = lazy(() => import("../../administrator/global-tags/global-tags"));
const AdministratorTenantTagsPage = lazy(() => import("../../administrator/tenant-tags/tenant-tags"));
const AdministratorNonAvailabilityCalendarsPage = lazy(() => import("../../administrator/manage-non-availability-calendars/manage-non-availability-calendars"));
const AdministratorTenantVariablesPage = lazy(() => import("../../administrator/tenant-variables/tenant-variables"));
const AdministratorGlobalPersonsPage = lazy(() => import("../../administrator/global-persons/global-persons"));
const AdministratorUsersPage = lazy(() => import("../../administrator/users/users"));
const AdministratorTenantCategoriesPage = lazy(() => import("../../administrator/categories/categories"));
const AdministratorTenantLanguagesPage = lazy(() => import("../../administrator/languages/languages"));
const AdministratorGlobalCategoriesPage = lazy(() => import("../../administrator/global-categories/global-categories"));
const AdministratorManageTenantPage = lazy(() => import("../../administrator/manage-tenant/manage-tenant"));
const AdministratorManageLiterals = lazy(() => import("../../administrator/manage-literals/manage-literals"));
const AdministratorTenantsPage = lazy(() => import("../../administrator/tenants/tenants"));
const AdministratorGlobalSkillsPage = lazy(() => import("../../../components/administrator/global-skills/global-skills"));
const AdministratorTenantSkillsPage = lazy(() => import("../../administrator/skills/skills"));
const AdministratorIndustriesPage = lazy(() => import("../../../components/administrator/industries/industries"));
const AdministratorVideoLibraryPage = lazy(() => import("../../../components/administrator/video-library/video-library"));
const AdministratorScreeningQuestionsLibraryPage = lazy(() => import("../../administrator/screening-questions/screening-questions"));
const AdministratorGlobalBrandsPage = lazy(() => import("../../administrator/global-brands/global-brands"));
const AdministratorManageTagCategoriesPage = lazy(() => import("../../../components/administrator/manage-tag-categories/manage-tag-categories"));
const AdministratorIntegrationErrors = lazy(() => import("../../administrator/integrations-error-logs/integration-error-logs"));
const AdministratorTenantCandidateFeedBackPage = lazy(() => import("../../administrator/local-candidate-feedback/local-candidate-feedback"));
const AdministratorGlobalCandidateFeedBackPage = lazy(() => import("../../../components/administrator/global-candidate-feedback/global-candidate-feedback"));
const DevelopmentGuidelines = lazy(() => import("../../../components/development-guideliness/development-guidelines"));
const LeadsManagement = lazy(() => import("../../../components/leads-management/leads-management"));
const Leads = lazy(() => import("../../../components/leads-management/leads/leads"));
const LeadsRegistration = lazy(() => import("../../../components/leads-registration/leads-registration"));
const ManageNotificationTemplates = lazy(() => import("../../administrator/manage-notification-templates/manage-notification-templates"));
const Queries = lazy(() => import("../../../components/queries/queries"));
const Dashboard = lazy(() => import("../../../components/dashboard/dashboard"));
const LeadsUnsubscribe = lazy(() => import("../../../components/leads-unsubscribe/leads-unsubscribe"));
const AdditionalDocuments = lazy(() => import("../../administrator/additional-documents/additional-documents"));
const CandidateImportFailures = lazy(() => import("../../administrator/candidates-import-failures/candidates-import-failures"));
const AdministratorOperationalRecruitersReportsGlobalPage = lazy(() => import("../../../components/administrator/operational-recruiters-reports/operational-recruiters-reports-global"));
const CammioReport = lazy(() => import("../../administrator/cammio-report/cammio-report"));
const AdministrationGlobalReportsTenantStats = lazy(() => import("../../../components/administrator/global-report-tenant-stats/global-reports-tenant-stats"));
const SMSTestingTool = lazy(() => import("../../administrator/sms-testing-tool/sms-testing-tool"));
const CacheManager = lazy(() => import("../../administrator/cache-manager/cache-manager"));
const ReleaseNotesListing = lazy(() => import("../../administrator/manage-versions/components/listing/release-notes-listing"));
const ReleaseNote = lazy(() => import("../../administrator/manage-versions/release-note"));
const ReleaseNotesRedirectPage = lazy(() => import("../../release-notes/release-notes"));
const EventsManager = lazy(() => import("../../administrator/events-manager/events-manager"));
const JDBusinessUnitManager = lazy(() => import("../../administrator/business-unit-manager/business-unit-manager"));
const CandidateProcessDetailsLanding = lazy(() => import("../../external-landings/candidate-process-details-landing/candidate-process-details-landing"));
const DeveloperPOCs = lazy(() => import("../../administrator/developer-pocs/developer-pocs"));
const TenantScripts = lazy(() => import("../../administrator/tenant-scripts/tenant-scripts"));
const PDFRenderPage = lazy(() => import("../../public/pdf/pdf-render-page"));
const SharingClientPage = lazy(() => import("../../public/client/sharing"));
const ShortlistClientPage = lazy(() => import("../../public/client/shortlist"));
const ReferencePage = lazy(() => import("../../public/reference/reference"));

// External landings
const ProcessCreationLandingPage = lazy(() => import("../../../components/external-landings/process-creation-landing/process-creation-landing"));
const CandidateDetailsLandingPage = lazy(() => import("../../../components/external-landings/candidate-details-landing/candidate-details-landing"));
const ExternalLandingCandidatePage = lazy(() => import("../../../components/external-landings/external-landing-candidate/external-landing-candidate"));
const ExternalLandingPersonServicePage = lazy(() => import("../../external-landings/external-landing-person-service/external-landing-person-service"));
const LandingCandidateQuickApplyPage = lazy(() => import("../../../components/external-landings/candidate-quick-apply-landing/candidate-quick-apply-landing"));
const ProcessDetailsLandingPage = lazy(() => import("../../../components/external-landings/process-details-landing/process-details-landing"));
const ProcessTemplatesLandingPage = lazy(() => import("../../../components/external-landings/process-templates-landing/process-templates-landing"));
const PersonDetailsLandingPage = lazy(() => import("../../../components/external-landings/person-details-landing/person-details-landing"));
const CandidateAttachmentLandingPage = lazy(() => import("../../../components/external-landings/candidate-attachment-landing/candidate-attachment-landing"));
const NewRequisitionLandingPage = lazy(() => import("../../../components/external-landings/new-requisition-landing/new-requisition-landing"));
const LandingPeronsVideoSharingPage = lazy(() => import("../../../components/external-landings/person-video-sharing-landing/person-video-sharing-landing"));
const CandidatePublicLanding = lazy(() => import("../../../components/external-landings/candidate-public-landing/candidate-public-landing"));
const CammioLandingPage = lazy(() => import("../../../components/external-landings/cammio-landing/cammio-landing"));
const JobDescriptionExternalLanding = lazy(() => import("../../../routes/external-landings/job-description-external-landing/job-description-external-landing"));
const JobDescriptionListingExternalLanding = lazy(() => import("../../../routes/external-landings/job-description-listing-external-landing/job-description-listing-external-landing"));
const JobDescriptionListingExternalLandingUser = lazy(() => import("../../../routes/external-landings/job-description-listing-external-landing/job-description-listing-external-landing-user"));
const JobPostingListingExternalLanding = lazy(() => import("../../../routes/external-landings/jop-posting-external-landing/job-posting-listing-external-landing"));
const ProcessesLanding = lazy(() => import("../../../components/external-landings/processes-landing/processes-landing"));
const SMSRedirectLanding = lazy(() => import("../../../components/external-landings/sms-redirecet-landing/sms-redirect-landing"));

const routesObject: any = {
  NOT_FOUND: NotFoundPage,
  UI_LIBRARY: UiLibraryPage,
  DEVELOPMENT_GUIDELINE: DevelopmentGuidelines,
  JOB_DESCRIPTION: JobDescription,
  JOB_DESCRIPTION_GENERATOR: JobDescriptionGenerator,
  JOB_DESCRIPTION_PDF_GENERATOR: PDFJobDescriptionPage,
  JOB_DESCRIPTION_BOARDS: JobDescriptionsBoardsPage,
  JOB_DESCRIPTION_DOWNLOAD_REPORT: JobDescriptionsReportPage,
  JOB_LISTING: JobListingPage,
  JOB_POSTING_LISTING: JobPostingPage,
  LEADS_MANAGEMENT: LeadsManagement,
  LEADS_DETAILS: Leads,
  QUERIES: Queries,
  DASHBOARD: Dashboard,
  LEADS_REGISTRATION: LeadsRegistration,
  LEADS_UNSUBSCRIBE: LeadsUnsubscribe,
  RECRUITMENT: HomePage,
  LOGIN_TOKEN: LoginToken,
  LOGIN: LoginPage,
  CREATE_PROCESS: CreatorIntroPage,
  CREATE_SERVICE: ServiceCreationPage,
  CREATE_SERVICE_TEMPLATE: ServiceCreationPage,
  EDIT_SERVICE: ServiceCreationPage,
  NEW_EDIT_SERVICE: ServiceCreation,
  DETAILS_PROCESS: DetailsPage,
  EDIT_PROCESS: CreatorPage,
  SERVICE_DETAIL: ServiceDetailPage,
  SERVICE_ASSESSMENT_DETAIL: ServiceAssessmentDetailPage,
  SHORTLIST_PAGE: ShortlistPage,
  DETAILS_CANDIDATE: CandidatePage,
  CANDIDATE_WEB: CandidateWebPage,
  SERVICE_ASSESSMENT: ServiceAssessmentPage,
  SHORTLIST_PUBLIC: ShortlistPublicPage,
  QUICK_APPLY_VERIFICATION_WEB_PUBLIC: QuickApplyVerificationPublicPage,
  QUICK_APPLY_WEB_PUBLIC: QuickApplyPublicPage,
  CANDIDATE_WEB_PUBLIC: CandidatePublicPage,
  CANDIDATE_WEB_REDIRECT: CandidateRedirectPage,
  TERMS: TermsRedirectPage,
  POLICY: TermsRedirectPage,
  MARKETING: TermsRedirectPage,
  ADMINISTRATOR_HOME: AdminSettings,
  ADMINISTRATOR_USERS: AdministratorUsersPage,
  ADMINISTRATOR_TEMPLATES: AdministratorProcessTemplatesPage,
  ADMINISTRATOR_TENANTS: AdministratorTenantsPage,
  ADMINISTRATOR_CATEGORIES: AdministratorTenantCategoriesPage,
  ADMINISTRATOR_VARIABLES: AdministratorTenantVariablesPage,
  ADMINISTRATOR_OPERATIONAL_PROCESS_REPORTS: AdministratorOperationalProcessesReportsPage,
  ADMINISTRATOR_OPERATIONAL_RECRUITERS_REPORTS: AdministratorOperationalRecruitersReportsPage,
  ADMINISTRATOR_OFFER_TEMPLATES: AdministratorOfferTemplatesPage,
  ADMINISTRATOR_MANAGE_TAG_CATEGORIES: AdministratorManageTagCategoriesPage,
  ADMINISTRATOR_BUDGETS: ManageBudgets,
  ADMINISTRATOR_USER_FEEDBACK_REPORTS: OperationalFeedbackReports,
  ADMINISTRATOR_GLOBAL_USER_FEEDBACK_REPORTS: OperationalFeedbackReports,
  ADMINISTRATOR_LANGUAGES: AdministratorTenantLanguagesPage,
  ADMINISTRATOR_SERVICES: FeaturesConfiguration,
  ADMINISTRATOR_TERMS: AdministratorTermsPage,
  ADMINISTRATOR_COOKIE_BANNER: CookieBannerPage,
  ADMINISTRATOR_STATISTICS: AdministratorStatsPage,
  ADMINISTRATOR_ACTIVITY: AdministratorActivityLogsPage,
  ADMINISTRATOR_INDUSTRIES: AdministratorIndustriesPage,
  HEALTHY: HealthyPage,
  ADMINISTRATOR_GLOBAL_CATEGORIES: AdministratorGlobalCategoriesPage,
  ADMINISTRATOR_MAILJET: AdministratorMailsPage,
  ADMINISTRATOR_SKILLS: AdministratorGlobalSkillsPage,
  ADMINISTRATOR_LOCAL_SKILLS: AdministratorTenantSkillsPage,
  ADMINISTRATOR_GLOBAL_JOB_POSTING_REPORT: JobPostingReports,
  ADMINISTRATOR_TENANT_JOB_POSTING_REPORT: JobPostingReports,
  ADMINISTRATOR_TENANT_CONVERSION_RATES_REPORT: ConversionRatesReport,
  RECRUITER_PROFILE: RecruiterProfilePage,
  LANDING_INTERVIEW: LandingInterviewRedirectPage,
  LANDING_SMS_REDIRECT: SMSRedirectLanding,
  ADMINISTRATOR_CLIENTS: AdministratorClientsPage,
  ADMINISTRATOR_BRANDS: AdministratorGlobalBrandsPage,
  ADMINISTRATOR_COBRANDS: AdministratorGlobalCobrandsPage,
  ADMINISTRATOR_LOCAL_CLIENTS: AdministratorLocalClientsPage,
  SHARING_CANDIDATE_WEB_PUBLIC: ShareCandidateWebPublicPage,
  ADMINISTRATOR_LOCAL_CLIENTS_STATS: AdministratorClientsStatsPage,
  LIVE_INTERVIEW: LiveInterviewPage,
  ADMINISTRATOR_LOCAL_RECRUITER_STATS: AdministratorRecruiterStatsPage,
  PROCESS_FUNNELING: ProcessFunnelingPage,
  EXTRA_LIVE_REDIRECT: ExtraLiveRedirectPage,
  ADMINISTRATOR_TAGS: AdministratorGlobalTagsPage,
  ADMINISTRATOR_MANAGE_TENANT: AdministratorManageTenantPage,
  ADMINISTRATOR_LOCAL_CANDIDATE_FEEDBACK: AdministratorTenantCandidateFeedBackPage,
  ADMINISTRATOR_GLOBAL_CANDIDATE_FEEDBACK: AdministratorGlobalCandidateFeedBackPage,
  ADMINISTRATOR_XPERT_CATALOG: AdministratorXpertCatalogPage,
  CANDIDATE_WEB_CONFIRMATION: CandidatePublicConfirmationPage,
  ADMINISTRATOR_GLOBAL_COMPETENCY_FRAMEWORK_SKILL: AdministratorGlobalCompetencyFrameworkSkillPage,
  ADMINISTRATOR_SCREENING_QUESTIONS_LIBRARY: AdministratorScreeningQuestionsLibraryPage,
  ADMINISTRATOR_REFERENCE_CHECK_LIBRARY: AdministratorReferenceCheckLibraryPage,
  ADMINISTRATOR_POSITION: AdministratorPositionPage,
  ADMINISTRATOR_CONTRACT_TYPE: AdministratorContractTypePage,
  ADMINISTRATOR_SCREENING_QUESTION_TYPES: AdministratorScreeningQuestionTypesPage,
  ADMINISTRATOR_VIDEO_TYPES: AdministratorVideoTypesPage,
  ADMINISTRATOR_VIDEO_LIBRARY: AdministratorVideoLibraryPage,
  ADMINISTRATOR_LOCAL_QUESTIONS: AdministratorVideoQuestionsLibraryPage,
  ADMINISTRATOR_TENANT_TAGS: AdministratorTenantTagsPage,
  ADMINISTRATOR_TENANT_SCREENING_ATTRIBUTES: AdministratorTenantScreeningAttributesPage,
  ADMINISTRATOR_SCREENING_ATTRIBUTES: AdministratorGlobalScreeningAttributesPage,
  ADMINISTRATOR_CALENDAR: AdministratorCalendarsPage,
  ADMINISTRATOR_HOLIDAY_CALENDAR: AdministratorNonAvailabilityCalendarsPage,
  ADMINISTRATOR_TENANT_EMAILS: TenantEmails,
  ADMINISTRATOR_PERSONS: AdministratorGlobalPersonsPage,
  CALENDARS_PAGE: UserCalendarsPage,
  VIDEO_LIBRARY_PAGE: UserVideoLibraryPage,
  CANDIDATE_WEB_UNSUBSCRIBE: CandidatePublicUnsubscribePage,
  PROCESS_CREATION_LANDING: ProcessCreationLandingPage,
  CANDIDATE_QUICK_APPLY: LandingCandidateQuickApplyPage,
  PROCESS_DETAILS_LANDING: ProcessDetailsLandingPage,
  PROCESS_TEMPLATES_LANDING: ProcessTemplatesLandingPage,
  PERSON_DETAILS_LANDING: PersonDetailsLandingPage,
  CANDIDATE_ATTACHMENT_LANDING: CandidateAttachmentLandingPage,
  CANDIDATE_DETAILS_LANDING: CandidateDetailsLandingPage,
  EXTERNAL_LANDING_CANDIDATE_DETAILS: ExternalLandingCandidatePage,
  EXTERNAL_LANDING_PERSON_SERVICE_DETAILS: ExternalLandingPersonServicePage,
  CANDIDATE_PUBLIC_LANDING: CandidatePublicLanding,
  CAMMIO_LANDING: CammioLandingPage,
  PROCESSES_LANDING: ProcessesLanding,
  JOB_DETAILS: HomeJobsDetailsPage,
  JOB_DETAILS_PROCESS: DetailsPage,
  JOB_DETAILS_PROCESS_CANDIDATE: CandidatePage,
  CANDIDATE_DETAILS_PROCESS: CandidatePage,
  CANDIDATE_DETAILS: HomeCandidatesDetailsPage,
  JOB_EDIT: HomeJobsFormPage,
  JOB_NEW: HomeJobsFormPage,
  NEW_REQUISITION_LANDING: NewRequisitionLandingPage,
  RECRUITER_PORTAL: RecruiterPortalPage,
  JOB_DESCRIPTION_TEMPLATES: JobDescriptionTemplatesListing,
  JOB_DESCRIPTION_TEMPLATE: JobDescriptionTemplateUI,
  JOB_DESCRIPTION_LOCAL_TEMPLATE: JobDescriptionTemplateUI,
  JOBDESCRIPTION_CREATION_LANDING: JobDescriptionExternalLanding,
  JOBDESCRIPTION_LISTING_EXTERNAL_LANDING: JobDescriptionListingExternalLanding,
  JOBDESCRIPTION_LISTING_EXTERNAL_LANDING_USER: JobDescriptionListingExternalLandingUser,
  JOBPOSTING_LISTING_EXTERNAL_LANDING: JobPostingListingExternalLanding,
  REFERENCE_CHECK: ReferenceCheckPublicPage,
  ADMINISTRATOR_MANAGE_EXTERNAL_SYSTEMS: AdministratorGlobalExternalSystems,
  ADMINISTRATOR_MANAGE_OPERATIONS: AdministratorGlobalOperationsComponent,
  ADMINISTRATOR_MANAGE_TENANT_SYSTEM_OPERATION: AdministratorExternalSystemOperationTenant,
  ADMINISTRATOR_MANAGE_LINKS: AdministratorLinks,
  ADMINISTRATOR_MANAGE_INTEGRATION_ERRORS: AdministratorIntegrationErrors,
  ADMINISTRATOR_MYA_EVENTS: AdministratorMyaEvents,
  ADMINISTRATOR_GLOBAL_PROACTIVE_SOURCING: AdministratorProactiveSourcing,
  ADMINISTRATOR_UPLOAD_JD_MANUALLY: AdministratorUploadJDManually,
  PROACTIVE_SOURCING: ProactiveSourcing,
  PERSON_VIDEOS_SHARING: PersonVideoSharingPage,
  PERSON_VIDEOS_SHARING_LANDING: LandingPeronsVideoSharingPage,
  ADMINISTRATOR_NOTIFICATION_TEMPLATES: ManageNotificationTemplates,
  ADMINISTRATOR_SMS_CANDIDATES_STATUS: ManageSmsCandidateStatus,
  RECRUITERS_MODULES: RecruitersModulesPage,
  ADMINISTRATOR_OPERATIONAL_RECRUITERS_REPORTS_GLOBAL: AdministratorOperationalRecruitersReportsGlobalPage,
  ADMINISTRATOR_CAMMIO_REPORT_TENANT: cammioReportTenant,
  ADMINISTRATOR_CAMMIO_REPORT: CammioReport,
  ADMINISTRATOR_GLOBAL_REPORTS_TENANT_STATS: AdministrationGlobalReportsTenantStats,
  ADMINISTRATOR_CLIENT_BRAND: ClientBrand,
  ADMINISTRATOR_MANAGE_LITERALS: AdministratorManageLiterals,
  CANDIDATE_PROCESS_DETAILS: CandidateProcessDetailsLanding,
  JOB_DESCRIPTION_SECTIONS_CATEGORIES: JobDescriptionsSectionsCategoriesPage,
  CALENDARS_DETAILS_PAGE: ComplexCalendar,
  ADMINISTRATOR_CALENDAR_DETAILS: ComplexCalendar,
  USER_PROFILE: UserProfile,
  ADMINISTRATOR_SMS_TESTING_TOOL: SMSTestingTool,
  ADMINISTRATOR_CACHE_MANAGER: CacheManager,
  ADMINISTRATOR_RELEASE_NOTES: ReleaseNotesListing,
  RELEASE_NOTE: ReleaseNote,
  RELEASE_NOTES: ReleaseNotesRedirectPage,
  ADMINISTRATOR_EVENT_MANAGER: EventsManager,
  ADMINISTRATOR_JD_BU_TENANT_MANAGER: JDBusinessUnitManager,
  ADMINISTRATOR_ADDITIONAL_DOCUMENTS: AdditionalDocuments,
  ADMINISTRATOR_DEVELOPER_POCS: DeveloperPOCs,
  ADMINISTRATOR_TENANT_SCRIPTS: TenantScripts,
  ADMINISTRATOR_CANDIDATE_IMPORT_FAILURES: CandidateImportFailures,
  PDF_RENDER_PAGE: PDFRenderPage,
  default: null,
};

export const getComponentFromRouteId = (routeId: string) => {
  // Check new design process creation component
  if (getFeatureConfigurationIsEnabled("Process Creation", "process-creation-new-design") && routeId === "CREATE_PROCESS") return ProcessCreationIntro;
  else if (getFeatureConfigurationIsEnabled("Process Creation", "process-creation-new-design") && routeId === "EDIT_PROCESS") return ProcessCreationPhases;
  // Check new design recruitment home component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "recruitment-home-new-design") && routeId === "RECRUITMENT") return RecruitmentHome;
  // Check new design job details page
  if (getFeatureConfigurationIsEnabled("New Design Settings", "recruitment-home-new-design") && routeId === "JOB_DETAILS") return jobDetails;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "recruitment-home-new-design") && routeId === "JOB_NEW") return JobCreation;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "recruitment-home-new-design") && routeId === "JOB_EDIT") return JobCreation;
  else if (getFeatureConfigurationIsEnabled("New Design Settings", "services-home-new-design") && routeId === "SERVICE_DETAIL") return ServiceDetailNewPage;
  // Check new design process details component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "process-details-new-design") && routeId === "DETAILS_PROCESS") return ProcessDetails;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "process-details-new-design") && routeId === "JOB_DETAILS_PROCESS") return ProcessDetails;
  // Check new design candidate details component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "candidate-details-new-design") && routeId === "DETAILS_CANDIDATE") return CandidatesDetails;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "candidate-details-new-design") && routeId === "JOB_DETAILS_PROCESS_CANDIDATE") return CandidatesDetails;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "candidate-details-new-design") && routeId === "CANDIDATE_DETAILS_PROCESS") return CandidatesDetails;
  // Check new design service assessment details component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "service-assessment-details-new-design") && routeId === "SERVICE_ASSESSMENT_DETAIL") return Assessments;
  // Check new design person details component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "person-details-new-ui") && routeId === "CANDIDATE_DETAILS") return PersonDetailsPage;
  // Check new design shortlist component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "shortlist-new-ui") && routeId === "SHORTLIST_PAGE") return ShortlistUIPage;
  // Check new design profile details page
  if (getFeatureConfigurationIsEnabled("New Design Settings", "user-profile-new-ui") && routeId === "RECRUITER_PROFILE") return UserProfile;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "video-library-new-ui") && routeId === "VIDEO_LIBRARY_PAGE") return VideoLibraryPage;
  // Check new design service creation component
  if (getFeatureConfigurationIsEnabled("New Design Settings", "service-creation-new-design") && (routeId === "CREATE_SERVICE" || routeId === "CREATE_SERVICE_TEMPLATE")) return ServiceCreationIntro;
  // Check new design calendars
  if (getFeatureConfigurationIsEnabled("New Design Settings", "calendars-new-ui") && (routeId === "CALENDARS_PAGE" || routeId === "ADMINISTRATOR_CALENDAR")) return Calendars;
  //Check new design sharing pages
  if (getFeatureConfigurationIsEnabled("New Design Settings", "client-sharing-new-ui") && routeId === "SHARING_CANDIDATE_WEB_PUBLIC") return SharingClientPage;
  if (getFeatureConfigurationIsEnabled("New Design Settings", "shortlist-evaluation-new-ui") && routeId === "SHORTLIST_PUBLIC") return ShortlistClientPage;
  // Check new design reference
  if (getFeatureConfigurationIsEnabled("New Design Settings", "reference-new-ui") && routeId === "REFERENCE_CHECK") return ReferencePage;
  // Default return
  return routesObject[routeId] || "NotFoundPage";
};
