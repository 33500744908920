import React, { Component } from "react";
export interface IProps {
  [others: string]: any;
}
export default class Selections extends Component<IProps> {
  onRemoveItem = (item: any) => {
    this.props.onRemoveItem(item);
  };
  renderOptions() {
    if (this.props.value && this.props.value.length > 0) {
      let options: any[] = [];
      let currentValue = this.props.value && typeof this.props.value !== "object" ? [this.props.value] : this.props.value;

      if (currentValue && typeof currentValue === "object") {
        currentValue?.forEach((value: any, index: number) => {
          const item = this.props.items?.find((valueItem: any) => value === valueItem.props?.value);
          if (item) {
            const image = item.props?.others?.image;
            let element = (
              <button
                type="button"
                key={index}
                className="chip_active mb1 mr1"
                disabled={this.props.disabled}
                onClick={() => {
                  this.onRemoveItem(item.props?.value);
                }}
                id={`selected-${item.props?.value}`}
              >
                {item.props?.label}
                {image ? <img src={image?.src} className={image?.className} alt=" " /> : ""} <span className="material-icons right">close</span>
              </button>
            );

            if ((currentValue.length && typeof currentValue === "object" && currentValue.indexOf(item.props?.value) !== -1) || currentValue === item.props?.value) {
              options.push(element);
            }
          }
        });
      }

      return options;
    }
    return null;
  }

  render() {
    return (
      <>
        <div className="select-bottom-container multiple-element">{this.renderOptions()}</div>
      </>
    );
  }
}
