import { getValueDate, parseToDate } from "../../../../helper/form-ui";

export const getMilisecondsWithAdditionalDays = (valueField: string, additionalDays: number): number | null => {
  let result = getValueDate(valueField, true);
  if (result && !isNaN(additionalDays)) {
    // Add days in miliseconds
    const additionalDaysMiliseconds = additionalDays * 24 * 3600 * 1000;
    result = parseInt(result) + additionalDaysMiliseconds;
  }
  return result;
};

export const setDateFromMiliseconds = (values: any, nameField: string, valueField: number) => {
  let field: any;
  const accessNames = nameField.split(".");
  accessNames.forEach((accessName, index) => {
    if (index === 0) {
      field = values[accessName];
    } else if (index === accessNames.length - 1) {
      field.endDate = parseToDate(valueField);
    } else {
      if (/\[\d+\]/.test(accessName)) {
        const [accessField, indexField] = accessName.split("[");
        field = field[accessField];
        field = field[indexField.replace("]", "")];
      } else {
        field = field[accessName];
      }
    }
  });
  return values;
};

export const equals = (date1: string | string[], date2: string | string[]) => {
  let _equals = false;
  if (date1 === date2) {
    _equals = true;
  } else if (date1 && typeof date1 === "object" && date1.constructor === Array && date2 && typeof date2 === "object" && date2.constructor === Array) {
    _equals = date1.every((date) => date2.includes(date)) && date2.every((date) => date1.includes(date));
  }
  return _equals;
};
