import React, { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";

import { toLiteral } from "../../../../../helper/locale-utils";
import { addNotification } from "../../../../shared/notifications/notifications-service";

import { ContextAction, resetContextParams, updateContextParams } from "../../../../../redux/slices/copilotSlice";
import { addLoadingProgress, removeLoadingProgress } from "../../../../shared/loading-progress/loading-progress";
import { CopilotActionSteps, CopilotActionType } from "../copilot-actions";

import MultiSelectAnswer from "./multi-select-answer";
import SimpleOptionAnswer from "./simple-option-answer";
import TextInputAnswer from "./text-input-answer";
import { handleSkipStep, replaceContextVariables } from "../helpers/copilot-helper";

interface CopilotActionProps {
  key: string;
  setDialogOpen: Function;
  contextAction: ContextAction;
  setSelectedAction: Function
  userContextParamns: any
  selectedAction?: ContextAction;
}

/**
 * Component that renders the recruiter copilot
 */
const CopilotAction: React.FC<CopilotActionProps> = (props: CopilotActionProps): ReactElement => {
  const { setDialogOpen, contextAction, userContextParamns, key, setSelectedAction, selectedAction } = props;

  const { apiCall, id, callbackFunction, label, contextParams, loadingText, dataForCallback } = contextAction;

  const dispatch = useDispatch()

  const [dialogStep, setDialogStep] = useState<CopilotActionSteps>();

  async function triggerAction(data?: any, nextStep?: string) {
    if (contextAction?.steps) {
      // Determinate next step on the copilot actions
      let actionStep: CopilotActionSteps | undefined
      let _actionContextParams
      // If we are selecting for the first time the action
      if (!dialogStep) {
        // Select first step in the action
        actionStep = contextAction?.steps?.[0];
        // Check if we sould skip the selected step
        actionStep = handleSkipStep(contextAction, userContextParamns, actionStep)
        setSelectedAction(contextAction);
        _actionContextParams = contextParams
        dispatch(updateContextParams(_actionContextParams))
      } else { // Else, manage current step
        // Set setp input data to the context
        const _actionContext = { [dialogStep?.id]: data }
        _actionContextParams = _actionContext
        // Update user context with the new data
        dispatch(updateContextParams(_actionContextParams))
        // Pick next step
        actionStep = contextAction?.steps?.find((step) => step?.id === nextStep)
        // Check if we sould skip the selected step
        actionStep = handleSkipStep(contextAction, userContextParamns, actionStep)
      }
      // If there is no next step, then, trigger API call
      if (!actionStep) triggerActionApiCall(_actionContextParams)
      else setDialogStep(actionStep); // Else, set next step
    } else triggerActionApiCall(contextParams)
  }

  async function triggerActionApiCall(_actionContextParams?: any) {
    setDialogOpen(false);
    if (!apiCall || !id) return;
    try {
      addLoadingProgress(id, toLiteral({ id: loadingText }), 15);
      const apiParams = { ...userContextParamns, ..._actionContextParams }
      const response = await apiCall(apiParams);
      if (callbackFunction) await callbackFunction(response, dataForCallback);
    } catch (e) {
      addNotification({ type: "error", content: toLiteral({ id: "AI Error - Please try again or contact IT Support." }), timer: 3 });
      console.error("AI Generation", e)
    }
    removeLoadingProgress(id);
    setSelectedAction(undefined);
  }

  function resetCopilotAction() {
    setDialogStep(undefined)
    setSelectedAction(undefined);
    dispatch(resetContextParams())
  }

  const renderByStepType = (stepType: CopilotActionType) => {
    const commonProps = { dialogStep, triggerAction }
    switch (stepType) {
      case CopilotActionType.simpleOptions:
        return <SimpleOptionAnswer {...commonProps} />
      case CopilotActionType.input:
        return <TextInputAnswer {...commonProps} />
      case CopilotActionType.multiSelect:
        return <MultiSelectAnswer {...commonProps} />
      default:
        return toLiteral({ id: "Sorry, something went wrong" })
    }
  }

  const renderAction = (_dialogStep?: CopilotActionSteps, _selectedAction?: ContextAction) => {
    if (!_dialogStep && !_selectedAction) {
      return (
        <div className="flex">
          <button className="chip mla" key={key} onClick={() => triggerAction()}>
            {toLiteral({ id: label })}
          </button>
        </div>
      )
    } else if (_dialogStep && _selectedAction?.id === contextAction?.id) {
      return (
        <React.Fragment>
          <h5 className="mb3">{replaceContextVariables(toLiteral({ id: _dialogStep?.label }), userContextParamns)}</h5>
          <div className="flex">
            <div className="mla">
              {renderByStepType(_dialogStep?.type)}
            </div>
          </div>
          {!_dialogStep?.hideStartOver && <button type="button" className="link mt2" onClick={() => resetCopilotAction()}>{toLiteral({ id: "ai.start.over" })}</button>}
        </React.Fragment>
      )
    }
  }

  return (
    <div key={key} className="mt1">
      {renderAction(dialogStep, selectedAction)}
    </div>
  );
};

export default CopilotAction;
