import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { COPILOT_ACTION, CopilotAction, CopilotActionID } from "../../components/master/components/recruiter-copilot/copilot-actions";
import { getFeatureConfigurationIsEnabled } from "../../helper/feature-configuration-helper";

export interface ContextAction extends CopilotAction {
  callbackFunction: Function;
  contextParams?: any;
  dataForCallback?: any;
}

export interface CopilotState {
  contextActions: ContextAction[];
  userContextParamns: any;
}

const initialState: CopilotState = {
  contextActions: [],
  userContextParamns: {},
};

export const copilotSlice = createSlice({
  name: "copilot",
  initialState: initialState,
  reducers: {
    initContextActions: (state) => {
      state.contextActions = [];
      state.userContextParamns = {};
    },
    pushContextActions: (state, action) => {
      const { contextActions, id, dataForCallback } = action.payload;
      const existingActionIndex = state.contextActions?.findIndex((contextAction) => contextAction?.id === id);
      // If action dosent exist yet, push it
      if (existingActionIndex === -1) {
        let _contextActions = state.contextActions;
        _contextActions?.push({ ...contextActions?.[0], dataForCallback });
        state.contextActions = _contextActions;
      } else {
        // Else, replace for the existing one just in case the params or context is different
        let _contextActions = state.contextActions?.map((ca) => ca);
        _contextActions?.splice(existingActionIndex, 1, { ...contextActions?.[0], dataForCallback });
        state.contextActions = _contextActions;
      }
    },
    eliminateContextActions: (state, action) => {
      const { actionId } = action.payload;
      const contextActions = state.contextActions?.filter((contextAction) => contextAction?.id !== actionId);
      state.contextActions = contextActions;
    },
    updateUserContextParams: (state, action) => {
      const { contextParams } = action.payload;
      state.userContextParamns = { ...state.userContextParamns, ...contextParams };
    },
    resetUserContextParams: (state) => {
      state.userContextParamns = {};
    },
  },
});

const { initContextActions, pushContextActions, eliminateContextActions, updateUserContextParams, resetUserContextParams } = copilotSlice.actions;

export const resetCopilotContextActions = (): AppThunk => (dispatch) => {
  dispatch(initContextActions());
};

export const addCopilotContextActions =
  (actionId: CopilotActionID, callbackFunction: Function, contextParams: any, dataForCallback?: any): AppThunk =>
  (dispatch) => {
    const _contextAction = COPILOT_ACTION?.find((copilotAction) => copilotAction?.id === actionId);
    if (_contextAction?.feature && !getFeatureConfigurationIsEnabled("Generative AI", _contextAction?.feature)) return;
    const _copilotAction = { ..._contextAction, callbackFunction, contextParams };
    dispatch(pushContextActions({ contextActions: [_copilotAction], id: actionId, dataForCallback }));
    setTimeout(() => document?.getElementById("copilot-bubble")?.classList?.add("shine-class"), 100);
    setTimeout(() => document?.getElementById("copilot-bubble")?.classList?.remove("shine-class"), 1600);
  };

export const removeCopilotContextActions =
  (actionId: CopilotActionID): AppThunk =>
  (dispatch) => {
    dispatch(eliminateContextActions({ actionId }));
  };

export const updateContextParams =
  (contextParams: any): AppThunk =>
  (dispatch) => {
    dispatch(updateUserContextParams({ contextParams }));
  };

export const resetContextParams = (): AppThunk => (dispatch) => {
  dispatch(resetUserContextParams());
};

export default copilotSlice.reducer;
