import { http } from "../../../../api";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import { SPELLING_SCORE } from "../../../../helper/inclusive-insights-helper";
import { ISpellingErrors, ISpellingResponseAxios, ISpellingResponseAxiosData, ISpellingResponseData } from "../../../../helper/inclusive-insights-helper-response";
import { addRequest, checkIsCancel, removeRequest, Types_Request } from "./abort-insights";
import { cleanHTMLBeforeSend, clearAllInsightsErrors } from "./errors-wsyiwyg";

export const removeTagInsightErrorRegex =
  /<span data-key="(\d*)" class="(.*?)spelling insight-error">(.*?)<span class="spelling-tooltip" onclick="(.*?)">(.*?)<span class="close-icon fa fa-times"><\/span><\/span><\/span>/gi;

export function generateRemoveTagInsightErrorRegexByIndex(index: string) {
  return new RegExp(
    `<span data-key="${index}" class="(.*?)spelling insight-error">(.*?)<span class="spelling-tooltip" onclick="(.*?)">(.*?)<span class="close-icon fa fa-times"></span></span></span>`,
    "gi"
  );
}

interface SpellingChunk {
  originalText: string;
  responseData?: ISpellingResponseAxiosData;
}

let cachedChunks: SpellingChunk[] = [];

// ----------------------------------------------- SPELLING FUNCTIONS -----------------------------------------------
//This function gets a chunk of HTML and find the spelling errors inside it
//clears the HTML first
//then calls the SPELING APÎ and receives a response with the list of errors
export async function getSpellingErrors(key: string, value: string, selectedLanguage: string): Promise<ISpellingResponseData> {
  let response = { errors: [], originalText: value, totalErrors: 0 } as ISpellingResponseData;
  let _value = clearAllInsightsErrors(value);
  _value = cleanHTMLBeforeSend(_value);

  let _selectedLanguage = selectedLanguage ? selectedLanguage : DEFAULT_LANGUAGE.toLowerCase();

  const chunks = _value.match(/.{1,64}(\s|$)/g);
  if (chunks && chunks.length) {
    let abort: boolean = false;
    let errors: ISpellingErrors[] = [];
    let totalErrors: number = 0;
    // Add request to array - return parameter that allow abort
    const cancelToken = addRequest(key, Types_Request.INSIGHTS);
    await Promise.all(
      chunks.map(async (stringChunk) => {
        // Check if chunk it is repeated
        const repeatedChunk: SpellingChunk = cachedChunks?.find((cachedChunk) => cachedChunk.originalText === stringChunk) || ({} as SpellingChunk);
        let spellingChunk: SpellingChunk = {
          originalText: repeatedChunk.originalText || stringChunk,
          responseData: repeatedChunk.responseData || undefined,
        };
        // If not call to the API
        if (!spellingChunk.responseData) {
          const responseAxios = (await http(false, {}, cancelToken)
            .put("/api/spelling?language=" + _selectedLanguage + "&mode=spell", {
              text: stringChunk,
            })
            .catch((err) => {
              if (checkIsCancel(err)) {
                abort = true;
              } else {
                console.error("Failed chunk request: ", err);
              }
            })) as ISpellingResponseAxios;
          // Cache chunk
          spellingChunk = {
            originalText: stringChunk,
            responseData: responseAxios ? responseAxios.data : { errors: [], totalErrors: 0 },
          };
          cachedChunks.push(spellingChunk);
        }
        // Add errors and total
        if (spellingChunk.responseData && spellingChunk.responseData.totalErrors > 0) {
          errors = [...errors, ...spellingChunk.responseData.errors];
          totalErrors = totalErrors + spellingChunk.responseData.totalErrors;
        }
      })
    );
    // Remove request to array
    removeRequest(key, Types_Request.INSIGHTS);
    // Add all chunks response to returned data
    response.abort = abort;
    response.errors = errors;
    response.totalErrors = totalErrors;
  }
  return response;
}

export function getClassErrorHTMLSpelling(indexError: number, index: number): string {
  let classErrorHTML = "";
  if (indexError === index) {
    classErrorHTML = "active spelling insight-error";
  } else {
    classErrorHTML = "spelling insight-error";
  }
  return classErrorHTML;
}

export function getErrorHTMLSpelling(classErrorHTML: string, errorToken: string, index: number, suggestion: string, suggestionEscape: string): string {
  return `<span data-key="${index}" class="${classErrorHTML}">${errorToken}<span class="spelling-tooltip" onclick="window.correctInsightError(this, '${suggestionEscape}', '${index}', '${SPELLING_SCORE}')">${suggestion}<span class="close-icon fa fa-times"></span></span></span>`;
}

export function clearSpellingErrors(text: string = ""): string {
  return text.replace(removeTagInsightErrorRegex, "$3");
}
