const localStorageTableKey = "rc_tableStorage";

export class TableConfigurationStorage {
  setTableConfigStorage(resultsId: string, tenantId: any, columns: any): void {
    let tableStorage: any = {};
    let table = `${resultsId}-tenantId-${tenantId}`;
    tableStorage[table] = [...columns];
    const data = localStorage.getItem(localStorageTableKey);
    if (data) {
      const parsedData = JSON.parse(data);
      let newData = { ...parsedData, ...tableStorage };
      localStorage.setItem(localStorageTableKey, JSON.stringify(newData));
    } else {
      localStorage.setItem(localStorageTableKey, JSON.stringify(tableStorage));
    }
  }

  getTableConfigStorage() {
    const data = localStorage.getItem(localStorageTableKey);
    if (!data) return null;
    return JSON.parse(data);
  }
}
