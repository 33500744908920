import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Api from "../../../../api";
import { NavigationState } from "../../../../redux/slices/navigationSlice";
import { CombinedState } from "../../../../redux/store/state";
import { Container } from "../../../../constants";
import { getFeatureConfigurationIsEnabled } from "../../../../helper/feature-configuration-helper";

const ScriptHandler: React.FC<any> = (props): ReactElement => {
  const navigationState: NavigationState = useSelector((state: CombinedState) => state?.navigation);

  const currentRoute = navigationState?.currentRoute;

  const tenantScriptsEnabled = getFeatureConfigurationIsEnabled("General Settings", "tenant-scripts");

  const [cleanupFunctions, setCleanupFunctions] = useState<any>([]);

  useEffect(() => {
    if (tenantScriptsEnabled) _fetchTenantScripts(currentRoute);
    else cleanupFunctions?.forEach((cleanup: Function) => cleanup());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantScriptsEnabled]);

  async function _fetchTenantScripts(_currentRoute: any) {
    try {
      const scripts = await Api.fetchTenantScripts();

      let _cleanupFunctions: any = [];

      // Iterate through the scripts and add them using the appropriate function
      scripts?.tenantScripts?.forEach((script: any) => {
        const isPublicValid = currentRoute?.public && script?.container === Container.public;
        const isInternalValid = !currentRoute?.public && script?.container === Container.internal;
        const isAllValid = script?.container === "all";
        if (isPublicValid || isInternalValid || isAllValid) {
          const cleanup = addScriptOrNoscript(script);
          _cleanupFunctions.push(cleanup);
        }
      });

      setCleanupFunctions(_cleanupFunctions);
    } catch (error) {
      console.error("Error fetching scripts");
    }
  }

  const addAttributes = (element: HTMLElement, attributes: Record<string, string>) => {
    // Check if attributes is not null
    if (!attributes) return;
    // Add attributes to the element based on the attributes object
    for (const attributeName in attributes) {
      element.setAttribute(attributeName, attributes?.[attributeName]);
    }
  };

  const handleNoscriptTags = (element: any, script: any) => {
    if (script?.tags && typeof script?.tags === "object") {
      Object.keys(script?.tags).forEach((tagKey) => {
        const tagObject = script?.tags?.[tagKey];
        if (tagObject?.type && tagObject?.attributes) {
          const tag = document.createElement(tagObject?.type);
          for (const attributeName in tagObject?.attributes) {
            tag?.setAttribute(attributeName, tagObject?.attributes?.[attributeName]);
          }
          element.appendChild(tag);
        }
      });
    }
  };

  const addScriptOrNoscript = (script: any) => {
    if (!script?.type) return () => {};

    const element = document.createElement(script?.type);
    addAttributes(element, script.attributes);

    switch (script?.type) {
      case "script":
        if (script?.innerHTML) element.innerHTML = `try { ${script?.innerHTML} } catch(e) { console.error("Internal script error: ${script?.title}", e) }`;
        break;
      case "noscript":
        handleNoscriptTags(element, script);
        break;
      default:
        break;
    }

    document.body.appendChild(element);
    return () => document.body.removeChild(element);
  };

  return <>{props.children}</>;
};

export default ScriptHandler;
