import { IProviderInfo } from "../../../../constants";

export function replaceContactInfo(text: string, data?: IProviderInfo): string {
  let _text = text;
  if (data) {
    const keys = Object.keys(data);
    for (const key of keys) {
      const propValue = (data as any)[key] || "";
      _text = _text.replaceAll(`{{${key.toUpperCase()}}}`, propValue);
    }
  }
  return _text;
}
export function getContactInfoData(contactData: any = {}, contactInfo: string = ""): IProviderInfo {
  return contactData[contactInfo] || null;
}

export function buildContactInfoData( values: { dataContactName: string; dataContactPhone: string; dataContactEmail: string }, contactInfo: string = "", contactData: any = {}): IProviderInfo {
  if (values.dataContactEmail && values.dataContactPhone && values.dataContactName){
    return contactData[contactInfo] ? { ...contactData[contactInfo], email: values?.dataContactEmail, phone: values.dataContactPhone, name: values.dataContactName } : null;
  }else{
    return contactData[contactInfo] || null;
  }
}

export function checkValidaty(form: any = {}) {
  let valid = true;
  const keys = Object.keys(form);
  for (const key of keys) {
    const value = form[key] || "";
    if (value.length > 255) valid = false;
  }
  return valid;
}
