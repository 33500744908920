import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TenantState } from "../../../../redux/slices/tenantSlice";
import { CombinedState } from "../../../../redux/store/state";
import { LiteralState, setLangAppLiterals } from "../../../../redux/slices/literalsSlice";

import { getAllLiteralsByLanguage } from "../../../../api";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import { regExpHelper } from "../../../../helper/regexp-helper";

import Loading from "../../loading/loading";

const LiteralHandler: React.FC<any> = (props): ReactElement => {
  const tenantsState: TenantState = useSelector((state: CombinedState) => state.tenant);
  const literalsState: LiteralState = useSelector((state: CombinedState) => state.literals);
  const selectedLanguage = tenantsState?.selectedLanguage;
  const languageToLoad = regExpHelper.isIsoCode(selectedLanguage) ? selectedLanguage : DEFAULT_LANGUAGE;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (languageToLoad && !literalsState?.literals?.[languageToLoad]) {
      setIsLoading(true);
      loadLangs([languageToLoad]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageToLoad]);

  async function loadLangs(langs: string[]) {
    const promises = langs.map((lang) => getAllLiteralsByLanguage(lang, undefined, true));
    const literals = await Promise.all(promises).catch((error) => {
      console.error("Literals loading error: ", error);
    });
    if (literals) {
      const literalsUnion = _.extend({}, ...literals);
      dispatch(setLangAppLiterals(literalsUnion, () => setIsLoading(false)));
    } else setIsLoading(false);
  }

  return <>{isLoading ? <Loading id="literals-handler" /> : props.children}</>;
};

export default LiteralHandler;
