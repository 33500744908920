import _ from "lodash";
import React, { Component } from "react";
import store from "../../../redux/store";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { serializeForm } from "../forms/utilities/serialize";
import { objectIsClearOrEmpty } from "../../../utilities/objects";
import { objectToQueryString, queryStringToObject } from "../../../utilities/url";
import { defaultFiltersResults, FiltersResults, ITEMS_PER_PAGE, ONE_DAY_MILISECONDS, ResultsI } from "../../../constants";
import { TableConfigurationStorage } from "../../../helper/table-configuration-storage";
import { Form, SelectInput } from "../forms";
import { LoadingMessage } from "../messages";
import Filters from "./filters/filters";
import GridView from "./viewHandler/gridView/grid-view";
import FiltersSummary from "./filters/filters-summary/filters-summary";
import TableConfiguration from "./table-configuration/table-configuration";
import { getTagDSTheme } from "../../../utilities/theme";
import { toLiteral } from "../../../helper/locale-utils";
import Paginator from "@adeccoux/tag-ds/paginator";

export const ResultsContext = React.createContext({});
const tableStorage = new TableConfigurationStorage();

interface ResultsProps extends RouteComponentProps {
  clientFiltering?: boolean; //if you want to do the filtering on the client side and not the backend
  defaultColumns: any[]; //the list of all the possible colums
  columns: any[]; //the list of collumns that the user has selected to show
  fetchResults: Function; //the function that retrieves the data that will be displayed in the results
  filterOnChange?: boolean; //if you want the results to refresh when any input inside it changes or only when submit is triggered
  updateColumnsConfiguration?: Function; //callback to save the columns configuration
  children?: any; //these are the components dispayed inside the result form but above the table/grid
  renderItems?: Function; //method to override how the result items are rendered
  renderHeader?: Function; //method to override how the header of the table is rendered
  disableURLFilters?: boolean; //disables the URL filters, when a filter changes to add it or not to the URL
  noFiltersNoFetch?: boolean; //if the filters are empty, avoid to fetch the data and leave the table empty
  filterResults?: Function; //function to override the filtering when it's client side
  forceRefresh?: number; //force the refresh of the table, repeating a fetch. Usefull if for instance you modified or removed the data
  refresh?: number; //it's just a number that allow us to refresh, use forceRefresh instead
  defaultFiltersResults?: FiltersResults; //list of default filters when results is mounted
  customClientSideSort?: Function; //to override the shorting if it's client side
  hideTableConfiguration?: boolean; //hides the dropdown that allows the user to change the columns and their order
  hideFiltersSummary?: boolean; //hides the tags that display the selected filters
  itemsPerPage?: number[]; //how many items are in each page (for rendering the pagination)
  disablePagination?: boolean; //removes the pagination
  showTopScroll?: boolean; //shows a scroll bar on the top
  tableId?: string; //adds an ID to the results table
  resultsId?: string; //adds an ID to the results component
  selectecTenantId?: number; //allow you to select a tenant ID, it's for saving in the local storage the latest filters
  disableFilters?: boolean; //NO IDEA!
  showNestedRow?: boolean; //NO IDEA
  handleClearFilters?: Function; //Custom handle after clear filters
  enableResize?: boolean; //allows the user to change the column width
  oldUI?: boolean;
  customSubmits?: any; //to add additional custom submits that use also the filters of the form
  showItemsPerPage?: boolean; //shows the items per page select
  nonClosableFilters?: string[];
}

interface ResultsState {
  results?: ResultsI;
  columns: any;
  filters: FiltersResults;
  cachedResults?: any;
  forceRefresh: number;
  refresh?: number;
  selectecTenantId?: number;
}

class Results extends Component<ResultsProps, ResultsState> {
  private _isValid = true;
  private _isMounted = false;
  private clientFiltering = this.props.clientFiltering;
  private filtersFormRef: React.RefObject<any> = React.createRef();
  private USER_MAX_RESULTS = store.getState()?.tenant?.userProperties?.maxResults;

  constructor(props: ResultsProps) {
    super(props);
    this.state = {
      results: undefined,
      columns: this.getInitialColumns(),
      filters: this.getInitialFilters(),
      cachedResults: null,
      forceRefresh: this.props.forceRefresh || 0,
      refresh: this.props.refresh,
      selectecTenantId: this.props?.selectecTenantId,
    };
    this.clientFiltering = this.props.clientFiltering;
    this.changeColumns = this.changeColumns.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkPageConfiguration("componentDidMount");
    if (this?.props?.resultsId !== "home-processes") this.fetchResultThenUpdate("componentDidMount");
    window.addEventListener("popstate", this.setStateBasedOnURL);
  }

  componentDidUpdate(prevProps: ResultsProps, prevState: ResultsState) {
    this.checkPageConfiguration("componentDidUpdate");
    if (
      this._isValid &&
      (JSON.stringify(prevProps.defaultColumns) !== JSON.stringify(this.props.defaultColumns) ||
        JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters) ||
        prevState.forceRefresh !== this.state.forceRefresh ||
        prevState.refresh !== this.state.refresh)
    ) {
      if (this.clientFiltering && (prevState.forceRefresh !== this.state.forceRefresh || prevState.refresh !== this.state.refresh) && this.state.cachedResults !== null) {
        this.setState({ cachedResults: null });
      }
      this.fetchResultThenUpdate("componentDidUpdate");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("popstate", (e) => {
      this.setStateBasedOnURL();
    });
  }

  checkDifferentColumns = (defaultColumns: any, _currentStorage: any) => {
    const updatedColumns: any = [];

    defaultColumns.forEach((column: any) => {
      const storageItem = _currentStorage.find((item: any) => item.key === column.columnKey);
      if (storageItem) {
        const updatedColumn = { ...column, displayed: storageItem.displayed };
        updatedColumns.push(updatedColumn);
      } else {
        updatedColumns.push(column);
      }
    });

    return updatedColumns;
  };

  getInitialColumns = () => {
    if (this?.props?.resultsId && this.props?.selectecTenantId) {
      const storage = tableStorage.getTableConfigStorage();
      if (storage && Object.keys(storage).length > 0) {
        const currentPage = `${this?.props?.resultsId}-tenantId-${this.props?.selectecTenantId}`;
        let foundStrName = Object.keys(storage).find((str: any) => str === currentPage);
        if (foundStrName) {
          const currentStorage = storage[`${this?.props?.resultsId}-tenantId-${this.props?.selectecTenantId}`];
          let updatedColumnsWithStorage = this.checkDifferentColumns(this.props.defaultColumns, currentStorage);
          return [...updatedColumnsWithStorage];
        } else {
          return [...this.props.columns];
        }
      } else {
        return [...this.props.columns];
      }
    } else {
      return [...this.props.columns];
    }
  };

  getDefaultFilters = () => {
    return this.props.defaultFiltersResults || defaultFiltersResults;
  };
  getStorageFilters(resultsId: string | undefined, tenantId: number | undefined) {
    const storageKey = `${resultsId}-tenantId-${tenantId?.toString()}`;
    if (resultsId && tenantId) {
      const storageFilter = localStorage.getItem(storageKey);
      if (storageFilter) return [JSON.parse(storageFilter), storageKey];
    }
    return [null, storageKey];
  }
  getInitialFilters() {
    let initialFilters = window.location.search ? queryStringToObject(window.location.search) : this.getDefaultFilters();
    if (!this.props.disableURLFilters || !window.location.search) {
      const [storageFilters, storageFilterKey] = this.getStorageFilters(this.props?.resultsId, this.props?.selectecTenantId);
      if (storageFilters) {
        if (new Date().getTime() > storageFilters?.expiry) {
          localStorage.removeItem(storageFilterKey);
        } else {
          initialFilters = storageFilters.filters;
        }
      }
      if (!this.props.disableURLFilters) objectToQueryString(initialFilters, this.props.history);
    }
    if (this.props?.selectecTenantId && this.props?.resultsId) this.setLocalStorageFilterWithExpiry(this.props.resultsId, initialFilters, this.props.selectecTenantId);
    return initialFilters;
  }

  checkPageConfiguration(_origin: string) {
    const storageFilters = this.getStorageFilters(this.props?.resultsId, this.props?.selectecTenantId)[0];
    if (this.props.forceRefresh && this.props.forceRefresh !== this.state.forceRefresh) {
      const newFilters = storageFilters?.filters || this.getDefaultFilters();
      this.setState({
        forceRefresh: this.props.forceRefresh,
        filters: newFilters,
        results: undefined,
        columns: this.props?.columns,
      });
      if (!this.props.disableURLFilters) {
        objectToQueryString(newFilters, this.props.history);
      }
    }
    if (this.props.refresh !== this.state.refresh) {
      const newFilters = storageFilters?.filters || this.state?.filters || this.getDefaultFilters();
      this.setState({
        refresh: this.props.refresh,
        filters: newFilters,
        results: undefined,
      });
      if (!this.props.disableURLFilters) {
        objectToQueryString(newFilters, this.props.history);
      }
    }
  }

  setLocalStorageFilterWithExpiry(id: any, filters: any, tenantId: number) {
    const item = {
      filters: filters,
      expiry: new Date().getTime() + ONE_DAY_MILISECONDS,
    };
    localStorage.setItem(`${id}-tenantId-${tenantId}`, JSON.stringify(item));
  }
  setStateBasedOnURL() {
    if (!this.props.disableURLFilters) {
      if (this._isMounted) {
        const filtersNS: FiltersResults | undefined = window.location.search
          ? queryStringToObject(window.location.search)
          : this.props.defaultFiltersResults
          ? this.props.defaultFiltersResults
          : defaultFiltersResults;
        if (this.state.filters !== filtersNS) {
          this.setState({ filters: filtersNS });
        }
      }
    }
    this.validateForm();
  }

  checkIfDataIsFetched() {
    return this.state.columns ? true : false;
  }
  fetchResultThenUpdate = async (_origin: string) => {
    if (this.state.results) {
      this.setState({ results: undefined });
    }
    await this.validateForm();
    if (!(this.props.noFiltersNoFetch && this._isValid && objectIsClearOrEmpty(this.state.filters))) {
      const maxPerPage = Math.max(...ITEMS_PER_PAGE.PROCESS_DETAILS, this.USER_MAX_RESULTS || 75);
      const filterPerPage = Math.min(maxPerPage, this.state.filters?.perPage || 10);
      const stateFilters = { ...this.state.filters, perPage: filterPerPage };
      if (!this.clientFiltering) {
        this.props
          .fetchResults(stateFilters)
          .then((response: ResultsI) => {
            if (this._isMounted) {
              // Check if is mounted
              const filtersAfterFetch = response?.count ? stateFilters : { ...stateFilters, page: 1 };
              if (!response?.count && stateFilters?.page !== 1) {
                this.setState({ filters: filtersAfterFetch }, () => {
                  this.fetchResultThenUpdate("fetchResultThenUpdate retry");
                });
              } else {
                this.setState({ results: response }, () => {
                  if (this.props?.resultsId && this.props?.selectecTenantId) {
                    this.setLocalStorageFilterWithExpiry(this.props.resultsId, filtersAfterFetch, this.props.selectecTenantId);
                    if (!this.props.disableURLFilters) {
                      objectToQueryString(filtersAfterFetch, this.props.history);
                    }
                  }
                });
              }
            }
          })
          .catch((error: any) => {
            if (this._isMounted) {
              console.error("Error fetching results:", error);
            }
          });
      } else {
        this.fetchAndCacheResults(stateFilters);
      }
    }
  };
  fetchAndCacheResults = (filters: FiltersResults) => {
    if (!this.state.cachedResults) {
      this.props.fetchResults(filters).then((response: ResultsI) => {
        this.filterAndPaginated(filters, response);
      });
    } else if (this.state.cachedResults) {
      this.filterAndPaginated(filters, this.state.cachedResults);
    }
  };

  filterAndPaginated = (filters: FiltersResults, response: any) => {
    if (filters && filters.sortBy && filters.sortDirection) {
      this.sortClientSide(filters.sortBy, filters.sortDirection, response);
    } else {
      this.paginationClientSide(response);
    }
  };
  onHandleClickPage = (page: number) => {
    this.setState({ filters: { ...this.state.filters, page: page } }, () => {
      if (!this.props.disableURLFilters) {
        objectToQueryString({ ...this.state.filters, page: page }, this.props.history);
      }
    });
  };
  paginationClientSide = (response: ResultsI) => {
    let paginatedResults = { ...response };
    if (response) {
      let resultsFilter = response.data;
      const otherFilters: any = {};
      Object.keys(this.state.filters).forEach((key: string) => {
        if (key !== "page" && key !== "perPage" && key !== "sortBy" && key !== "sortDirection" && this.state.filters[key] !== "") {
          otherFilters[key] = this.state.filters[key];
        }
      });
      if (Object.keys(otherFilters).length && this.props.filterResults) {
        resultsFilter = this.props.filterResults(otherFilters, resultsFilter, this.state.columns);
        paginatedResults = { count: resultsFilter.length, data: resultsFilter };
      }
      if (this.state && this.state.filters && this.state?.filters?.page && this.state.filters.perPage) {
        const start = this.state.filters ? this.state?.filters?.page * this.state.filters.perPage - this.state.filters.perPage : 0;
        const end = this.state.filters ? this.state?.filters?.page * this.state.filters.perPage : 0;
        paginatedResults.data = paginatedResults.data ? paginatedResults.data.slice(start, end) : paginatedResults.data;
      } else {
        const _page = 1;
        const _perPage = this.props.disablePagination ? paginatedResults?.count : 10;
        this.setState({ filters: { ...this.state.filters, page: _page, perPage: _perPage } });
        const start = this.state.filters ? _page * _perPage - _perPage : 0;
        const end = this.state.filters ? _page * _perPage : 0;
        paginatedResults.data = paginatedResults.data ? paginatedResults.data.slice(start, end) : paginatedResults.data;
      }
      this.setState({
        results: paginatedResults,
        cachedResults: response,
      });
    }
  };

  changeSortBy = (value: string, direction: string) => {
    this.setState({ filters: { ...this.state.filters, sortBy: value, sortDirection: direction } });
    if (!this.props.disableURLFilters) {
      objectToQueryString({ ...this.state.filters, sortBy: value, sortDirection: direction }, this.props.history);
    }
  };
  sortClientSide = (value: any, direction: any, response: any) => {
    if (response && response.data) {
      let result = response;
      result.data = typeof this.props.customClientSideSort === "function" ? this.props.customClientSideSort(result.data, value, direction) : this.sortData(result.data, value, direction);
      this.paginationClientSide(result);
    }
  };
  sortData = (data: any[], value: any, direction: any) => {
    return data.sort((a, b) => {
      let nameA = a[value],
        nameB = b[value];
      const valueA = nameA && typeof nameA === "object" ? nameA.label : nameA;
      const valueB = nameB && typeof nameB === "object" ? nameB.label : nameB;
      if (direction === "asc") {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
      }
      return 0;
    });
  };

  changeColumns(items: any) {
    this.setState({ columns: items });
    if (this.props?.resultsId && this.props?.selectecTenantId) {
      const reducedColumns = items.map((column: any) => {
        return {
          key: column?.columnKey,
          displayed: column?.displayed || column?.displayed === undefined ? true : false,
        };
      });
      tableStorage.setTableConfigStorage(this.props.resultsId, this.props.selectecTenantId, reducedColumns);
    }
  }
  updateColumnsWidth = (columnsWidthData: any[]) => {
    let newColumnsSet = [...this.state.columns];
    let newColumn = {};
    newColumnsSet.forEach((column, index) => {
      let thisColumn = columnsWidthData.find((a: any) => a.columnKey === column.columnKey);
      if (thisColumn) {
        newColumn = { ...column, width: thisColumn.width };
      } else {
        newColumn = column;
      }
      newColumnsSet[index] = newColumn;
    });
    this.setState({ columns: newColumnsSet });
  };

  serializeFormMapValues = (formValues: any) => {
    const serializeFormValues = serializeForm(formValues, true);
    return this.parserFormValues(serializeFormValues);
  };
  parserFormValues = (serializeFormValues: any) => {
    let parserFormValues: FiltersResults = {};
    Object.keys(serializeFormValues).forEach((formKey: string) => {
      if (formKey === "page" || formKey === "perPage") {
        parserFormValues[formKey] = parseInt(serializeFormValues[formKey]);
      } else if (formKey === "paginator-selector") {
        parserFormValues["page"] = parseInt(serializeFormValues[formKey]);
      } else {
        parserFormValues[formKey] = serializeFormValues[formKey];
      }
    });
    return parserFormValues;
  };
  validateForm = async () => {
    this._isValid = this.filtersFormRef && this.filtersFormRef.current ? await this.filtersFormRef.current.isValid() : true;
  };
  handleSubmitAdvanced = async (e: any) => {
    this._isValid = await (this.filtersFormRef && this.filtersFormRef.current ? this.filtersFormRef.current.isValid() : true);
    if (this._isValid) {
      const formValues = this.serializeFormMapValues(e.target);
      //only keep the filters from the state
      const filtersToRetain: FiltersResults = {};
      if (this.props.defaultFiltersResults) {
        Object.keys(this.props.defaultFiltersResults).forEach((keyDeafultProps: string) => {
          filtersToRetain[keyDeafultProps] = this.state?.filters[keyDeafultProps];
        });
      } else {
        Object.keys(defaultFiltersResults).forEach((keyDeafult: string) => {
          filtersToRetain[keyDeafult] = this.state?.filters[keyDeafult];
        });
      }
      //Reset page
      if (!this.props.disablePagination) filtersToRetain["page"] = 1;
      const newFilters = { ...filtersToRetain, ...formValues };
      if (e?.submitter?.name && this.props.customSubmits && this.props.customSubmits?.[e?.submitter?.name]) {
        this.props.customSubmits?.[e?.submitter?.name](newFilters);
      } else {
        this.setState({ filters: newFilters }, () => {
          if (!this.props.disableURLFilters) {
            objectToQueryString({ ...this.state.filters, ...formValues }, this.props.history);
          }
        });
      }
    }
  };

  handleOnChange = async (e: any = {}) => {
    this._isValid = await (this.filtersFormRef && this.filtersFormRef.current ? this.filtersFormRef.current.isValid() : true);
    if (this._isValid) {
      const formValues = this.parserFormValues(this.filtersFormRef.current.serialize());
      const filtersToRetain: FiltersResults = {
        ...(!this.props.disableURLFilters && window.location.search ? queryStringToObject(window.location.search) : this.state.filters),
        page: this.props.disablePagination ? undefined : 1,
      };
      const newFilters = { ...filtersToRetain, ...formValues };
      // Avoid problems remove last item and no serialize
      if (e.target?.current?.name && newFilters && newFilters[e.target?.current?.name]) {
        if (e.target.value.toString() === "") {
          delete newFilters[e.target.current.name];
        }
      }
      if (JSON.stringify(newFilters) !== JSON.stringify(this.state.filters)) {
        this.setState({ filters: newFilters }, () => {
          if (!this.props.disableURLFilters) {
            objectToQueryString(newFilters, this.props.history);
          }
        });
      }
    }
  };

  clearFieldValue = (key: string) => {
    let value = _.cloneDeep(this.state.filters);
    if (Array.isArray(key) && value[key[0]]) {
      const index = value[key[0]].indexOf(key[1]);
      if (index > -1) {
        value[key[0]].splice(index, 1);
      }
      if (!Object.keys(value[key[0]]).length) {
        delete value[key[0]];
      }
      if (value[key[0]].length === 1) {
        value[key[0]] = value[key[0]][0];
      }
    } else {
      delete value[key];
    }
    value.page = this.props.disablePagination ? undefined : 1;
    this.setState({ filters: value }, () => {
      if (!this.props.disableURLFilters) {
        objectToQueryString(value, this.props.history);
      }
    });
    if (typeof this.props.handleClearFilters === "function") {
      this.props.handleClearFilters(key);
    }
  };
  getFiltersTable = () => {
    let tableFilters: FiltersResults = {};
    if (this.state.filters) {
      Object.keys(this.state.filters).forEach((filterKey: string) => {
        if (filterKey !== "page" && filterKey !== "perPage") {
          tableFilters[filterKey] = this.state.filters[filterKey];
        }
      });
    }
    return tableFilters;
  };

  getPages = (count: number, perPage: number) => {
    let pages = Math.trunc(count / perPage);
    const rest = count % perPage;
    if (rest) return pages + 1;
    return pages;
  };

  render() {
    if (this.checkIfDataIsFetched()) {
      const _currentFiltersTable = this.getFiltersTable();
      const resultsCountDefined = this.state.results?.count !== null && this.state.results?.count !== undefined;
      const displayedColumns =
        typeof this.state.columns === "object" && this.state.columns.length ? this.state.columns.filter((e: any) => typeof e.displayed === "undefined" || e.displayed === true) : this.state.columns;
      let pages = 1;
      if (this.state.results && resultsCountDefined) {
        pages = this.getPages(this.state.results.count, this.state.filters.perPage || 10);
      }
      return (
        <ResultsContext.Provider value={this}>
          <div className={`data-table-wrapper  ${getTagDSTheme(store?.getState()?.tenant?.selectedTenant?.theme)} ${!this.props.oldUI ? " results tag-ds" : " results_deprecated adecco-ui"}`}>
            {this.props?.disableFilters ? (
              <GridView
                results={this.state.results}
                columns={displayedColumns}
                sortBy={this.state.filters?.sortBy}
                sortDirection={this.state.filters?.sortDirection}
                changeSortBy={this.changeSortBy}
                updateColumnsConfiguration={this.updateColumnsWidth}
                renderHeader={this.props.renderHeader}
                renderItems={this.props.renderItems}
                tableId={this.props.tableId}
                showTopScroll={this.props.showTopScroll}
              />
            ) : (
              <Form
                name="filtersForm"
                id="filtersForm"
                className="results-wrapper"
                loading={typeof this.state?.results === "undefined"}
                onSubmit={this.handleSubmitAdvanced}
                onChange={this.props.filterOnChange ? this.handleOnChange : undefined}
                values={this.state.filters}
                noValidate
                ref={this.filtersFormRef}
                debounceTimer={1000}
              >
                <Filters change={null} values={_currentFiltersTable} handleClearFilters={this.clearFieldValue} formRef={this.filtersFormRef}>
                  {this.props.children}
                </Filters>

                {(this.props.hideFiltersSummary !== true || this.props.hideTableConfiguration !== true) && (
                  <div className="results-summary-container">
                    {this.props.hideFiltersSummary !== true && (
                      <FiltersSummary filterValues={_currentFiltersTable} clearFieldValue={this.clearFieldValue} nonClosableFilters={this.props.nonClosableFilters || []} />
                    )}
                    {this.props.hideTableConfiguration !== true && <TableConfiguration changeColumns={this.changeColumns} columns={this.state.columns} defaultColumns={this.props.columns} />}
                  </div>
                )}

                <GridView
                  results={this.state.results}
                  columns={displayedColumns}
                  sortBy={this.state.filters?.sortBy}
                  sortDirection={this.state.filters?.sortDirection}
                  changeSortBy={this.changeSortBy}
                  updateColumnsConfiguration={this.updateColumnsWidth}
                  renderHeader={this.props.renderHeader}
                  renderItems={this.props.renderItems}
                  tableId={this.props.tableId}
                  showTopScroll={this.props.showTopScroll}
                  enableResize={this.props.enableResize} //allows the user to change the column width
                />

                {this._isValid && resultsCountDefined && !this.props.disablePagination && (
                  <div className={this.props?.showItemsPerPage ? "paginator-holder" : ""}>
                    {this.props?.showItemsPerPage && (
                      <div className="items-per-page-holder" data-testid="items-per-page-holder">
                        <SelectInput className="items-per-page" name="perPage" value={this.state.filters?.perPage ? `${this.state.filters?.perPage}` : "10"}>
                          {[...(this.props?.itemsPerPage ?? ITEMS_PER_PAGE.GENERAL)]
                            .filter((element) => element <= (this.USER_MAX_RESULTS || 75))
                            .map((element, index) => (
                              <option key={index} value={element}>
                                {element}
                              </option>
                            ))}
                        </SelectInput>
                        <span>{toLiteral({ id: "items per page" })}</span>
                      </div>
                    )}
                    <Paginator
                      className="data-table-paginator tag-ds"
                      pages={pages}
                      onChangePage={this.onHandleClickPage}
                      defaultPage={this.state.filters.page ? this.state.filters.page : 1}
                      collapsed={pages >= 100}
                      collapsedOptions={{ jump: "all" }}
                      truncate={pages < 100 ? "all" : undefined}
                    />
                  </div>
                )}
              </Form>
            )}
          </div>
        </ResultsContext.Provider>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}
export default withRouter(Results);
