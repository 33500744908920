import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { toLiteral } from "../../../../helper/locale-utils";
import { DateInput, SelectInput } from "../../../shared/forms";
import { datePickerMode } from "../../../shared/forms/date-input-deprecated";
import { FilteredSearch, FilteredSearchTopSection } from "@adeccoux/tag-ds";

interface TableFiltersProps {
  dateFormat: string;
  refresh: number;
  setRefresh: Function;
  downloadExcell: any;
  setDownloadAll: Function;
}

const CammioReportFilters: React.FC<any> = (props: TableFiltersProps): ReactElement => {
  const { dateFormat, refresh, setRefresh, setDownloadAll } = props;

  const [rolesFilters, setRolesFilters] = useState<{ name: string; title: string }[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const roles = [
      { name: "user", title: "User / Admin" },
      { name: "superAdmin", title: "Super Admin" },
    ];
    setRolesFilters(roles);
  };

  const renderSelectOptions = (name: string) => {
    if (name === "rolesFilters") {
      return renderOptions(rolesFilters);
    } else {
      return <></>;
    }
  };

  const renderOptions = (options: any[]) => {
    return options?.map((element: any, index: number) => (
      <option key={index} value={element.name}>
        {element.title}
      </option>
    ));
  };

  const FilterComponent = () => {
    return (
      <FilteredSearch openFilters={false}>
        <FilteredSearchTopSection>
          <DateInput
            className="ml2"
            mode={datePickerMode.RANGE}
            dateFormat={dateFormat}
            placeholder="date"
            name="dates"
            customCalendar
            minDate={moment("2024-01-01").valueOf()}
            maxDate={moment().add("1", "day").valueOf()}
            readOnly
          />
          <SelectInput name={"rolesFilters"}>
            <option value={""}>{toLiteral({ id: "admin.manage.tenant.roles" })}</option>
            {renderSelectOptions("rolesFilters")}
          </SelectInput>
        </FilteredSearchTopSection>
      </FilteredSearch>
    );
  };

  return (
    <>
      <div className="tag-ds data-table-top-toolbar">
        <button className="button-secondary ml4" onClick={() => setShowFilters(!showFilters)}>
          {toLiteral({ id: "details.table.filters" })}
        </button>
        <button className="button-primary ml2" data-testid="generate-orr" onClick={()=>setRefresh(refresh + 1)}>
          {toLiteral({ id: "administrator.stats.generate.button" })}
        </button>
        <button className="button-primary ml2" data-testid="download-orr" onClick={()=> {
          setRefresh(refresh + 1)
          setDownloadAll(true)
        }}>
          {toLiteral({ id: "administrator.stats.download.button" })}
          <span className="material-icons right">download</span>
        </button>
      </div>
      {showFilters ? <FilterComponent /> : <></>}
    </>
  );
};

export default CammioReportFilters;
