import { PhaseType, Profession } from ".";
export interface VideoInterviewConfigI {
  skillsToMatch: number;

  cammioAnswerTime: number;
  cammioBrandingId: number;
  cammioMaxRetries: number;

  videoInterviewTemplateId?: number;

  questions?: VideoInterviewQuestionConfigI[];
}
export interface LiveInterviewConfigI {
  duration?: number;
  inviteOption: LiveInterviewConfigInviteOption;
  startSchedulingPeriod?: number;
  endSchedulingPeriod?: number;
  scheduleDate?: number;
  scheduleOption?: LiveInterviewConfigScheduleOption;

  calendars?: any[];
  assignCalendarId?: string;

  recruiters?: any[];
  recruiterIds?: string[];
  assignRecruiterId?: string;

  reviewerOne?: { name: string; email: string };
  reviewerTwo?: { name: string; email: string };
}

export enum LiveInterviewConfigInviteOption {
  auto,
  manual,
}
export enum LiveInterviewConfigScheduleOption {
  calendarAvailability,
  chooseTime,
}

export interface VideoInterviewQuestionConfigI {
  id?: string;
  questionLibraryId?: string;
  question: string;
  skillId: string;
  skill?: any;
  videoId?: string;
  scoreMin: number;
  scoreMax: number;
  serviceTemplateId?: string;
  contentType?: string;
}

export enum ServiceStatus {
  draft,
  planned,
  opened,
  expired,
  closed,
}
export interface ServiceCreationI {
  name: string;
  type: PhaseType;
  config: VideoInterviewConfigI | LiveInterviewConfigI | any;
  id?: string;
  industryId?: string;
  industry?: Profession;
  professionId?: string;
  profession?: Profession;
  serviceTemplateId?: string;
  serviceTemplate?: string;
  subBrandId?: string;
  subBrandName?: string;
  coBrandId?: string;
  coBrandName?: string;
  openDate?: number;
  expiryDate?: number;
  isGlobal?: boolean;
  isDraft?: boolean
  status?: number
  isTemplate?: boolean
}

export enum ServiceAssessmentVideoInterviewStatus {
  waiting,
  opened,
  started,
  processing,
  pending,
  completed,
  failed,
}

export interface ServiceAssessmentVideoInterviewPublicInfo {
  personId: string;
  personName: string;
  serviceId: string;
  serviceName: string;
  type: PhaseType;
  cammioVideoInterviewUrl: string;
  status: ServiceAssessmentVideoInterviewStatus;
}

export enum ServiceAssessmentLiveInterviewStatus {
  waiting,
  invited,
  created,
  opened,
  started,
  processing,
  pending,
  completed,
  failed,
}

export interface ServiceAssessmentLiveInterviewPublicInfo {
  personId: string;
  personName: string;
  serviceId: string;
  serviceName: string;
  type: PhaseType;
  cammioLiveInterviewUrl?: string;
  scheduleOption: LiveInterviewConfigScheduleOption;
  scheduleDate?: number;
  duration?: number;
  optionTimes?: any;
  status: ServiceAssessmentLiveInterviewStatus;
  recruiters?: { id: string; name: string; email: string }[];
  calendars?: { id: string; location: string }[];
  assignRecruiter?: { id: string; name: string; email: string };
  assignCalendar?: { id: string; name: string; location: string };
}

export interface ServiceAssessmentInviteLiveInterview extends LiveInterviewConfigI {
  timezone?: string;
}

export interface LocaleData {
  alias: string;
  regionId: string;
  zoneId: string;
  branchId: string;
}

export interface VideoDictionary {
  url: string;
  video: any;
}
