import React from "react";
import { FormContext } from "../form";
import BaseInput from "../base-input";
import HiddenInput from "../hidden-input";
import { validateInput } from "../utilities/validations";

class QuestionInput extends BaseInput {
  type = "QuestionInput";
  question = {};

  //Rest of the functions and properties exist in baseInput, who's shared accross all form inputs.
  //They can be overrided using the same name and params type.
  isSelectedAnswer = (valueSelected: string | string[], valueAnswer: string) => {
    return valueSelected && (valueSelected === valueAnswer.toString() || valueSelected.indexOf(valueAnswer) !== -1);
  };

  onClickOption = (j: string, isMultiple: boolean) => {
    const self = this;
    if (!this.props.disabled) {
      let value = null;
      // Multiple
      if (isMultiple) {
        value = this.state.value && this.state.value?.length > 0 ? [...this.state.value] : [];
        const indexAnswer = value.indexOf(j);
        if (indexAnswer !== -1) {
          value.splice(indexAnswer, 1);
        } else {
          value.push(j);
        }
        // Simple
      } else {
        value = this.state.value === j ? "" : j;
      }
      // Set value
      this.setState({ value }, () => {
        // Call validate
        validateInput(self, this.context);
      });
    }
  };

  render() {
    this.processCSSClasses();
    const defaultValue = this.props.multiple ? [] : "";
    const value: string | string[] = this.state.value ? this.state.value : defaultValue;

    return (
      <>
        <div className={`input-wrapper ${this.validationClass} ${this.loadingClass} ${this.props.className || ""}`}>
          {this.renderTooltipIcon()}

          <div className="multiple-message mt-2 ml-0">{this.props.explicationMessage || null}</div>
          <div className="-content-question">
            <div className="d-flex flex-column">
              {this.props.answers &&
                this.props.answers.map((answer: any, j: any) => (
                  <div
                    key={j}
                    onClick={() => this.onClickOption(j.toString(), this.props.multiple)}
                    className={this.isSelectedAnswer(value, j.toString()) ? "-option mt-2 position-relative selected" : "-option mt-2 position-relative"}
                  >
                    <div className="-option-border position-absolute"></div>
                    <div className={"-option-text text-break pl-3 pr-3"}>{answer.answer}</div>
                  </div>
                ))}
            </div>
            <HiddenInput placeholder={this.props.placeholder} name={this.props.name} value={value} />
          </div>
          {this.renderErrorMessage()}
        </div>
      </>
    );
  }
}

QuestionInput.contextType = FormContext;
export default QuestionInput;
