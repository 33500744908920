import React from "react";
import { FormContext } from "./form";
import { validateInput } from "./utilities/validations";
import BaseInput, { IState } from "./base-input";
import { toLiteral } from "../../../helper/locale-utils";

class RadioInputDeprecated extends BaseInput {
  type = "RadioInput";
  state: Readonly<IState> = {
    errors: this.props.errors,
    isValid: undefined,
    value: this.props.value,
    type: "radio",
    validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
    disabled: this.props.disabled,
    readOnly: this.props.readOnly,
    loading: this.props.loading,
    checked: this.props.checked,
    checkboxStyle: this.props.checkboxStyle,
  };

  uncheck = () => {
    if (this.state.checked) {
      this.setState({ checked: false });
    }
    this.validate();
  };

  validate = () => {
    if (this.state.validateOnChange) {
      validateInput(this, this.context);
    }
  };

  onChange = async (SyntheticEvent: any) => {
    let siblings: any = []; //had to use any because type changes depending on if there is context (react) or not (vanilla html)
    //event triggered by user
    if (this.context && this.context.inputs && this.context.inputs.length) {
      //radios inside form
      siblings = this.context.inputs.filter((el: any) => el !== this && el.type === "RadioInput" && el.props.name === this.props.name);
      siblings.forEach((element: any) => {
        element.uncheck();
      });
    }
    this.setState({ checked: true }, () => {
      this.validate();
      siblings.forEach((element: any) => {
        validateInput(this, this.context);
      });
    });
    if (this.props.onChange) {
      this.props.onChange(SyntheticEvent);
    }

    if (SyntheticEvent && !SyntheticEvent?.target?.fakeEvent && typeof this.state.parentFormOnChange === "function") {
      this.state.parentFormOnChange(SyntheticEvent);
    }
  };

  render() {
    this.processCSSClasses();
    const checked = typeof this.state.checked !== "undefined" && this.state.checked !== null ? this.state.checked : false;
    const disabledClass = this.state.disabled ? "disabled" : "";
    const checkboxStyle = this.props.checkboxStyle;
    const _label = this.props?.label;
    const labelContent = typeof _label === "string" ? toLiteral({ id: _label }) : _label;
    return (
      <>
        <div className={`input-wrapper   ${checkboxStyle ? "input-wrapper-checkbox" : "input-wrapper-radio"}  ${this.validationClass}  ${disabledClass}`}>
          <div className={this.props.className || ""}>
            <span className={`fake-input ${checkboxStyle ? "checkbox" : "radio"} ${this.validationClass}  ${disabledClass}  ${this.state.checked ? "active" : ""}`}>
              <input disabled={this.state.disabled} ref={this.inputRef} name={this.props.name} value={this.props.value} id={this.props.id} type="radio" onChange={this.onChange} checked={checked} />
            </span>
            <label htmlFor={this.props.id} className={this.validationClass}>
              {labelContent} {this.renderTooltipIcon()}
            </label>
          </div>
        </div>
      </>
    );
  }
}

RadioInputDeprecated.contextType = FormContext;
export default RadioInputDeprecated;
