import React, { ReactElement } from "react";
import { toLiteral } from "../../../helper/locale-utils";
import Notification, { NotificationBody, NotificationFooter, NotificationHeader } from "@adeccoux/tag-ds/notification";

const RequestErrorMessage: React.FC<any> = (props): ReactElement => {
  return (
    <Notification className="notification_relative" renderAsPortal={false} data-testid="notification" show={true} error={true}>
      <NotificationHeader>{toLiteral({ id: "Info" })}</NotificationHeader>
      <NotificationBody>{toLiteral({ id: "There was an error submiting your enquiry" })}</NotificationBody>
      <NotificationFooter>{props.children}</NotificationFooter>
    </Notification>
  );
};

export default RequestErrorMessage;
