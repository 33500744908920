import { START_VALIDATE_YEAR } from "../constants";
import { getTodayYear } from "./calendar-helper";

/* REGEX LIBRARY
  \x21-\x25 = characters from "!" to "%"
  \x28-\x29 = characters from "(" to ")"
  \x2A-\x2D = characters from "*" to "-"
  \x3B-\x3E = characters from ";" to ">"
  \x2F = "/"
  \x5C = "\"
*/

// [0-9] => code smell , use \d

const eMailRegExp = /^([A-Za-z0-9_\-.]){1,}@([A-Za-z0-9_\-.]){1,}\.([A-Za-z]){2,4}$/;
const invalidNameRegExp = /[\x21-\x26\x28-\x2F\x3A-\x40\x5B-\x5D\x7B-\x7D]/;
const invalidNameWithHyphenRegExp = /[\x21-\x26\x28-\x2C\x2E-\x2F\x3A-\x40\x5B-\x5D\x7B-\x7D]/;
const validCamelcase = /[^\x21-\x26|^\x28-\x2F|^\x3A-\x40|^\x5B-\x5D|^\x7B-\x7D]$/;
const phoneRegExp = /^[+]*[(]{0,1}\d{1,4}[)]{0,1}[-\s./0-9]*$/;
const nameRegExp = /[^\x21-\x26|^\x28-\x2F|^\x3A-\x40|^\x5B-\x5D|^\x7B-\x7D]$/;
const invalidTagRegExp = /[\x21-\x26\x2A-\x2F\x3B-\x3F\x7B-\x7D]/; //previous regExp, not working as intended
// \p{x} are unicode character properties for L: letters, N: numbers, Pd: punctuation dashes, Pc: punctuation connectors
const processNameRegex = new RegExp(/^[\p{L}\p{N}\p{Pd}\p{Pc} ]+$/u);
const processNameRegexInverted = new RegExp(/[^\p{L}\p{N}\p{Pd}\p{Pc} ]/gu);
const gmailRegExp = /^[\w.+-]+@gmail\.com$/;
const invalidInputRegExp = /[\x21-\x26|\x28-\x2B|\x2F|\x3A-\x40|\x5B-\x5D|\x7B-\x7D]/;
const requisitionIdRegExp = /^([A-Z]){2}([_]){1}([A-Z]){2}([_]){1}(\d){1,2}([_]){1}(\d){6}([_]){1}(\d){7}$/;
const uuidRegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const extractUUID = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
const hexColorRegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const isoCodeRegExp = /^([a-z]{2}-[A-Z]{2})$/;
const numberRegExp = /^[\d]+$/;

export const htmlStrangeCharacters = /\u0300-\u036F|\u200B|\u200C|\uFEFF|\u200D|\u00A0/g; //matches stranges spaces
export const htmlExtraSpacesRegExp = /(<div>\r\n<\/div>)+/g; //matches HTML extra spaces
export const htmlOnlyOneExtraSpace = "<div>\r\n</div>"; // replace HTML extra spaces by one
export const htmlRegExp = /<[^>]{0,9999}>/gi; //matches HTML tags and leaves the content
export const trimWhiteSpacesFromBegining = /^\W{0,9999}/; //this matches HTML tags and leaves the content
export const trimWhiteSpacesFromEnd = /\W{0,9999}$/;

export const regExpHelper = {
  alphaExp: nameRegExp,
  nameRegExp,
  eMailRegExp,
  invalidTagRegExp,
  processNameRegex,
  processNameRegexInverted,
  validCamelcase: validCamelcase,
  htmlRegExp,
  hexColorRegExp,

  isIsoCode: (lang: string): boolean => {
    return lang?.length > 0 && isoCodeRegExp.test(lang);
  },
  isHtml: (htmlStr: string): boolean => {
    return new RegExp(htmlRegExp).test(htmlStr);
  },
  isEmail: (email: string): boolean => {
    if (email.match(eMailRegExp)) return true;
    return false;
  },
  isInvalidTagAlphabet: (text: string): boolean => {
    return text.length > 0 && invalidTagRegExp.test(text);
  },
  isInvalidProcessNameAlphabet: (text: string): boolean => !processNameRegex.test(text),
  findProcessNameInvalidCharacters: (text: string): string[] => {
    const matches = text.match(new RegExp(processNameRegexInverted)) || [];
    if (matches) {
      // Use a Set to remove duplicates and then convert it back to an array
      return [...new Set(matches)];
    } else {
      return [];
    }
  },
  replaceProcessNameInvalidCharacters: (text: string): string => {
    return text.replace(new RegExp(processNameRegexInverted), "");
  },
  isAlphabet: (text: string): boolean => {
    return text.length > 0 && !invalidNameRegExp.test(text);
  },
  isAlphabetWithHyphen: (text: string): boolean => {
    return text.length > 0 && !invalidNameWithHyphenRegExp.test(text);
  },
  isLower255Chars: (text: string): boolean => {
    return text.length < 255;
  },
  isNotEmptyAlphabet: (text: string): boolean => {
    return text.length > 0 && !invalidNameRegExp.test(text);
  },
  isCamelcase: (text: string): boolean => {
    return text.length > 0 && validCamelcase.test(text) && text.split(" ").length <= 1;
  },
  isPhone: (phoneNumber: string): boolean => {
    if (phoneNumber?.match(phoneRegExp)) return true;
    return false;
  },
  isGmail: (email: string): boolean => {
    if (email.match(gmailRegExp)) return true;
    return false;
  },
  isValidTextInput: (textInput: string): boolean => {
    return textInput.length > 0 && !invalidInputRegExp.test(textInput);
  },
  isRequisitionId: (requisitionId: string): boolean => {
    if (requisitionId.match(requisitionIdRegExp)) return true;
    return false;
  },
  isValidUuid: (uuid: any): boolean => {
    if (uuid.match(uuidRegExp)) return true;
    return false;
  },
  isHexColor: (color: string): boolean => {
    if (color.match(hexColorRegExp)) return true;
    return false;
  },
  isNumber: (numb: string): boolean => {
    if (numb.match(numberRegExp)) return true;
    return false;
  },
  isMonth: (month: string): boolean => {
    const numMonth = parseInt(month);
    return month?.length > 0 && !isNaN(numMonth) && numMonth >= 1 && numMonth <= 12;
  },
  isYear: (year: string): boolean => {
    const numYear = parseInt(year);
    return year?.length > 0 && !isNaN(numYear) && numYear >= START_VALIDATE_YEAR && numYear <= getTodayYear();
  },
  validatePhone(phone: string, specificPhone?: any): boolean {
    let validate = regExpHelper.isPhone(phone);
    if (specificPhone && specificPhone.regex !== undefined) {
      if (phone.match(specificPhone.regex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return validate;
    }
  },
  getUUIDfromString(_string: string): string {
    const processUUID = _string?.match(extractUUID);
    return processUUID && processUUID.length ? processUUID[processUUID.length - 1] : "";
  },
  isValidExternalUserId(externalId: string): boolean {
    if (externalId) {
      if (externalId.length <= 50) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  getPhonePrefixFromCountryAndPrefix(_string: string) {
    const textInsideParanthesis = _string?.split("(")?.[1]?.split(")")?.[0]; //only gets first match
    return textInsideParanthesis;
  },
};
