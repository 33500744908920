import { IProps as IPropsBaseInput, IState as IStateBaseInput } from "../base-input";
import HiddenInput from "../hidden-input";
import { getNestedObjectValueByString } from "../utilities/serialize";
/**
 * This is the interface for a form multilanguage option
 */
export interface IFormMultilanguageOption {
  name: string;
  isoCode: string;
  default?: boolean;
}

const ISOInputValueName = (propertyName?: string, languageISOCode?: string) => `multilanguage.${propertyName}__${languageISOCode}`;

/**
 *This function returns the translation of a property according to the Multilanguage module, that generates extra properties on a form
 serialization that match the original including a suffix like... "__en-GB" (ie: name__en-GB)
 if it fails to find the value will return the original one.
 It also allows to find complex object values (like nested properties divided by dots or [])
 * @param object
 * @param propertyName
 * @param languageISOCode
 * @returns the value from the object translated to a language
 */
export const getTranslation = (object: any, propertyName: string, languageISOCode: string | undefined) => {
  let value = undefined;
  try {
    if (object && propertyName) {
      value = getNestedObjectValueByString(object, ISOInputValueName(propertyName, languageISOCode));
      if (value === "" || value === undefined || value === null) {
        value = getNestedObjectValueByString(object, propertyName);
      }
    }
    return value;
  } catch (e) {}
};

/**
 * This function renders as many hidden inputs as available languages there are in a form, avoiding to create the current language as hidden input
 * @param formInputComponent
 * @returns the rendering of the new hidden inputs
 */
export const renderMutilanguageDefaultHiddenInput = (formInputComponent: React.Component<IPropsBaseInput, IStateBaseInput>) => {
  const self = formInputComponent;
  const formContext = self.context;
  const availableLanguages = formContext?.state?.availableLanguages;
  if (availableLanguages && availableLanguages.length > 1) {
    return availableLanguages?.map((language: IFormMultilanguageOption, i: number) => {
      const inputName = ISOInputValueName(self.props.name, language.isoCode); // add the iso code to the input only if the language is not default
      const languageIsDefaultAndNoCurrentLanguageIsSet = !self.state?.currentLanguage && (!language.isoCode || language.isoCode === "" || language.default);
      const languageIsEqualToCurrentLanguage = self.state?.currentLanguage?.isoCode === language.isoCode;
      if (!languageIsEqualToCurrentLanguage && !languageIsDefaultAndNoCurrentLanguageIsSet) {
        //render the hidden input only if this language is not the same as the selected one, otherwise it will be the actual displayed input
        return (
          <>
            {language?.default && <HiddenInput key={self.props.name} name={self.props.name} />}
            <HiddenInput key={i} name={inputName} />
            <HiddenInput key={`language-${i}`} name={`multilanguage.languages[${i}]`} value={language.isoCode} />
          </>
        );
      } else return <HiddenInput key={`language-${i}`} name={`multilanguage.languages[${i}]`} value={language.isoCode} />;
    });
  }
  return <></>;
};

/**
 * This function detects the current language and generates a new input name based on the currently selected language
 * @param formInputComponent
 * @returns string (the new input name)
 */
export const getMultilanguageInputName = (formInputComponent: React.Component<IPropsBaseInput, IStateBaseInput>) => {
  const currentLanguage = formInputComponent?.state?.currentLanguage;
  const inputName = !currentLanguage || currentLanguage?.default ? formInputComponent.props.name : ISOInputValueName(formInputComponent.props.name, currentLanguage?.isoCode); // add the iso code to the input only if the language is not default
  if (formInputComponent.state.name !== inputName) formInputComponent.setState({ name: inputName });
  return inputName;
};
