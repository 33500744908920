import moment from "moment";

export interface InfoCalendars {
  id: string;
  name: string;
  availableSlots: number;
  totalSlots: number;
  capacity: number;
}

export const getDaysInMonth = function (date: Date) {
  let month = date.getMonth();
  let year = date.getFullYear();
  return new Date(year, month + 1, 0).getDate();
};

export const getDayOfTheWeek = function (date: Date) {
  let dayOfWeek = date.getDay();

  dayOfWeek = dayOfWeek - 1;
  if (dayOfWeek === -1) {
    dayOfWeek = 6;
  }
  return dayOfWeek;
};

export const getFirstDayOfMonth = function (date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  return new Date(year, month, 1);
};

export const getLastDayOfMonth = function (date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  return new Date(year, month, getDaysInMonth(date));
};

export const getPreviousMonth = function (date: Date) {
  var prevMonthDate = new Date(date);
  return new Date(prevMonthDate.setDate(0));
};

export const getNextMonth = function (date: Date) {
  var prevMonthDate = new Date(date);
  let firstDayOfNextMonth = getLastDayOfMonth(date).getDate() + 1;
  return new Date(prevMonthDate.setDate(firstDayOfNextMonth));
};

export const getDisplayedDaysPrevMonth = function (date: Date) {
  const currentMonth_firstDay = getFirstDayOfMonth(date);
  const currentMonth_firstDayOfWeek = getDayOfTheWeek(currentMonth_firstDay);

  const previousMonth = getPreviousMonth(date);
  const previousMonth_numberOfDays = getDaysInMonth(previousMonth);
  const previousMonth_firstDisplayedDay = previousMonth_numberOfDays - currentMonth_firstDayOfWeek + 1;

  let month = previousMonth.getMonth();
  let year = previousMonth.getFullYear();

  let firstDisplayedDay = new Date(year, month, previousMonth_firstDisplayedDay);
  let lastDisplayedDay = getLastDayOfMonth(previousMonth);

  if (firstDisplayedDay <= lastDisplayedDay) {
    return getDaysFromTo(firstDisplayedDay, lastDisplayedDay);
  }
  return [];
};

export const getDisplayedDaysNextMonth = function (date: Date) {
  const currentMonth_LastDay = getLastDayOfMonth(date);
  const currentMonth_LastDayOfWeek = getDayOfTheWeek(currentMonth_LastDay);

  const nextMonth = getNextMonth(date);

  const nextMonth_lastDisplayedDay = 6 - currentMonth_LastDayOfWeek;

  let month = nextMonth.getMonth();
  let year = nextMonth.getFullYear();

  let firstDisplayedDay = new Date(year, month, 1);
  let lastDisplayedDay = new Date(year, month, nextMonth_lastDisplayedDay);
  if (firstDisplayedDay >= lastDisplayedDay) return getDaysFromTo(lastDisplayedDay, firstDisplayedDay);
  else return getDaysFromTo(firstDisplayedDay, lastDisplayedDay);
};

export const getDifferenceInDays = function (dateFrom: Date, dateTo: Date) {
  let difference_In_Time = dateTo.getTime() - dateFrom.getTime();
  let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  let remainder = difference_In_Days % 1;
  difference_In_Days = remainder > 0 ? Math.floor(difference_In_Days) + remainder : Math.floor(difference_In_Days);
  return parseInt(difference_In_Days.toFixed(0));
};

export const getDaysFromTo = function (dateFrom: Date, dateTo: Date) {
  const difference_In_Days: number = getDifferenceInDays(dateFrom, dateTo);
  let daysArray = [];
  for (let index = 0; index <= difference_In_Days; index++) {
    const nextDay: any = index === 0 ? null : daysArray[index - 1];
    daysArray[index] = nextDay ? getDatePlussDays(nextDay, -1) : dateTo;
  }
  return daysArray.reverse();
};

export const getDatePlussDays = function (date: Date, days: number) {
  let newDate = new Date(date);
  return new Date(newDate.setDate(newDate.getDate() + days));
};

export const compareDateDays: Function = function (date1: Date, date2: Date) {
  let _date1 = new Date(date1).setHours(0, 0, 0, 0);
  let _date2 = new Date(date2).setHours(0, 0, 0, 0);

  return _date1 === _date2;
};

export const getDatesBetween2Dates = function (date1: Date, date2: Date) {
  var dates = [];
  if (date1 && !date2) {
    dates.push(date1);
  }

  if (!date1 && date2) {
    dates.push(date2);
  }

  if (date1 && date2) {
    let startDate = date1;
    let endDate = date2;

    if (endDate < startDate) {
      startDate = date2;
      endDate = date1;
    }

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");

    dates.push(startDate);
    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(currDate.clone().toDate());
    }

    return dates;
  }
};

// Transform the dates of DateInput Range component, into: timestamp dates (for BE) -> dates: [{'01/12/2023', '10/12/2023'}]
export const fixDatesInMergeDatePicker = (dates: string[], dateFormat: string, withoutTimestamps?: boolean) => {
  let initDate, endDate;
  let indexDay: number, indexMonth: number, indexYear: number;
  //?Check format
  switch (dateFormat) {
    case "DD/MM/yyyy":
      indexDay = 0;
      indexMonth = 1;
      indexYear = 2;
      break;
    case "yyyy/MM/DD":
      indexDay = 2;
      indexMonth = 1;
      indexYear = 0;
      break;
    default:
      indexDay = 1;
      indexMonth = 0;
      indexYear = 2;
      break;
  }

  if (dates?.length) {
    let dataDate = dates[0]?.split("/");
    if (dataDate?.length === 3) {
      let day = parseInt(dataDate[indexDay]);
      let month = parseInt(dataDate[indexMonth]);
      let year = parseInt(dataDate[indexYear]);
      initDate = moment()
        .set("year", year)
        .set("month", month - 1)
        .set("date", day);
      if (!withoutTimestamps) initDate = initDate.valueOf();
    }
    dataDate = dates[1]?.split("/");
    if (dataDate?.length === 3) {
      let day = parseInt(dataDate[indexDay]);
      let month = parseInt(dataDate[indexMonth]);
      let year = parseInt(dataDate[indexYear]);
      endDate = moment()
        .set("year", year)
        .set("month", month - 1)
        .set("date", day);
      if (!withoutTimestamps) endDate = endDate.valueOf();
    }
  }
  return { initDate, endDate };
};
