export const defaultRefactorColumns = [
  { name: "tenantId", default: true, sortable:true },
  { name: "name", default: true, sortable:true },
  { name: "email", default: true, sortable:true },
  { name: "lastlogin", default: true, sortable:true },
  { name: "superAdmin", default: true, sortable:true },
  { name: "removed", default: true , sortable:true },
  { name: "LVIInterviewer", default: true }, 
  { name: "LVICammioTemplateDeleted", default: false },
  { name: "VICammioTemplateCreated", default: true },
  { name: "VICammioTemplateUpdated", default: false }, 

];
