/* PRIVATE PART */
interface IDisableResponse {
  fieldRelated: string | undefined;
  required: boolean;
}
const notChangeField = undefined;
const permContract = "EEDECT013";
const requireRelatedInput = (name: string, required: boolean, context: any) => {
  const inputComponent: any = context?.inputs?.find((a: any) => a.props.name === name && a._isMounted);
  if (inputComponent) {
    // Review the option when it is required in props and not in related required
    inputComponent.setState({ requireByRelated: required });
  }
};
/* END PRIVATE PART */

/* BASE FUNCTION PART */
export async function checkRequiredInput(inputComponent: any, context: any) {
  const relatedRequires = inputComponent.props.requireRelated || [];
  for await (const relatedRequire of relatedRequires) {
    if (typeof relatedRequire === "string") {
      let requiredRelated: IDisableResponse;
      switch (relatedRequire) {
        case "salaryMinRelatedContractType":
          requiredRelated = requiredSalaryMinContractType(inputComponent, context);
          break;
        case "salaryMinRelatedGFLO":
          requiredRelated = requiredSalaryMinGFLO(inputComponent, context);
          break;
        case "salaryMaxRelatedContractType":
          requiredRelated = requiredSalaryMaxContractType(inputComponent, context);
          break;
        case "salaryMaxRelatedGFLO":
          requiredRelated = requiredSalaryMaxGFLO(inputComponent, context);
          break;
        case "dataEducationRelated":
          requiredRelated = requiredEducation(inputComponent, context);
          break;
        case "collectiveAgreementNameRequired":
          requiredRelated = requiredCollectiveAgreementName(inputComponent, context);
          break;
        default:
          requiredRelated = { fieldRelated: notChangeField, required: false };
      }
      if (typeof requiredRelated?.fieldRelated !== "undefined") {
        // Disable the related field
        requireRelatedInput(requiredRelated.fieldRelated, requiredRelated.required, context);
      }
    }
  }
}
/* END BASE FUNCTION PART */

/* FUNCTIONS PART */
const requiredSalaryMinContractType = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, required: false };
  const gfloContractTypeValues = ["EEDECT011", "EEDECT012", "EEDECT015"]
  const procurementGFLO = _context?.inputs.find((_input: any) => _input.state.name === "dataProdurementOrderGFLO");
  const publishGFLO = _context?.inputs.find((_input: any) => _input.state.name === "dataPublishGFLO");
  const procurementGfloEnabled = procurementGFLO.state.value;
  const publisGfloEnabled = publishGFLO.state.value;
  result.fieldRelated = "dataSalaryMin";
  let valueContractType = "";
  valueContractType = input.state.value;
  if (valueContractType === permContract || (publisGfloEnabled === "true" && procurementGfloEnabled === "true" &&  gfloContractTypeValues.includes(input.state.value))) {
    result.required = true;
  } else {
    result.required = false;
  }
  return result;
};

const requiredSalaryMinGFLO = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, required: false };
  const gfloContractTypeValues = ["EEDECT011", "EEDECT012", "EEDECT015"]
  const inputContractType = _context?.inputs.find((_input: any) => _input.state.name === "dataContractType")
  const contractTypeValue = inputContractType.state.value;
  let gfloEnabled = "";
  if (input.state.name === "dataProdurementOrderGFLO") {
    gfloEnabled = input.state.value;
  }
  result.fieldRelated = "dataSalaryMin";
  let valueContractType = "";
  valueContractType = contractTypeValue;
  //PERM contract type will make salary always be required
  if (valueContractType === permContract || (gfloEnabled === "true" && gfloContractTypeValues.includes(contractTypeValue))) {
    result.required = true;
  } else {
    result.required = false;
  }
  return result;
};

const requiredSalaryMaxContractType = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, required: false };
  const gfloContractTypeValues = ["EEDECT011", "EEDECT012", "EEDECT015"]
  const procurementGFLO = _context?.inputs.find((_input: any) => _input.state.name === "dataProdurementOrderGFLO");
  const publishGFLO = _context?.inputs.find((_input: any) => _input.state.name === "dataPublishGFLO");
  const procurementGfloEnabled = procurementGFLO.state.value;
  const publisGfloEnabled = publishGFLO.state.value;
  result.fieldRelated = "dataSalaryMax";
  let valueContractType = "";
  valueContractType = input.state.value;
  if (valueContractType === permContract || (publisGfloEnabled === "true" && procurementGfloEnabled === "true" && gfloContractTypeValues.includes(input.state.value))) {
    result.required = true;
  } else {
    result.required = false;
  }
  return result;
};

const requiredSalaryMaxGFLO = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, required: false };
  const gfloContractTypeValues = ["EEDECT011", "EEDECT012", "EEDECT015"]
  const inputContractType = _context?.inputs.find((_input: any) => _input.state.name === "dataContractType")
  const contractTypeValue = inputContractType.state.value;
  let gfloEnabled = "";
  if (input.state.name === "dataProdurementOrderGFLO") {
    gfloEnabled = input.state.value;
  }
  result.fieldRelated = "dataSalaryMax";
  let valueContractType = "";
  valueContractType = contractTypeValue;
  //PERM contract type will make salary always be required
  if (valueContractType === permContract || (gfloEnabled === "true" && gfloContractTypeValues.includes(contractTypeValue))) {
    result.required = true;
  } else {
    result.required = false;
  }
  return result;
};

const requiredEducation = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, required: false };
  result.fieldRelated = "dataEducation";
  let valueHiringType = "";
  let valuePublishGFLO = "";

  if (input?.props?.name === "dataHiringType") {
    valueHiringType = input.state.value;
    valuePublishGFLO = getInputValue("dataPublishGFLO", _context);
  }
  if (input?.props?.name === "dataPublishGFLO") {
    valuePublishGFLO = input.state.value;
    valueHiringType = getInputValue("dataHiringType", _context);
  }

  if (valueHiringType === "EEDECT001" && valuePublishGFLO === "true") {
    result.required = true;
  } else {
    result.required = false;
  }
  return result;
};

const requiredCollectiveAgreementName = (input: any, _context: any) => {
  let result: IDisableResponse = { fieldRelated: undefined, required: false };
  const value = input.state.value;
  result.fieldRelated = "dataCollectiveAgreementName";
  // CHECK HAVE VALUE: value !== undefined && value !== null && value !== ""
  if (value === "true") {
    result.required = true;
  }
  return result;
};

const getInputValue = (name: string, context: any) => {
  return context?.inputs?.find((a: any) => a.props.name === name && a._isMounted)?.state?.value;
};
