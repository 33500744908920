import _ from "lodash";
import React, { Component } from "react";
import { toLiteral } from "../../../../helper/locale-utils";
import SelectFilterOption from "./select-filter-option";
import { sortFunction } from "../../../../helper/object-helper";
export interface IProps {
  [others: string]: any;
}
export default class DropdownMenu extends Component<IProps> {
  globalIndex: any;
  onSelectItem: any;
  arrowDirection: any;
  setItemPosition: any;
  render() {
    let self = this;
    let items: any[] = [];
    let allvalues: any[] = [];
    let index = 0;
    if (self.props.items) {
      self.props.items.forEach((item: any) => {
        if (item && item.type === SelectFilterOption) {
          let isSelected = false;
          if (self.props.mode === "single") {
            isSelected = self.props.value && self.props.value === item.props?.value;
          } else {
            const value = self.props.value && typeof self.props.value === "object" ? self.props.value : [self.props.value];
            isSelected = value && value.length && (value.indexOf(item.props?.value) !== -1 || value.indexOf(`${item.props?.value}`) !== -1) ? true : false;
          }
          let itemCustom = React.cloneElement(item, {
            key: index,
            onSelectItem: self.props.onSelectItem,
            onRemoveItem: self.props.onRemoveItem,
            selected: isSelected,
            index: index,
          });
          items.push(itemCustom);
          allvalues.push(item.props?.value);
          index++;
        }
      });
    }
    if (this.props.loading) {
      return (
        <div className={`select-filter-menu select-filter-menu-${this.props.className} no-border`} ref={this.props.dropdownRef}>
          <div className="message message-info mb-0">
            <div className="icon">
              <i className="fa fa fa-exclamation-circle"></i>
            </div>
            <div className="copy">
              <p>
                <strong>Info:</strong> Loading data
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this.props.items && this.props.items.length) {
      if (this.props.multiple && typeof this.props.value === "object") this.props.value.sort(sortFunction);
      if (typeof allvalues === "object") allvalues.sort(sortFunction);
      const selectAllOptionIsChecked = this.props.showSelectAllOption && allvalues && this.props.value && _.isEqual(this.props.value, allvalues);
      return (
        <div className={`select-filter-menu select-filter-menu-${this.props.className}`} ref={this.props.dropdownRef}>
          {this.props.showSelectAllOption && this.props.multiple && (
            <SelectFilterOption
              multiple={true}
              selected={selectAllOptionIsChecked}
              onSelectItem={this.props.onSelectItem}
              onRemoveItem={this.props.onRemoveItem}
              value={allvalues}
              label={toLiteral({ id: "select.all.button" })}
            />
          )}
          <ul className="select-filter-sub-menu">{items}</ul>
        </div>
      );
    } else {
      return (
        <div className={`select-filter-menu select-filter-menu-${this.props.className} no-border`} ref={this.props.dropdownRef}>
          <div className="message message-info mb-0">
            <div className="icon">
              <i className="fa fa fa-exclamation-circle"></i>
            </div>
            <div className="copy">
              <p>
                <strong>Info:</strong> {toLiteral({ id: "No results found" })}
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}
