import { generateQuestions, rephraseText } from "../../../../api/generative-ai";
import { getAllSkillsOptions } from "../../../recruitment/processes/edit/process-creation-helpers/process-creation-api-helper";

export enum CopilotActionID {
  InterviewQuestionGenerator_0 = "INTERVIEW_QUESTION_GENERATOR_0",
  InterviewQuestionGenerator_1 = "INTERVIEW_QUESTION_GENERATOR_1",
  RephraseText_0 = "REPHRASE_TEXT_0",
}

export enum CopilotActionType {
  multiSelect = "multiSelect",
  input = "input",
  simpleOptions = "simpleOptions",
}

export interface CopilotAction {
  id: CopilotActionID; // Action identifier
  label: string; // Main action title
  apiCall: Function; // API call function
  loadingText?: string; // This is an optional text that is displayed when the API call is triggered
  steps?: CopilotActionSteps[]; // This steps define some additional inputs needed to retrive extra data from the user
  feature?: string; // Name of the feature if is feature dependant
}

export interface CopilotActionSteps {
  label: string; // Step title
  type: CopilotActionType; // Action answer type
  id: string; // This is the id for the input on the form
  answers: CopilotActionAnswer[] | Function; // List of possible answers
  skip?: CopilotActionConditional[]; // This is the defenition of when this step should be skipped
  hideStartOver?: boolean; // If true, the start over action is hiden
}

export interface CopilotActionConditional {
  name: string; // Name of the field that will trigger the conditional
  values?: string[]; // Posible values of the field that will trigger the conditional
  nextStep?: string; // Next step id
}

interface CopilotActionAnswer {
  label: string; // Answer text or placeholder in case is an input type answer
  value?: string; // Answer internal value for multiple options answer types
  nextStep?: string; // Next step id
  icon?: string; // Optional name for a material icon
}

export const COPILOT_ACTION: CopilotAction[] = [
  {
    id: CopilotActionID.InterviewQuestionGenerator_0,
    label: "ai.generate.questions",
    loadingText: "copilot.default.loading.label",
    apiCall: generateQuestions,
    steps: [
      {
        id: "jobTitle",
        label: "ai.generate.questions.step.1",
        type: CopilotActionType.input,
        answers: [{ label: "Job Title", nextStep: "skills" }],
        skip: [{ name: "jobTitle", nextStep: "skills" }],
        hideStartOver: true,
      },
      {
        id: "skills",
        label: "ai.generate.questions.step.2",
        type: CopilotActionType.multiSelect,
        answers: getAllSkillsOptions,
      },
    ],
    feature: "interview-question-generator",
  },
  {
    id: CopilotActionID.InterviewQuestionGenerator_1,
    label: "ai.generate.question.skill",
    loadingText: "copilot.default.loading.label",
    apiCall: generateQuestions,
    steps: [
      {
        id: "jobTitle",
        label: "ai.generate.questions.step.1",
        type: CopilotActionType.input,
        answers: [{ label: "Job Title", nextStep: "skills" }],
        skip: [{ name: "jobTitle", nextStep: "skills" }],
        hideStartOver: true,
      },
    ],
    feature: "interview-question-generator",
  },
  {
    id: CopilotActionID.RephraseText_0,
    label: "ai.rephrase.text",
    loadingText: "copilot.default.loading.label",
    apiCall: rephraseText,
    steps: [
      {
        id: "tone",
        label: "ai.generate.questions.step.1",
        type: CopilotActionType.simpleOptions,
        answers: [
          { label: "ai.generate.tone.1", value: "Friendly", icon: "sentiment_satisfied" },
          { label: "ai.generate.tone.2", value: "Professional", icon: "work" },
          { label: "ai.generate.tone.3", value: "Concise", icon: "short_text" },
        ],
        hideStartOver: true,
      },
    ],
    feature: "rephrase-text",
  },
];
