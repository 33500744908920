import moment from "moment";
import {
  required,
  alphabet,
  alphanumeric,
  url,
  date,
  dateTo,
  dateFrom,
  dateRangeStart,
  dateRangeEnd,
  totalLength,
  kldbValid,
  min,
  max,
  maxLength,
  email,
  phone,
  fiscalCode,
  minYears21,
  minYears18,
  minYears16,
  minLength,
  numeric,
  minSalaryValidation,
  maxSalaryValidation,
  notAllowZeroAtTheBeggining,
  minCustomYears16,
  onlyDigits,
  numericWithDecimal,
  minScheduleWindow,
  maxScheduleWindow,
} from "../components/shared/forms/utilities/validations";
import { toLiteral } from "./locale-utils";
import { getOptionFieldLabel } from "../components/candidate-sourcing/job-description/tenant-fields/tenant-fields-service";
import { getDateFormatFromTenantOrBrowser } from "./date-format-helper";
import { renderCountryNameTranslated } from "../components/candidate-sourcing/candidate-sourcing-constants";

interface IDateNameToObject {
  group: string;
  index: string;
  name: string;
}
interface IDateRanges {
  [x: string]: { start?: number; end?: number; statusId?: number | string };
}
interface IDateRangesResponse {
  rangesDates: IDateRanges;
  missingCurrentPart?: number;
}

export function questionInfo(isMultiple: boolean = false) {
  return isMultiple ? toLiteral({ id: "candidateweb.questions.multiple" }) : toLiteral({ id: "candidateweb.questions.single" });
}

// Date range validations
export const generateRangesDates = (isStart: boolean, nameGroup: string, index: string, inputs: any[]): IDateRangesResponse => {
  let missingCurrentPart: number | undefined = undefined;
  const rangesDates = {} as IDateRanges;
  inputs?.forEach((field: any) => {
    // Only mounted fields - Problem with replicated fields
    if (field._isMounted) {
      const nameField = field.props.name || "";
      if (nameField.startsWith(nameGroup)) {
        const nameParts = arrayDateNameToObject(nameField);
        if (nameParts) {
          if (nameParts.index !== index) {
            // Init object
            if (!rangesDates[nameParts.index]) rangesDates[nameParts.index] = {} as any;
            if (nameField.includes("start")) {
              rangesDates[nameParts.index].start = getValueDate(field.state.value);
            } else {
              rangesDates[nameParts.index].end = getValueDate(field.state.value);
            }
            if (nameField.includes("statusId")) {
              rangesDates[nameParts.index].statusId = field.state.value;
            }
          } else {
            if ((isStart && !nameField.includes("start")) || (!isStart && nameField.includes("start"))) {
              missingCurrentPart = getValueDate(field.state.value);
            }
          }
        }
      }
    }
  });
  return { rangesDates, missingCurrentPart };
};
export const arrayDateNameToObject = (name: string): IDateNameToObject | null => {
  let dateNameFormat = null;
  const parts = name.split(/\[|\]\./);
  if (parts?.length >= 3 && !isNaN(parseInt(parts[1]))) {
    dateNameFormat = {
      group: parts[0].indexOf(".schedules") > -1 ? parts[0].replace(".schedules", "") : parts[0],
      index: parts[1],
      name: parts[2],
    };
  }
  if (parts?.length < 3) {
    // Validate multischedule
    const endDate = name.indexOf(".endDate") > -1;
    const startDate = name.indexOf(".startDate") > -1;
    const statusId = name.indexOf(".statusId") > -1;
    if (startDate || endDate) {
      dateNameFormat = {
        group: name.replace(".endDate", "").replace(".startDate", ""),
        index: "-1",
        name: endDate ? "endDate" : "startDate",
      };
    }
    if (statusId) {
      dateNameFormat = {
        group: name.replace(".statusId", ""),
        index: "-1",
        name: "statusId",
      };
    }
  }
  return dateNameFormat;
};

export const parseToDate = (value: any) => {
  let newValue = value;
  if (value) {
    try {
      newValue = moment(value).format(getDateFormatFromTenantOrBrowser());
    } catch (error) {
      console.error("Error getting date: ", value);
    }
  }
  return newValue;
};

export const getValueDate = (value: any, utc: boolean = false) => {
  if (value === 0) {
    value = null;
  } else {
    if (value) {
      try {
        let datevalue;
        if (utc) {
          datevalue = moment.utc(value, getDateFormatFromTenantOrBrowser()).format("x");
        } else {
          datevalue = moment(value, getDateFormatFromTenantOrBrowser()).format("x");
        }
        value = datevalue !== "Invalid date" ? datevalue : null;
      } catch (error) {
        value = null;
      }
    }
  }
  return value;
};

export const getValidationFunctions = (
  validationNames: string[] | never[] | undefined,
  additionalData?: { tenantAlias: string; phonePrefixSelected: string; phoneSelected: string }
): Function[] | undefined => {
  let validationFunctions: Function[] = [];
  //IT Conf.
  let IT =
    (additionalData?.tenantAlias === "RnMfkC9Y7CTubjnuq4tZ" || //IT
      additionalData?.tenantAlias === "sVV6hwe3kmSZuefb7CAr") && //LHH IT
    (additionalData?.phonePrefixSelected === "+39" || additionalData?.phonePrefixSelected === "0039");

  if (validationNames) {
    validationNames.forEach((functionName) => {
      switch (functionName) {
        case "required":
          validationFunctions.push(required);
          break;
        case "numeric":
          validationFunctions.push(numeric);
          break;
        case "alphabet":
          validationFunctions.push(alphabet);
          break;
        case "alphanumeric":
          validationFunctions.push(alphanumeric);
          break;
        case "url":
          validationFunctions.push(url);
          break;
        case "date":
          validationFunctions.push(date);
          break;
        case "minYears16":
          validationFunctions.push(minYears16);
          break;
        case "minYears18":
          validationFunctions.push(minYears18);
          break;
        case "minYears21":
          validationFunctions.push(minYears21);
          break;
        case "dateTo":
          validationFunctions.push(dateTo);
          break;
        case "dateFrom":
          validationFunctions.push(dateFrom);
          break;
        case "dateRangeStart":
          validationFunctions.push(dateRangeStart);
          break;
        case "dateRangeEnd":
          validationFunctions.push(dateRangeEnd);
          break;
        case "totalLength5":
          validationFunctions.push(totalLength(5));
          break;
        case "totalLength10":
          validationFunctions.push(totalLength(10));
          break;
        case "kldbValid":
          validationFunctions.push(kldbValid);
          break;
        case "minLength10":
          validationFunctions.push(minLength(10));
          break;
        case "maxLength8NoZeros":
          validationFunctions.push(maxLength(8, undefined, true));
          break;
        case "maxLength10":
          validationFunctions.push(maxLength(10));
          break;
        case "maxLength11":
          validationFunctions.push(maxLength(11));
          break;
        case "maxLength20":
          validationFunctions.push(maxLength(20));
          break;
        case "maxLength30":
          validationFunctions.push(maxLength(30));
          break;
        case "maxLength50":
          validationFunctions.push(maxLength(50));
          break;
        case "maxLength100":
          validationFunctions.push(maxLength(100));
          break;
        case "maxLength200":
          validationFunctions.push(maxLength(200));
          break;
        case "maxLength255":
          validationFunctions.push(maxLength(255));
          break;
        case "maxLength10485760":
          validationFunctions.push(maxLength(10485760));
          break;
        case "min0":
          validationFunctions.push(min(0));
          break;
        case "min1":
          validationFunctions.push(min(1));
          break;
        case "email":
          validationFunctions.push(email);
          break;
        case "phone":
          validationFunctions.push(phone);
          break;
        case "fiscalCode":
          validationFunctions.push(fiscalCode);
          break;
        case "minSalaryValidation":
          validationFunctions.push(minSalaryValidation);
          break;
        case "maxSalaryValidation":
          validationFunctions.push(maxSalaryValidation);
          break;
        case "minScheduleWindow":
          validationFunctions.push(minScheduleWindow);
          break;
        case "maxScheduleWindow":
          validationFunctions.push(maxScheduleWindow);
          break;
        case "hoursPerWeekValidation":
          validationFunctions.push(max(168));
          break;
        case "numericWithDecimal2":
          validationFunctions.push(numericWithDecimal(2));
          break;
        //IT Custom Fields Validations.
        case "minYears16CustomIT":
          if (IT) {
            validationFunctions.push(minCustomYears16);
          }
          break;
        case "lengthDependsPrefix":
          if (IT && additionalData?.phoneSelected) {
            validationFunctions.push(notAllowZeroAtTheBeggining(additionalData?.phoneSelected)); //Not allow 0s in the beginning
            validationFunctions.push(minLength(9)); //0-9 dig.
            validationFunctions.push(maxLength(10)); //0-10 dig.
          }
          break;
        case "onlyDigits": {
          validationFunctions.push(onlyDigits);
        }
      }
    });
  }
  return validationFunctions;
};

export const getFormatFunction = (nameFunction: string): Function | undefined => {
  switch (nameFunction) {
    case "getOptionCountryLabel":
      return renderCountryNameTranslated;
    case "getOptionFieldLabel":
      return getOptionFieldLabel;
    default:
      return;
  }
};
