import React from "react";
import { FormContext } from "./form";
import { validateInput } from "./utilities/validations";
import BaseInput, { IState } from "./base-input";
import { checkDisabledInput } from "./utilities/disables";

class CheckboxInputDeprecated extends BaseInput {
  type = "CheckboxInput";
  state: Readonly<IState> = {
    disabled: this.props.disabled,
    errors: this.props.errors,
    isValid: undefined,
    type: "checkbox",
    value: this.props.value ? this.props.value : "value",
    checked: this.props.checked,
    validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
    disableRelated: this.props.disableRelated
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.context && typeof this.context.addInputToContext === "function") {
      this.context.addInputToContext(this);

      this.updateParentGroup();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props) || JSON.stringify(prevState) !== JSON.stringify(this.state)) {
      if (this.props.disableRelated) checkDisabledInput(this, this.context);
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        let isChecked = typeof this.props.checked !== "undefined" ? this.props.checked : false;
        if (this.state.checked !== isChecked) {
          this.setState({ checked: isChecked });
        }
        if (this.props.defaultValue !== prevProps.defaultValue) {
          this.setState({ value: this.props.defaultValue });
        }
        if (this.props.disabled !== prevProps.disabled) {
          this.setState({ disabled: this.props.disabled });
        }
      }

      if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
        this.updateParentGroup();
      }
    }
  }

  updateParentGroup = () => {
    if (this.props.changeParentGroup && typeof this.props.changeParentGroup === "function") {
      let isChecked = typeof this.state.checked !== "undefined" ? this.state.checked : false;
      this.props.changeParentGroup(this, isChecked);
    }
  };

  onChange = async (SyntheticEvent: any) => {
    const self = this;
    SyntheticEvent.persist();
    this.setState({ checked: !this.state.checked }, () => {
      validateInput(self, this.context);
      //validate siblings
      if (self.context && self.context.inputs && self.context.inputs.length) {
        //checkboxes inside form
        const siblings = self.context.inputs.filter((el: any) => el !== self && el.type === "CheckboxInput" && el.props.name === self.props.name);
        siblings.forEach((element: any) => {
          validateInput(element, self.context);
        });
      }
      this.updateParentGroup();
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(SyntheticEvent);
    }

    if (SyntheticEvent && !SyntheticEvent?.target?.fakeEvent && typeof this.state.parentFormOnChange === "function") {
      this.state.parentFormOnChange(SyntheticEvent);
    }
  };

  render() {
    this.processCSSClasses();

    const checked = typeof this.state.checked !== "undefined" && this.state.checked !== null ? this.state.checked : false;
    const disabledClass = this.state.disabled ? "disabled" : "";
    return (
      <div className={`input-wrapper input-wrapper-checkbox ${this.validationClass} ${this.loadingClass} ${this.props.className || ""}`}>
        <div className={this.props.className}>
          <span className={`fake-input checkbox ${this.validationClass} ${disabledClass} ${checked ? "active" : ""}`}>
            <input disabled={this.state.disabled} name={this.props.name} value={this.props.value} id={this.props.id} type="checkbox" checked={checked} onChange={this.onChange} />
          </span>
          <label htmlFor={this.props.id} className={this.validationClass}>
            {this.props.label} {this.renderTooltipIcon()}
          </label>

          {this.renderErrorMessage()}
        </div>
      </div>
    );
  }
}

CheckboxInputDeprecated.contextType = FormContext;
export default CheckboxInputDeprecated;
