import React, { ReactElement, useEffect, useState } from "react";
import Loader from "@adeccoux/tag-ds/loader";
import ReactDOM from "react-dom";
import { getTagDSTheme } from "../../../utilities/theme";
import store from "../../../redux/store";

/**
 * Component that renders the app loading
 */

export const addLoadingProgress = (id: string, title?: string, progress?: number): void => {
  const container = document.getElementById(id);
  if (container) {
    container.parentNode?.removeChild(container);
  }

  const newContainer = document.createElement("div");
  newContainer.id = id;
  document.body.appendChild(newContainer);
  ReactDOM.render(<LoadingProgress title={title} id={id} progress={progress} />, newContainer);
};

export const removeLoadingProgress = (id: string): void => {
  const container = document.getElementById(id);
  if (container) {
    ReactDOM.unmountComponentAtNode(container);
    container.parentNode?.removeChild(container);
  }
};

const LoadingProgress: React.FC<any> = (props: { title?: string; id: string; progress?: number }): ReactElement => {
  const [percentage, setPercentage] = useState(0);

  const progressUpdatePerSecond = props?.progress ?? 10;

  useEffect(() => {
    increasePercentage(percentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function increasePercentage(_percentage: number) {
    setTimeout(() => {
      const updatedPercentage = _percentage + progressUpdatePerSecond;
      setPercentage(updatedPercentage);
      if (updatedPercentage < 100 - progressUpdatePerSecond) increasePercentage(updatedPercentage);
    }, 1000);
  }

  return ReactDOM.createPortal(
    <div id={props?.id} data-testid={props?.id} className="loading-bar-layout">
      <Loader className={`tag-ds ${getTagDSTheme(store?.getState()?.tenant?.selectedTenant?.theme)}`} title={props?.title} percentage={{ show: true, value: percentage }} />
    </div>,
    document.body
  );
};

export default LoadingProgress;
