import React from "react";
import Option from "./select-filter-option";
import Selections from "./_select-filter-selections";
import DropdownMenu from "./_select-filter-dropdown-menu";
import BaseInput from "../base-input";
import { FormContext } from "../form";
import { toLiteral } from "../../../../helper/locale-utils";

export { default as Option } from "./select-filter-option";

export interface IProps {
  children?: any;
  name?: string;
  type?: string;
  className?: string;
  value?: any;
  tooltip?: string;
  loading?: boolean;
  emptyOption?: boolean;
  showSelectAllOption?: boolean;
  onFilterChange?: Function; //to handle filter search change
  [others: string]: any;
}
export interface IState {
  children?: any;
  value?: any;
  filterValue?: any;
  [others: string]: any;
}
class SelectFilterDeprecated extends BaseInput {
  type = "SelectFilter";
  state: Readonly<IState> = {
    showMenu: false,
    items: [],
    selectedItems: [],
    showClearIcon: false,
    value: this.props.value ? this.props.value : this.props.multiple ? [] : "",
    filterValue: "",
  };
  tooltip?: string = undefined;
  inputRef: any = React.createRef();
  wrapperRef: any = null;
  dropdownRef: any = React.createRef();
  hiddenInputRef: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      errors: this.props.errors,
      isValid: undefined,
      value: this.props.value ? this.props.value : "",
      validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
      disabled: this.props.disabled,
      readOnly: this.props.readOnly,
      loading: this.props.loading,
      placeholder: this.props.placeholder,
      showSearchButton: this.props.showSearchButton,
    };
    this._isMounted = false;

    this.type = "";
    this.change = this.change.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.context && typeof this.context.addInputToContext === "function") {
      this.context.addInputToContext(this);
    }
    this.renderItems();

    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (this._isMounted) {
      if (this.props.value !== prevProps.value) {
        this.setState({ value: this.props.value });
      }
      if (this.props.disabled !== prevProps.disabled) {
        this.setState({ disabled: this.props.disabled });
      }

      if (this.props.placeholder !== prevProps.placeholder) {
        this.setState({ placeholder: this.props.placeholder });
      }

      if (this.props.showSearchButton !== prevProps.showSearchButton) {
        this.setState({ showSearchButton: this.props.showSearchButton });
      }
      if (this.props.readOnly !== prevProps.readOnly) {
        this.setState({ readOnly: this.props.readOnly });
      }
      if (this.props.loading !== prevProps.loading) {
        this.setState({ loading: this.props.loading });
      }
      if (this.props.tooltip !== prevProps.tooltip) {
        this.setState({ tooltip: this.props.tooltip });
      }
      if (prevProps.children !== this.props.children) {
        this.renderItems();
      }
      try {
        if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
          //just for you sonar =),  to avoid wrong code smell for prevState, needed for checkbox input
        }
      } catch (error) {}
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    const clickedInWrapper = this.wrapperRef && this.wrapperRef.contains(event.target);
    const clickedInDropdown = !this.dropdownRef || (this.dropdownRef.current && this.dropdownRef.current.contains(event.target));
    if (!clickedInWrapper && !clickedInDropdown) {
      this.setState({ showMenu: false });
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, showClearIcon: false });
    if (!this.state.showMenu) {
      if (this.inputRef && this.inputRef.current) {
        setTimeout(() => {
          this.inputRef.current.focus();
        }, 100);
      }
    }
  };

  onRemoveItem = (value: string | string[]) => {
    let newValues: any;
    if (this.props.multiple !== true) {
      newValues = "";
    } else {
      const currentStateValue = this.state.value;
      if (typeof currentStateValue === "object") {
        if (typeof value === "object" && value?.length) {
          newValues = this.state.value && this.state.value.length && this.state.value.filter((existingValue: string) => value.indexOf(existingValue) === -1);
        } else {
          newValues = this.state.value && this.state.value.length && this.state.value.filter((existingValue: string) => existingValue !== value);
        }
      } else if (typeof currentStateValue === "string") {
        newValues = [];
      }
    }
    const fakeEvent = {
      target: { ...this.hiddenInputRef, value: newValues },
    };

    this.setState({ value: newValues }, async () => {
      this.processChange(newValues, fakeEvent);
    });
  };

  onSelectItem = (value: string | string[]) => {
    const fakeEvent = {
      target: { ...this.hiddenInputRef, value: value },
    };
    if (this.props.multiple !== true) {
      this.setState({ /* showMenu: false, */ value: value }, async () => {
        this.processChange(value, fakeEvent);
      });
    } else {
      let values = typeof this.state.value === "object" ? this.state.value : this.state.value ? [this.state.value] : [];
      if (typeof value === "object") {
        values = [...new Set([...values, ...value])]; //using set avoids duplicated values in the same array
      } else {
        if (!values?.some((existingValue: string) => existingValue === value)) {
          values.push(value);
        }
      }

      this.setState({ value: values }, async () => {
        this.processChange(values, fakeEvent);
      });
    }
  };

  deleteTextInput = () => {
    this.setState({ showClearIcon: false, filterValue: null });
  };

  filterItems = (event: any) => {
    const showClearIcon = event.target.value.length > 0 ? true : false;
    this.setState({ filterValue: event.target.value, showClearIcon: showClearIcon, showMenu: true });
    this.renderItems(event.target.value.toUpperCase());
    if (this.props.onFilterChange) {
      this.props.onFilterChange(event);
    }
  };

  renderItems(filterValue = null) {
    let self = this;
    let items: any[] = [];
    let children: any = self.props.children;
    let index = 0;
    if (self.props.children) {
      children.forEach((item: any) => {
        if (item.type === Option) {
          let isSelected: boolean = false;
          if (self.props.value) {
            if (self.props.multiple !== true) {
              isSelected = self.props.value && self.props.value.length === item.props?.value ? true : false;
            } else {
              isSelected =
                self.props.value &&
                self.props.value.length &&
                typeof self.props.value === "object" &&
                typeof self.props.value.find((a: any) => a.value === item.props?.value || `${a.value}` === `${item.props?.value}`) !== "undefined"
                  ? true
                  : false;
            }
          }
          let itemCustom: any = React.cloneElement(item, {
            key: index,
            onSelectItem: self.onSelectItem,
            selected: isSelected,
            index: index,
          });
          if (filterValue) {
            let matchesValue = itemCustom.props.value ? itemCustom.props.value.toUpperCase().includes(filterValue) : false;
            let matchesLabel = itemCustom.props.value ? itemCustom.props.label.toUpperCase().includes(filterValue) : false;
            if (matchesValue || matchesLabel) {
              items.push(itemCustom);
            }
          } else {
            items.push(itemCustom);
          }
          index++;
        }
      });
    }
    this.setState({ items: items });
  }

  renderMenu() {
    if (this.state.showMenu) {
      return (
        <DropdownMenu
          className={`${this.props.className} adecco-ui`}
          dropdownRef={this.dropdownRef}
          onSelectItem={this.onSelectItem}
          onRemoveItem={this.onRemoveItem}
          showSelectAllOption={this.props.showSelectAllOption}
          loading={this.props.loading}
          items={this.state.items}
          mode={this.props.mode}
          value={this.state.value}
          multiple={this.props.multiple}
        />
      );
    }
  }

  renderHiddenInput() {
    const children: any = this.props.children;
    let value: any = this.state.value ? this.state.value : "";

    if (this.props.multiple) {
      value = this.state.value ? this.state.value : [];
      if (typeof value === "string") {
        value = [value];
      }
    }
    return (
      <div className="hidden">
        <select
          onChange={() => {
            return;
          }}
          disabled={this.state.disabled}
          name={this.props.name}
          multiple={this.props.multiple}
          ref={this.hiddenInputRef}
          value={value}
        >
          {this.props.emptyOption && <option value=""></option>}
          {children.map((option: any, index: number) => {
            return (
              <option key={index} value={option.props?.value}>
                {option.props?.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  renderErrorMessage = () => {
    const input = this.hiddenInputRef && this.hiddenInputRef.current;
    if (input && input.state && input.state.errors) {
      return <div className="input-error">{input.state.errors[0]}</div>;
    } else if (this.state.errors?.length > 0) {
      return <div className="input-error">{this.state.errors[0]}</div>;
    }
  };

  render() {
    this.processCSSClasses();
    const disableClass = this.state.disabled ? "disabled" : "";
    return (
      <>
        <div className={`input-wrapper ${this.validationClass}  ${this.loadingClass} ${this.props.className}`}>
          {this.props.label ? (
            <label>
              {this.props.label} {this.validationClass && this.validationClass.includes("validation-required") && <small className="required">*</small>}
              {this.renderTooltipIcon()}
            </label>
          ) : null}

          <div className={`select-filter `} style={this.props.style ? this.props.style : {}} ref={this.setWrapperRef}>
            <div className="select-filter-container">
              <div className={`inputs-group ${disableClass}`}>
                <div className="input-group-item">
                  <Selections name={this.props.name} onRemoveItem={this.onRemoveItem} items={this.props.children} value={this.state.value} disabled={this.state.disabled} />
                </div>
                <div className="input-group-item fill">
                  <input
                    placeholder={this.state.placeholder && this.state.placeholder?.length !== 0 ? this.state.placeholder : toLiteral({ id: "Click to search" })}
                    className="select-text-input"
                    ref={this.inputRef}
                    type="text"
                    onClick={this.toggleMenu}
                    value={this.state.filterValue ? this.state.filterValue : ""}
                    onChange={this.filterItems}
                    disabled={this.state.disabled}
                  />
                </div>
                {this.state.showSearchButton !== false && (
                  <button onClick={this.toggleMenu} type="button" className="input-group-item btn btn-transparent  select-filter-btn-input" disabled={this.state.disabled}>
                    <span className="select-filter-icon-search fas fa-search"></span>
                  </button>
                )}
              </div>
            </div>
            {this.renderMenu()}
            {this.renderHiddenInput()}
          </div>
          {this.renderErrorMessage()}
        </div>
      </>
    );
  }
}

SelectFilterDeprecated.contextType = FormContext;
export default SelectFilterDeprecated;
