import React, { ReactElement, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import store from "../../../redux/store";
import "./header.scss";

import { getCurrentRoute, getRoutePath, NavItem } from "../../../utilities/navigation";

import { getFeatureConfigurationIsEnabled } from "../../../helper/feature-configuration-helper";
import { toLiteral } from "../../../helper/locale-utils";
import UpperMenu from "@adeccoux/tag-ds/upperMenu";
import { getInitials } from "../../../helper/role-helper";
import { getTagDSTheme } from "../../../utilities/theme";
import { HEADER_TITLE } from "../../../constants";

import { Avatar } from "@adeccoux/tag-ds";
import { getNotificationsItems } from "./helpers/internal-header-notifications";
import { isProd } from "../../../utilities/enviroments";

/**
 * Component that renders the internal header
 */
const InternalHeader: React.FC<any> = (): ReactElement => {
  const history = useHistory();

  const userName = store.getState()?.tenant?.userName;
  const photo = store.getState()?.tenant?.photo;
  const external = store.getState()?.auth?.external;
  const tenantIsAvailability = store.getState()?.tenant?.availability;
  const theme = store.getState()?.tenant?.selectedTenant?.theme;
  const tenantId = store.getState().tenant?.selectedTenant?.id;

  const isCalendarsEnabled = getFeatureConfigurationIsEnabled("General Settings", "calendar-branches");
  const isVideolibraryEnable = getFeatureConfigurationIsEnabled("General Settings", "video-library");
  const isRecruiterProfileEnable = getFeatureConfigurationIsEnabled("General Settings", "recruiter-profile");

  const [showNotifications, setShowNotifications] = useState(false);
  const lastReleaseReaded = localStorage.getItem(`rc_last-read-notification-${tenantId}`);
  const [idReleaseReaded] = useState<string | null>(lastReleaseReaded);

  const currentRoute: NavItem = getCurrentRoute(window.location.pathname);

  useEffect(() => {
    if (isProd()) return;
    let upperMenuLeft = document.getElementsByClassName("upper-menu_left")?.[0];
    if (!upperMenuLeft) return;
    upperMenuLeft?.classList?.add(process?.env?.REACT_APP_ENV_PREFIX ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  function goTo(pathId: string) {
    history.push(`${getRoutePath(pathId)}`);
  }

  const getOptionsArr = () => {
    const options: any[] = [];

    if (isRecruiterProfileEnable) options.push({ name: toLiteral({ id: "literals.profile" }), onClick: () => goTo("RECRUITER_PROFILE") });
    if (isCalendarsEnabled) options.push({ name: toLiteral({ id: "breadcrumb.calendars" }), onClick: () => goTo("CALENDARS_PAGE") });
    if (isVideolibraryEnable) options.push({ name: toLiteral({ id: "administrator.services.process.video.library" }), onClick: () => goTo("VIDEO_LIBRARY_PAGE") });

    return options;
  };

  const notifications = {
    title: toLiteral({ id: "Notifications" }),
    setShowItems: () => setShowNotifications(!showNotifications),
    showItems: showNotifications,
    items: getNotificationsItems(history, [], idReleaseReaded, tenantIsAvailability),
    emptyMessage: toLiteral({ id: "Empty notifications" }),
  };

  return (
    <header id="header">
      <UpperMenu
        className={`tag-ds rc-upper-menu ${getTagDSTheme(theme)}`}
        title={toLiteral({ id: currentRoute?.section ? HEADER_TITLE?.[currentRoute?.section] : "" })}
        avatar={
          <Avatar title={external ? toLiteral({ id: "Guest" }) : userName}>
            {photo ? (
              <img alt="avatar icon" className="avatar_xsmall" src={"data:image/jpeg;base64," + photo} />
            ) : (
              <div className="avatar_xsmall no-photo">
                <div>{external ? toLiteral({ id: "G" }) : getInitials(userName)}</div>
              </div>
            )}
          </Avatar>
        }
        notifications={external ? undefined : notifications}
        options={external ? undefined : getOptionsArr()}
      />
    </header>
  );
};

export default InternalHeader;
