import { useEffect, useState } from "react";
import { checkDeviceSize } from "../utilities/devices";

export interface IUseDevice {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

/**
 *
 * @internal
 */
export const useDevice = (): IUseDevice => {
  const [isMobile, setIsMobile] = useState(typeof window !== "undefined" && window.innerWidth <= 672);
  const [isTablet, setIsTablet] = useState(typeof window !== "undefined" && window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState((typeof window !== "undefined" && window.innerWidth > 1024) ?? true);

  const handleResize = () => {
    const device = checkDeviceSize();
    setIsMobile(device.mobile);
    setIsTablet(device.tablet);
    setIsDesktop(device.desktop);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return { isMobile, isTablet, isDesktop };
};
