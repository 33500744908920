import { _env } from "../config-env";
import { ApplicationInsights, DistributedTracingModes, ITelemetryPlugin } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { History } from "history";
let reactPlugin: ITelemetryPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

export const createTelemetryService = (history: History) => {
  const AppInsightsInstrumentationKey = _env.REACT_APP_INST_KEY;
  try {
    if (!history) {
      throw new Error("Could not initialize Telemetry Service");
    }

    if (!AppInsightsInstrumentationKey) {
      throw new Error("App insights Instrumentation key is not provided");
    }
  } catch (e) {
    console.error(e);
  }

  if (Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
    reactPlugin = new ReactPlugin() as unknown as ITelemetryPlugin;
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: AppInsightsInstrumentationKey,
        maxBatchInterval: 0,
        distributedTracingMode: DistributedTracingModes.AI,
        disableCorrelationHeaders: true, //I disabled this because if not the files requested by third parties are blocked by CORS errors. (ie: Cookies OneTrus https://cdn.cookielaw.org/)
        disableFetchTracking: false,
        enableRequestHeaderTracking: true,
        enableCorsCorrelation: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: history,
          },
        },
      },
    });

    appInsights.loadAppInsights();
    return appInsights;
  }
};

export const getAppInsights = (): ApplicationInsights | null => appInsights;
