import React, { createRef } from "react";
import BaseInput from "./base-input";
import { FormContext } from "./form";
import { toLiteral } from "../../../helper/locale-utils";
import _ from "lodash";

export interface IProps {}

class SelectTagInput extends BaseInput {
  selectRef = createRef<HTMLSelectElement>();

  selectOption(value: string, previouslySelected: boolean) {
    let newValue: string | string[];
    if (this.props.multiple) {
      if (typeof this.state.value === "string") newValue = [this.state.value];
      else newValue = this.state.value || [];
      // PreviouslySelected - remove
      if (previouslySelected) {
        newValue = newValue.filter((previousValue) => previousValue !== value);
        // Add to the list if no exist
      } else {
        const alreadySelected = newValue.findIndex((previousValue) => previousValue === value);
        if (alreadySelected === -1) newValue.push(value);
      }
    } else {
      // Clean if previosuly selected - else overwrite
      newValue = previouslySelected ? "" : value;
    }
    this.processChange(newValue, { target: { value: newValue } });
    // Review update preview
  }

  generateOptions(options: any[]) {
    const _options = options?.map((option, index) => (
      <option key={index} value={option?.value}>
        {toLiteral({ id: option?.label })}
      </option>
    ));
    _options.unshift(
      <option key="-1" value="">
        {toLiteral({ id: "Select an option" })}
      </option>
    );
    return _options;
  }

  renderOption(option: any, value: string | string[], index: number) {
    const optionValue = option?.value || "";
    let isSelected = false;
    if (_.isArray(value)) {
      isSelected = value.indexOf(optionValue) !== -1;
    } else {
      isSelected = value === optionValue;
    }
    return (
      <button
        data-testid={"chip-icon-language-" + optionValue}
        key={"button-" + index}
        type="button"
        className={`chip ${isSelected ? "active" : "simple-chips"} mr2`}
        data-value={optionValue}
        onClick={(e: any) => this.selectOption(optionValue, isSelected)}
      >
        {toLiteral({ id: option?.label })}
        {isSelected && <span className="material-icons right">close</span>}
      </button>
    );
  }

  render() {
    this.processCSSClasses();
    let value: any = this.state.value ? this.state.value : "";
    if (this.props.multiple) {
      value = this.state.value ? this.state.value : [];
    }

    return (
      <div className={`input-wrapper ${this.validationClass} ${this.loadingClass} ${this.props.className || ""}`}>
        {this.renderInputLabel()}
        <div className="input-container">
          {/* Hidden select */}
          <select
            ref={this.selectRef}
            id={this.props.id}
            disabled={this.state.disabled}
            name={this.props.name}
            multiple={this.props.multiple}
            onChange={this.onChange}
            value={value}
            className="hidden"
          >
            {this.props.options && this.props.options.length > 0 && this.generateOptions(this.props.options)}
          </select>
          {/* Visible options */}
          <div className="d-inline-flex">{this.props.options.map((option: any, i: number) => this.renderOption(option, value, i))}</div>
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }
}

SelectTagInput.contextType = FormContext;
export default SelectTagInput;
