import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTokens, getTokensByRefreshToken, logout } from "../../../../helper/auth-helper";
import { RootState } from "../../../../redux/store";
import Loading from "../../loading/loading";
import { NavItem, getCurrentRoute } from "../../../../utilities/navigation";

/**
 * Component that handle the auth context
 */

// check if token is valid
// check if need renew token

let timer: NodeJS.Timeout;
const createTimer = (time: number, dispatch: Function) => {
  clearTimeout(timer);
  timer = setTimeout(function () {
    getTokensByRefreshToken(dispatch);
  }, time);
};

const AuthHandler: React.FC<any> = (props): ReactElement => {
  // For more security Auth Token will be on memory and Refresh token on cookie

  const history = useHistory();
  const authData = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const refreshCookie = document.cookie.replace(/(?:(?:^|.*;\s*)lastCheck\s*=\s*([^;]*).*$)|^.*$/, "$1");
    const authToken = authData.token;
    const expirationAuth = authData.tokenExpiry;
    const currentRoute: NavItem = getCurrentRoute(history?.location.pathname);
    //We add this conditional to avoid the AuthFunc in public pages. (#253099 FO)
    if (currentRoute?.public) {
      setIsLoading(false);
    } else {
      if (authData.error) {
        logout(history, "Session error", dispatch);
      }
      if (authData.code) {
        setIsLoading(true);
        getTokens(authData.code, dispatch);
      } else {
        if (refreshCookie) {
          // if exist expiration time auth, calculate remaing time
          const remainingTime = expirationAuth ? expirationAuth - 10 * 60 * 1000 : 0;
          if (authToken && remainingTime && remainingTime > Date.now()) {
            createTimer(remainingTime - Date.now(), dispatch);
            setIsLoading(false);
          } else {
            setIsLoading(true);
            getTokensByRefreshToken(dispatch)?.finally(() => setIsLoading(false));
          }
        } else {
          setIsLoading(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  return <>{isLoading ? <Loading id="auth-handler" /> : props.children}</>;
};

export default AuthHandler;
