import store from "../redux/store";
import { isDev } from "../utilities/enviroments";
import { history } from "../../src/index";
import { logout } from "../helper/auth-helper";
import { authSlice } from "../redux/slices/authSlice";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_CS_ADMIN, ROLE_USER, ROLE_ATTRACTION, ROLE_SOURCING, ROLE_ASSESMENT, ROLE_ASSESMENT_SENIOR, ROLE_SCREENING } from "../constants";
import { getTenantRole } from "../helper/role-helper";
import { TokenStorage } from "../helper/token-storage";

export const userIsAuth = (logOutIfFalse: boolean = false) => {
  const state = store.getState();
  const AdB2C = new TokenStorage().getAzureAdB2C();
  const userId = state.auth.userId || AdB2C?.userId;
  const exp = state.auth.tokenExpiry || AdB2C?.tokenExpiry;
  const isLoggedIn: boolean = isDev() ? true : userId !== null && exp !== null && exp * 1000 >= Date.now();
  //check if user is login and redirect to login
  if (!isLoggedIn && logOutIfFalse) {
    logout(history, "login.error", store.dispatch, () => {
      store.dispatch(authSlice.actions.auth(null));
    });
  }
  return isLoggedIn;
};

export const checkRoleAndPermissions = (tenants: any[], tenantAlias?: string, roles?: string[], permissions?: string[], selectedUserProfile?: string) => {
  const tenant = tenants?.find((tenantFind: any) => tenantFind.alias === tenantAlias);
  const isSuperAdmin = getTenantRole(tenants, tenantAlias) === ROLE_SUPER_ADMIN;
  const isAdmin = getTenantRole(tenants, tenantAlias) === ROLE_ADMIN;
  const isUser = getTenantRole(tenants, tenantAlias, selectedUserProfile) === ROLE_USER;
  const isRoleAttraction = getTenantRole(tenants, tenantAlias, selectedUserProfile) === ROLE_ATTRACTION;
  const isRoleSourcing = getTenantRole(tenants, tenantAlias, selectedUserProfile) === ROLE_SOURCING;
  const isRoleScreening = getTenantRole(tenants, tenantAlias, selectedUserProfile) === ROLE_SCREENING;
  const isRoleAssesment = getTenantRole(tenants, tenantAlias, selectedUserProfile) === ROLE_ASSESMENT;
  const isRoleAssesmentSenior = getTenantRole(tenants, tenantAlias, selectedUserProfile) === ROLE_ASSESMENT_SENIOR;

  let havePermission = true;
  // Check roles
  if (roles) {
    const haveSuperAdminPermission = roles.find((role: any) => role === ROLE_SUPER_ADMIN) && isSuperAdmin;
    const haveAdminPermission = roles.find((role: any) => role === ROLE_ADMIN) && isAdmin;
    const haveCSADminPermission = roles.find((role: any) => role === ROLE_CS_ADMIN) && isAdmin;
    const haveUserPermission = roles.find((role: any) => role === ROLE_USER) && isUser;
    const haveAttractionPermission = roles.find((role: any) => role === ROLE_ATTRACTION) && isRoleAttraction;
    const haveSourcingPermission = roles.find((role: any) => role === ROLE_SOURCING) && isRoleSourcing;
    const haveScreeningPermission = roles.find((role: any) => role === ROLE_SCREENING) && isRoleScreening;
    const haveAssesmentPermission = roles.find((role: any) => role === ROLE_ASSESMENT) && isRoleAssesment;
    const haveAssesmentSeniorPermission = roles.find((role: any) => role === ROLE_ASSESMENT_SENIOR) && isRoleAssesmentSenior;
    if (
      !(
        haveSuperAdminPermission ||
        haveAdminPermission ||
        haveCSADminPermission ||
        haveUserPermission ||
        haveAttractionPermission ||
        haveSourcingPermission ||
        haveScreeningPermission ||
        haveAssesmentPermission ||
        haveAssesmentSeniorPermission
      )
    )
      havePermission = false;
  }
  // Check permissions
  if (permissions) {
    permissions.forEach((permission) => {
      if (permission && tenant?.permissions?.adminPanel && !tenant.permissions.adminPanel[permission]) havePermission = false;
    });
  }
  return havePermission;
};
