import { TokenObject } from "./token-storage-definitions";

const localStorageTokenKey = "rc_tokenStorage_tokenKey";
const localStorageAdB2CKey = "rc_tokenStorage_AdB2CKey";

export class TokenStorage {
  setToken(token: TokenObject): void {
    localStorage.setItem(localStorageTokenKey, JSON.stringify(token));
  }

  getToken(): TokenObject | null {
    const data = localStorage.getItem(localStorageTokenKey);
    if (!data) return null;
    return JSON.parse(data);
  }
  getAzureAdB2C() {
    const data = localStorage.getItem(localStorageAdB2CKey) || ""; //only exists in d-env
    if (!data) return null;
    const parsedToken = data ? JSON.parse(data) : null;
    return parsedToken;
  }

  removeToken(): void {
    localStorage.removeItem(localStorageTokenKey);
  }
}
