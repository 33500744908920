import React, { ReactElement } from "react";
import { toLiteral } from "../../../helper/locale-utils";
import Notification, { NotificationBody, NotificationHeader } from "@adeccoux/tag-ds/notification";

const NoResultsMessage: React.FC<any> = (props): ReactElement => {
  return (
    <Notification className="notification_relative" renderAsPortal={false} data-testid="notification" show={true} info={true}>
      <NotificationHeader>{toLiteral({ id: "Info" })}</NotificationHeader>
      <NotificationBody>{toLiteral({ id: "No results found" })}</NotificationBody>
    </Notification>
  );
};

export default NoResultsMessage;
