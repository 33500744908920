import React, { Component, ComponentType, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getLandingPageURL, getAllRoutes, userHasPermissionToModule, navigationModules, NavItem } from "../../../utilities/navigation";

import { getComponentFromRouteId } from "./routes.service";
import { isDev } from "../../../utilities/enviroments";
import Loading from "../loading/loading";

const renderComponent = function (Comp: ComponentType, routeObject: NavItem, routes: NavItem[], props: any) {
  if (routeObject) {
    return (
      <React.Fragment>
        <div id={"main"} key={routeObject.id}>
          <Comp {...props} />
        </div>
      </React.Fragment>
    );
  } else return null;
};

export interface IProps {
  [others: string]: any;
}
export interface IState {
  [others: string]: any;
}

export default class Routes extends Component<IProps> {
  render() {
    //process urls
    const routes: NavItem[] = getAllRoutes();
    const isDevelopmentEnv: boolean = isDev();
    const landingPageURL = getLandingPageURL();

    return (
      <Suspense fallback={<Loading id="routes-suspense" />}>
        <Switch>
          {routes.map((route: NavItem, index: number) => {
            const componentType = getComponentFromRouteId(route.id);
            let showPage = isDevelopmentEnv || !route.hideInProduction;

            if (showPage) {
              const pageIsForCS = route.allowedModules === navigationModules.candidateSourcing;
              const pageIsForRC = route.allowedModules === navigationModules.recruitmentConnect;

              if (pageIsForCS) {
                showPage = userHasPermissionToModule(navigationModules.candidateSourcing);
              } else if (pageIsForRC) {
                showPage = userHasPermissionToModule(navigationModules.recruitmentConnect);
              }
            }

            if (componentType && showPage) {
              return (
                <Route
                  key={index}
                  exact
                  path={[route.url]}
                  render={() =>
                    renderComponent(componentType, route, routes, {
                      ...route,
                      onShowSnackbar: this.props.onShowSnackbar,
                      appInsights: this.props.getAppInsights,
                    })
                  }
                />
              );
            }
            return null;
          })}

          <Route path="*" render={() => <Redirect to={landingPageURL || { pathname: "/login", state: { error: "login.no.modules.error" } }} />} />
        </Switch>
      </Suspense>
    );
  }
}
