import React, { ReactElement, useEffect, useState } from "react";
import "./snackbar.scss";
import { SNACKBAR_TIMEOUT } from "../../../constants";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface SnackbarProps extends RouteComponentProps {
  onTimeout: Function;
  message: string;
}

/**
 * Component that renders a Snackbar with an error message
 */
const Snackbar: React.FC<SnackbarProps> = (props): ReactElement => {
  const { message, onTimeout } = props;
  const [isRedirecting, setRedirecting] = useState(false);

  useEffect(() => {
    switch (message) {
      case "AuthHeaderNoBearerError":
      case "AuthTokenUndecodablePayloadError":
      case "AuthTokenNoIssuerError":
      case "AuthTokenExpiredError":
      case "AuthTokenUnknownIssuerError":
      case "AuthTokenSubMissingError":
      case "AuthMultipleErrorsError":
      case "AuthTokenUserMissingError":
        let ref = "";
        if (window.location.pathname !== "/" && window.location.pathname !== "/login") {
          ref = "?ref=" + window.location.pathname;
        } else if (window.location.pathname === "/login" && window.location.search) {
          ref = window.location.search;
        }
        props.history.push("/login" + ref, { error: message });
        setRedirecting(true);
        break;
      default:
        setRedirecting(false);
        break;
    }
  }, [message, props.history]);

  useEffect(() => {
    setTimeout(() => onTimeout(), SNACKBAR_TIMEOUT);
  });

  return (
    <div data-testid="snackbar" id="snackbar" className={isRedirecting ? "d-none" : "d-flex p-3"}>
      {!isRedirecting && typeof message === "string" && <div>{message}</div>}
    </div>
  );
};

export default withRouter(Snackbar);
