import React, { ReactElement, useEffect, useState } from "react";
import store from "../../../../redux/store";
import BreadcrumbTagDs from "../../../shared/breadcrumb/breadcrumb-tag-ds";
import { NavItem, getAllRoutes } from "../../../../utilities/navigation";
import { getTagDSTheme } from "../../../../utilities/theme";
import { useDevice } from "../../../../hooks/useDevice";
import { toLiteral } from "../../../../helper/locale-utils";

interface IMainComponent {
  mainContent: Function;
  drawerComponent?: Function;
  newUI?: boolean;
  refScroll?: any;
}

/**
 * Component that renders the internal header
 */
const MainComponent: React.FC<IMainComponent> = (props: IMainComponent): ReactElement => {
  const { drawerComponent, mainContent, newUI, refScroll } = props;

  const routes: NavItem[] = getAllRoutes();
  const storeState = store?.getState();
  const currentRoute = storeState?.navigation?.currentRoute;
  const selectedTenant = storeState?.tenant?.selectedTenant;

  // Responsive control
  const { isDesktop } = useDevice();
  const [drawerMobile, setDrawerMobile] = useState(!isDesktop);
  const [showDrawer, setShowDrawer] = useState(isDesktop);

  useEffect(() => {
    setDrawerMobile(!isDesktop);
    if (isDesktop) setShowDrawer(true);
    else setShowDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  function setUIClassName() {
    if (newUI) return `tag-ds ${getTagDSTheme(selectedTenant?.theme)}`;
    else return "old-ui";
  }

  const renderBreadcrumb = (_currentRoute: any) => {
    return (
      // Reference div for scrolling to top
      <div ref={refScroll}>
        <BreadcrumbTagDs currentPage={_currentRoute} pages={routes} data-testid="breadcrumb-item" />
      </div>
    );
  };

  const renderMainContent = () => {
    return (
      <React.Fragment>
        <div id="main-content" className={`${setUIClassName()}`}>
          {renderBreadcrumb(currentRoute)}
          {mainContent()}
        </div>
      </React.Fragment>
    );
  };

  const closeMainDrawer = () => setShowDrawer(false);

  const renderMainDrawer = (_drawerComponent: Function) => {
    const _collapsed = `mobile-drawer${showDrawer ? "" : "_collapsed"}`;
    return (
      <React.Fragment>
        <aside id="main-drawer" className={`${setUIClassName()} ${drawerMobile ? _collapsed : ""}`}>
          {!showDrawer && (
            <button className="button-secondary show-drawer small" onClick={() => setShowDrawer(true)} title={toLiteral({ id: "Open Drawer" })}>
              <span className="material-icons outlined">east</span>
            </button>
          )}
          {showDrawer && _drawerComponent(closeMainDrawer)}
        </aside>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {drawerComponent ? (
        <React.Fragment>
          {renderMainDrawer(drawerComponent)}
          {renderMainContent()}
        </React.Fragment>
      ) : (
        renderMainContent()
      )}
    </React.Fragment>
  );
};

export default MainComponent;
