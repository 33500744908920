import React, { Component } from "react";
export interface IProps {
  [others: string]: any;
}
class SelectFilterOption extends Component<IProps> {
  onSelectItem = () => {
    this.props.selected ? this.props.onRemoveItem(this.props.value) : this.props.onSelectItem(this.props.value);
  };
  render() {
    return (
      <li className={this.props.selected ? "select-filter-menu-option small active" : "select-filter-menu-option small"} onClick={this.onSelectItem}>
        {this.props.label}
      </li>
    );
  }
}

export default SelectFilterOption;
