import React from "react";
import moment from "moment";
import { FormContext } from "./form";
import BaseInput from "./base-input";
import Calendar from "@adeccoux/tag-ds/calendar";

import { DateTime } from "luxon";
import { equals } from "./utilities/schedules-manipulation";
import Dropdown, { DropdownButton, DropdownMenu } from "@adeccoux/tag-ds/dropdown";
import { getDatesBetween2Dates } from "../../shared/calendar/calendar-service";
import { isMobile } from "../../../utilities/devices";
import { getDateFormatFromTenantOrBrowser } from "../../../helper/date-format-helper";

export const datePickerMode = {
  SINGLE: "single",
  MULTIPLE: "mutiple",
  RANGE: "range",
};

export interface IProps {
  name?: string;
  type?: string;
  className?: string;
  value?: string;
  disabled?: boolean;
  label?: string;
  errors?: string[];
  validations?: Function[];
  validateOnChange?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  placeholder?: string;
  onChange?: Function;
  autoComplete?: string;
  [others: string]: any;
  isMobile?: boolean;
  time?: string;
  includeTime?: boolean;
  //previous line avoids having to write specific component properties
}

export interface IState {
  errors?: string[];
  isValid?: boolean;
  value?: any;
  validateOnChange?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  id?: string;
  [others: string]: any;
}

class DateInput extends BaseInput {
  state: Readonly<IState> = {
    errors: this.props.errors,
    isValid: undefined,
    value: this.props.value,
    validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
    disabled: this.props.disabled,
    readOnly: this.props.readOnly,
    loading: this.props.loading,
    dateFormat: this.props.dateFormat ? this.props.dateFormat : getDateFormatFromTenantOrBrowser(),
    showCalendar: false,
    calendarPosition: null,
    forceRefresh: 0,
  };

  constructor(props: any) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  inputRef: any = React.createRef();
  wrapperRef: any = null;

  componentDidMount() {
    this._isMounted = true;

    let value = null;
    if (this.props.defaultValue) {
      if (Object.prototype.toString.call(this.props.defaultValue) === "[object Date]") {
        value = this.props.defaultValue ? moment(this.props.defaultValue).format(this.state.dateFormat) : null;
      } else {
        value = this.props.defaultValue;
      }
    }

    this.setState({ value: value });
    if (this.context && typeof this.context.addInputToContext === "function") {
      this.context.addInputToContext(this);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!equals(this.props.defaultValue, prevProps.defaultValue) && this.props.value === undefined) {
      this.setState({ value: this.props.defaultValue });
    }
    if (this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  renderCalendar = () => {
    const self = this;
    let _selectedDates: any[] = [];
    let _activeDates: Date[] | undefined;
    let _defaultDate: number | undefined = undefined;

    let value = this.state.value ? this.state.value : [];
    if (typeof value === "string") value = value.split(",");

    if (value && Object.prototype.toString.call(value) === "[object Array]") {
      value.forEach(function (item: any) {
        _selectedDates.push(typeof item === "number" ? moment(item) : moment(item, self.state.dateFormat));
      });

      if (self.props.mode === datePickerMode.MULTIPLE) {
        _defaultDate = _selectedDates[0];
      } else if (self.props.mode === datePickerMode.RANGE) {
        if (typeof value === "object" && value.length) {
          const _valueMin = _selectedDates[0];
          const _valueMax = _selectedDates[1];
          const dateMin = moment(_valueMin);
          const dateMax = moment(_valueMax);

          if (dateMin && typeof dateMin.isValid === "function" && dateMin.isValid() && dateMax && typeof dateMax.isValid === "function" && dateMin.isValid()) {
            _activeDates = getDatesBetween2Dates(_valueMin, _valueMax);
          }
          _defaultDate = _valueMin;
        }
      } else {
        _defaultDate = _selectedDates[0];
      }
    }

    // Force get timestamp default value
    if (_defaultDate) _defaultDate = _defaultDate.valueOf();
    // Paint calendar (if not disabled, FIX of tag-ds)
    if (this.props.customCalendar && !this.state.disabled) {
      return (
        <Calendar
          className="tag-ds datepicker-calendar-wrapper"
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          selectedDates={_selectedDates}
          activeDates={_activeDates?.map((_aDate) => _aDate.valueOf())}
          disabledDates={this.props.disabledDates}
          onSelectDate={(date: number, e?: React.MouseEvent) => this.selectCalendarDate(DateTime.fromMillis(date).toJSDate(), e)}
          defaultDate={_defaultDate}
        />
      );
    }
    return null;
  };

  selectCalendarDate = (date: Date, e?: React.MouseEvent) => {
    if (this.props.customCalendar) {
      if (!this.props.mode || this.props.mode === datePickerMode.SINGLE) {
        let _value: string = "";
        _value = date ? moment(date).format(this.state.dateFormat) : "";
        if (this.props.time && this.props.includeTime) _value += ` ${this.props.time}`;
        this.processChange(_value, { target: this.inputRef.current });
      }
      if (this.props.mode === datePickerMode.MULTIPLE || this.props.mode === datePickerMode.RANGE) {
        e?.stopPropagation();
        e?.nativeEvent.stopImmediatePropagation();
        let _value = this.state.value ? this.state.value : [];
        if (typeof _value === "string") _value = _value.split(",");
        if (this.props.mode === datePickerMode.RANGE) {
          if (_value.length !== 2) {
            _value.push(moment(date).format(this.state.dateFormat));
          } else {
            _value = [];
            _value.push(moment(date).format(this.state.dateFormat));
          }
        }

        if (this.props.mode === datePickerMode.MULTIPLE) {
          if (_value.find((element: any) => element === moment(date).format(this.state.dateFormat))) {
            _value = _value.filter((element: any) => element !== moment(date).format(this.state.dateFormat));
          } else {
            _value.push(moment(date).format(this.state.dateFormat));
          }
        }

        this.setState({ forceRefresh: this.state.forceRefresh + 1 }, () => {
          this.processChange(_value, { target: this.inputRef.current });
        });
      }
    }
  };

  // Review the code removed in comments
  render() {
    this.processCSSClasses();
    let value = this.state.value ? this.state.value : "";
    if (Object.prototype.toString.call(value) === "[object Date]") {
      value = moment(this.props.defaultValue).format(this.state.dateFormat);
    }
    const inputType = this.props.customCalendar ? "text" : "date";
    const _isMobile = isMobile();
    return (
      <div className={`input-wrapper${this.state.disabled ? "_disabled" : ""} ${this.validationClass} ${this.loadingClass} ${this.props.className || ""}`} ref={this.setWrapperRef}>
        <Dropdown className="datepicker-wrapper" forceRefresh={this.state.forceRefresh} id="date-input-dropdown">
          <DropdownButton className="datepicker-container" disabled={this.state.disabled}>
            {this.renderInputLabel()}
            <div className="input-container">
              <input
                disabled={this.state.disabled}
                ref={this.inputRef}
                onChange={this.onChange}
                type={inputType}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={value}
                autoComplete="off"
                readOnly={this.state.readOnly}
                id={this.props.id}
                onFocus={_isMobile ? (e) => e.target.blur() : undefined} // <--- Adding this
                data-dateformat={this.state?.dateFormat}
                data-testid={this.props?.["data-testid"]}
              />
            </div>
            {this.renderErrorMessage()}
          </DropdownButton>
          <DropdownMenu>{this.renderCalendar()}</DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

DateInput.contextType = FormContext;
export default DateInput;
