import _ from "lodash";
import { getInsightsErrors } from "../components/shared/forms/utilities/insights";
import { getSpellingErrors } from "../components/shared/forms/utilities/spelling";
import { DEFAULT_LANGUAGE, FAQS_SECTION } from "../constants";

import { getArrayLength, isDefined } from "./object-helper";
import {
  INoInclusiveSequences,
  IInsightSequences,
  IResponseInsightData,
  IResponseInsightSpellingData,
  ISpellingErrors,
  ISpellingResponseData,
  ISuggestionsErrors,
} from "./inclusive-insights-helper-response";
import { toLiteral } from "./locale-utils";

// CONSTANTS
const ALLOW_LANGS = ["en-US"]; // Only Insights not Spelling
const NEUTRAL_CLASS = "neutral";
export const SPELLING_SCORE = "spellingScore";
export const GENDER_SCORE = "genderScore";
export const COMPLEXITY_SCORE = "complexityScore";
export const OTHER_SCORE = "otherScore";
export const idInsights: string = "formbox-";
// CONSTANTS - TYPES
const COMPLEXITY_TYPE = "too many clauses";
const SPELLING_TYPE = "error";

export const newInsights: IInclusiveInsightsSection = {
  key: "",
  id: idInsights,
  name: "",
  insights: [],
};
export const initialInclusiveInsights: IInclusiveInsightsSections = {
  spellingScore: [],
  genderScore: [],
  complexityScore: [],
  otherScore: [],
};
export const initialInclusiveTotalScore: IInclusiveTotalScore[] = [] as IInclusiveTotalScore[];

// INTERFACES
export interface IMapResponseInsight {
  totalScore: number;
  [SPELLING_SCORE]: IInsight[];
  [GENDER_SCORE]: IInsight[];
  [COMPLEXITY_SCORE]: IInsight[];
  [OTHER_SCORE]: IInsight[];
}
export interface IInsight {
  text: string;
  info: string;
  suggestions?: ISuggestionsErrors[];
}
export interface ISection {
  key: string;
  title: string;
  value: string;
  titleValue: string;
}
export interface IInclusiveInsightsSection {
  key: string;
  id: string;
  name: string;
  insights: IInsight[];
}
export interface IInclusiveTotalScore {
  key: string;
  score: number;
}
export interface IInclusiveInsightsSections {
  [SPELLING_SCORE]: IInclusiveInsightsSection[];
  [GENDER_SCORE]: IInclusiveInsightsSection[];
  [COMPLEXITY_SCORE]: IInclusiveInsightsSection[];
  [OTHER_SCORE]: IInclusiveInsightsSection[];
}
export interface IInclusiveInsightsSectionByKey {
  [SPELLING_SCORE]?: IInclusiveInsightsSection;
  [GENDER_SCORE]?: IInclusiveInsightsSection;
  [COMPLEXITY_SCORE]?: IInclusiveInsightsSection;
  [OTHER_SCORE]?: IInclusiveInsightsSection;
}

// -------------------- FUNCTIONS GENERATE / ADD / DELETE -------------------------------
export function generateSections(descriptionSections: ISection[] = []) {
  const _descriptionSections = descriptionSections || [];
  return [..._descriptionSections];
}
function generateTotalScore(_totalScore: IInclusiveTotalScore[], section: ISection, order: number): IInclusiveTotalScore[] {
  _totalScore.splice(order, 0, {
    key: `${section?.key}` || "",
    score: 1,
  });
  return _totalScore;
}
function generateInclusiveInsight(_inclusiveInsights: IInclusiveInsightsSections, section: ISection, order: number, forceLanguage?: string): void {
  const _newInisght = _.cloneDeep(newInsights);
  const key = `${section?.key}` || "";
  _newInisght.id = _newInisght.id.concat(key);
  _newInisght.key = key;
  _newInisght.name = toLiteral({ id: section?.title, forceLanguage });
  if (_inclusiveInsights.spellingScore) {
    const newInisghtSpelling = _.cloneDeep(_newInisght);
    _inclusiveInsights.spellingScore.splice(order, 0, newInisghtSpelling);
  }
  if (_inclusiveInsights.genderScore) {
    const newInisghtGender = _.cloneDeep(_newInisght);
    _inclusiveInsights.genderScore.splice(order, 0, newInisghtGender);
  }
  if (_inclusiveInsights.complexityScore) {
    const newInisghtComplexity = _.cloneDeep(_newInisght);
    _inclusiveInsights.complexityScore.splice(order, 0, newInisghtComplexity);
  }
  if (_inclusiveInsights.otherScore) {
    const newInisghtComplexity = _.cloneDeep(_newInisght);
    _inclusiveInsights.otherScore.splice(order, 0, newInisghtComplexity);
  }
}

export function generateInitialInclusiveInsights(
  descriptionSections: ISection[] = [],
  forceLanguage?: string
): { _inclusiveInsights: IInclusiveInsightsSections; _totalScore: IInclusiveTotalScore[] } {
  const sections = descriptionSections || [];
  const _totalScore: IInclusiveTotalScore[] = [];
  const _inclusiveInsights = _.cloneDeep(initialInclusiveInsights);
  // Sections
  sections.forEach((section: ISection, index: number) => {
    generateInclusiveInsight(_inclusiveInsights, section, index, forceLanguage);
    generateTotalScore(_totalScore, section, index);
  });
  return { _inclusiveInsights, _totalScore };
}

export function addInclusiveInsightScore(_inclusiveInsights: IInclusiveInsightsSections, _totalScore: IInclusiveTotalScore[], section: ISection, order: number, forceLanguage?: string): void {
  generateInclusiveInsight(_inclusiveInsights, section, order, forceLanguage);
  generateTotalScore(_totalScore, section, order);
}
export function deleteInclusiveInsightScore(_inclusiveInsights: IInclusiveInsightsSections, _totalScore: IInclusiveTotalScore[], key: string): void {
  deleteInclusiveInsight(_inclusiveInsights, key);
  deleteTotalScore(_totalScore, key);
}
function deleteTotalScore(_totalScore: IInclusiveTotalScore[], key: string): void {
  const index = _totalScore.findIndex((sectionTotalScore: IInclusiveTotalScore) => sectionTotalScore.key === key);
  if (index > -1) {
    _totalScore.splice(index, 1);
  }
}
function deleteInclusiveInsight(_inclusiveInsights: IInclusiveInsightsSections, key: string): void {
  const { spellingScore = [], genderScore = [], complexityScore = [], otherScore = [] } = _inclusiveInsights;
  const index = complexityScore.findIndex((section: IInclusiveInsightsSection) => section.key === key);
  if (index > -1) {
    spellingScore.splice(index, 1);
    genderScore.splice(index, 1);
    complexityScore.splice(index, 1);
    otherScore.splice(index, 1);
  }
}
export function changeNameInclusiveInsight(_inclusiveInsights: IInclusiveInsightsSections, name: string, key: string, forceLanguage?: string): void {
  const { spellingScore = [], genderScore = [], complexityScore = [], otherScore = [] } = _inclusiveInsights;
  const index = complexityScore.findIndex((section: IInclusiveInsightsSection) => section.key === key);
  if (index > -1) {
    const nameToChange = toLiteral({ id: name, forceLanguage });
    if (nameToChange) {
      if (!_.isEmpty(spellingScore) && spellingScore[index] && spellingScore[index].name !== nameToChange) spellingScore[index].name = nameToChange;
      if (!_.isEmpty(genderScore) && genderScore[index] && genderScore[index].name === "") genderScore[index].name = nameToChange;
      if (!_.isEmpty(complexityScore) && complexityScore[index] && complexityScore[index].name === "") complexityScore[index].name = nameToChange;
      if (!_.isEmpty(otherScore) && otherScore[index] && otherScore[index].name === "") otherScore[index].name = nameToChange;
    }
  }
}
// ----------------- ADD ERRORS TO INSIGHT GROUP ----------------------
interface ILoadInsightsReturned {
  newInclusiveInsights: IInclusiveInsightsSections | null;
  newInclusiveTotalScore: IInclusiveTotalScore[];
}
export function loadInsightsFromSections(
  inclusiveInsights: IInclusiveInsightsSections,
  totalScore: IInclusiveTotalScore[],
  sections: ISection[],
  language: string,
  load = false
): Promise<ILoadInsightsReturned> {
  // Force avoid load spelling and insights
  if (load) {
    const keys: string[] = [];
    // Add Filter of FAQs because we don't show it
    const promises = inclusiveInsights.complexityScore
      .filter((section: IInclusiveInsightsSection) => section.name !== FAQS_SECTION)
      .map((section: IInclusiveInsightsSection, index: number) => {
        const key = `${section.key}`;
        keys.push(key);
        const value = sections[index].value;
        return Promise.all([getInsightsErrors(key, value, language), getSpellingErrors(key, value, language)]);
      });
    return Promise.all(promises)
      .then((promisesResponse) => {
        const _inclusiveInsights = _.cloneDeep(inclusiveInsights);
        const _totalScore = _.cloneDeep(totalScore);
        promisesResponse.forEach((response: IResponseInsightSpellingData, index: number) => {
          const [insights, spelling] = response;
          const insightsMapped = mapInsightsErrors(insights, spelling);
          addTotalScoreToGroup(_totalScore, `${keys[index]}`, insightsMapped.totalScore);
          addInsightsErrorsToGroups(_inclusiveInsights, `${keys[index]}`, insightsMapped);
        });
        return { newInclusiveInsights: _inclusiveInsights, newInclusiveTotalScore: _totalScore };
      })
      .catch((error) => {
        console.error("Cant load default score", error);
        return { newInclusiveInsights: null, newInclusiveTotalScore: [] };
      });
  } else {
    return Promise.resolve({ newInclusiveInsights: null, newInclusiveTotalScore: [] });
  }
}
export function addInsightsErrorsToGroups(_inclusiveInsights: IInclusiveInsightsSections, key: string, insights: IMapResponseInsight) {
  const { spellingScore = [], genderScore = [], complexityScore = [], otherScore = [] } = _inclusiveInsights;
  const index = complexityScore.findIndex((section: IInclusiveInsightsSection) => section.key === key);
  if (index > -1) {
    if (_.isArray(spellingScore) && spellingScore[index]?.insights) spellingScore[index].insights = insights.spellingScore || [];
    if (_.isArray(genderScore) && genderScore[index]?.insights) genderScore[index].insights = insights.genderScore || [];
    if (_.isArray(complexityScore) && complexityScore[index]?.insights) complexityScore[index].insights = insights.complexityScore || [];
    if (_.isArray(otherScore) && otherScore[index]?.insights) otherScore[index].insights = insights.otherScore || [];
  }
}
export function addTotalScoreToGroup(_totalScore: IInclusiveTotalScore[], key: string, totalScore: number) {
  const index = _totalScore.findIndex((sectionScore: IInclusiveTotalScore) => sectionScore.key === key);
  if (index !== -1 && isDefined(_totalScore[index])) {
    _totalScore[index].score = totalScore || 0;
  }
}

// ---------------------- MAP FUNCTIONS -------------------------------
// addTotalScoreToGroup
export function mapInsightsErrors(errors: IResponseInsightData, spellingErrors: ISpellingResponseData): IMapResponseInsight {
  const totalScore = errors?.inclusivity?.overall_score || 1;
  const spelling_sequences = spellingErrors?.errors || [];
  const noninclusive_sequences = errors?.gender_bias?.non_inclusive_soft_skills || [];
  const complexity_sequences = errors?.text_complexity?.long_sentence_num_clauses_dict || [];
  const other_sequences = errors?.discriminatory || {};
  return {
    totalScore: mapTotalScore(totalScore),
    spellingScore: mapSpellingErrors(spelling_sequences),
    genderScore: mapGendersInsights(noninclusive_sequences),
    complexityScore: mapComplexity(complexity_sequences),
    otherScore: mapOther(other_sequences),
  };
}
function mapTotalScore(totalScore: number = 0): number {
  return _.isNumber(totalScore) && totalScore > 0 ? totalScore : 0;
}
function mapGendersInsights(insights: INoInclusiveSequences[] = []): IInsight[] {
  const clearInsights = insights.filter((insight: INoInclusiveSequences) => insight.class !== NEUTRAL_CLASS);
  return clearInsights.map((insight: INoInclusiveSequences) => ({ text: insight.text, info: insight.class } as IInsight));
}
function mapComplexity(insights: IInsightSequences[] = []): IInsight[] {
  return insights.map((insight: IInsightSequences) => ({ text: insight.text, info: COMPLEXITY_TYPE } as IInsight));
}
function mapSpellingErrors(insights: ISpellingErrors[]): IInsight[] {
  // Type of spelling error in insight.type
  return insights.map((insight: ISpellingErrors) => ({ text: insight.token, info: SPELLING_TYPE, suggestions: insight.suggestions }));
}
function mapOther(insights: any): IInsight[] {
  const _insights = [] as IInsight[];
  const keys = Object.keys(insights);
  keys.forEach((key: string) => {
    const insightsByKey = insights[key] as IInsightSequences[];
    if (_.isArray(insightsByKey)) {
      insightsByKey.forEach((insightByKey: IInsightSequences) => {
        _insights.push({ text: insightByKey.text, info: key });
      });
    }
  });
  return _insights;
}

// ---------------------- REMOVE FUNCTIONS -------------------------------
export function removeInsightErrorInGroup(key: string, type: string, inclusiveInsights: IInclusiveInsightsSections, token: string) {
  const _inclusiveInsights = _.cloneDeep(inclusiveInsights);
  let typeScore: IInclusiveInsightsSection[] = (_inclusiveInsights as any)[type];
  const index = typeScore.findIndex((section: IInclusiveInsightsSection) => section.key === key);
  if (isDefined(index) && typeScore && typeScore[index] && getArrayLength(typeScore[index].insights) > 0) {
    const indexToRemove = typeScore[index].insights.findIndex((insight: IInsight) => insight.text === token);
    if (indexToRemove !== -1) typeScore[index].insights.splice(indexToRemove, 1);
  }
  return _inclusiveInsights;
}
// ---------------------- GET FUNCTIONS -------------------------------
// Errors to WYSIWYG
export function getErrorsNumber(id: string, type: string, insights: IInclusiveInsightsSections = {} as IInclusiveInsightsSections) {
  let numInsights = 0;
  const insightsOfType = getErrors(id, type, insights);
  if (insightsOfType && insightsOfType.length) numInsights = insightsOfType.length;
  return numInsights;
}
export function getErrors(id: string = "", type: string = "", insights: IInclusiveInsightsSections = {} as IInclusiveInsightsSections): IInsight[] {
  let errors: IInsight[] = [];
  if (type && insights && !_.isEmpty(insights)) {
    const insightsType: IInclusiveInsightsSection[] = (insights as any)[type] || [];
    const insightsFound = insightsType.find((wysiwyg) => wysiwyg.id === `${idInsights}${id}`);
    errors = insightsFound?.insights || [];
  }
  return errors;
}
export function getAllErrorByKey(id: string = "", insights: IInclusiveInsightsSections = {} as IInclusiveInsightsSections): IInclusiveInsightsSectionByKey {
  let filterInsights = {} as IInclusiveInsightsSectionByKey;
  if (insights && !_.isEmpty(insights)) {
    filterInsights.spellingScore = insights.spellingScore ? insights.spellingScore.find((wysiwyg) => wysiwyg.id === `${idInsights}${id}`) : undefined;
    filterInsights.genderScore = insights.genderScore ? insights.genderScore.find((wysiwyg) => wysiwyg.id === `${idInsights}${id}`) : undefined;
    filterInsights.complexityScore = insights.complexityScore ? insights.complexityScore.find((wysiwyg) => wysiwyg.id === `${idInsights}${id}`) : undefined;
    filterInsights.otherScore = insights.otherScore ? insights.otherScore.find((wysiwyg) => wysiwyg.id === `${idInsights}${id}`) : undefined;
  }
  return filterInsights;
}
export function getTotalErrors(id: string = "", insights: IInclusiveInsightsSections = {} as IInclusiveInsightsSections): number {
  let numberErrors = 0;
  if (insights && !_.isEmpty(insights)) {
    const { spellingScore, complexityScore, genderScore, otherScore } = getAllErrorByKey(id, insights);
    numberErrors += spellingScore && spellingScore.insights.length ? spellingScore.insights.length : 0;
    numberErrors += genderScore && genderScore.insights.length ? genderScore.insights.length : 0;
    numberErrors += complexityScore && complexityScore.insights.length ? complexityScore.insights.length : 0;
    numberErrors += otherScore && otherScore.insights.length ? otherScore.insights.length : 0;
  }
  return numberErrors;
}
export function getTotalInclusiveScore(inclusiveTotalScores: IInclusiveTotalScore[] = [] as IInclusiveTotalScore[]): number {
  let totalScore = 0;
  let numSections = 0;
  // There are not spelling score
  inclusiveTotalScores.forEach((inclusiveTotalScore: IInclusiveTotalScore) => {
    numSections = numSections + 1;
    totalScore += inclusiveTotalScore?.score || 0;
  });
  return totalScore / numSections || 0;
}

// ---------------------- CYCLE FUNCTIONS -------------------------------
export function getPreviousDimension(currentType: string = ""): string {
  let toInsightType = currentType;
  const keys = Object.keys(initialInclusiveInsights) || [];
  for (let index = keys.length - 1; index >= 0; index--) {
    const key = keys[index];
    if (key === currentType) {
      if (index === 0) {
        toInsightType = keys[keys.length - 1];
      } else {
        toInsightType = keys[index - 1];
      }
    }
  }
  return toInsightType;
}
export function getNextDimension(currentType: string = ""): string {
  let toInsightType = currentType;
  const keys = Object.keys(initialInclusiveInsights) || [];
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (key === currentType) {
      if (index + 1 === keys.length) {
        toInsightType = keys[0];
      } else {
        toInsightType = keys[index + 1];
      }
    }
  }
  return toInsightType;
}

// ---------------------- AUX FUNCTIONS -------------------------------
// Automatic select type
export function getFirstInsightSectionNoEmpty(id: string = "", insights: IInclusiveInsightsSections = {} as IInclusiveInsightsSections): string {
  let type = "";
  const filterInsights = getAllErrorByKey(id, insights);
  if (filterInsights.spellingScore?.insights?.length) {
    type = SPELLING_SCORE; // It is the property access in the object
  } else if (filterInsights.genderScore?.insights?.length) {
    type = GENDER_SCORE;
  } else if (filterInsights.complexityScore?.insights?.length) {
    type = COMPLEXITY_SCORE;
  } else if (filterInsights.otherScore?.insights?.length) {
    type = OTHER_SCORE;
  }
  return type;
}
// Langs
/**
 * In a context form get the ISO Lang value
 * @param context React.Context
 * @returns string ISO Lang
 */
export function getForceLanguage(context: any): string {
  const values = context.serialize();
  return values?.descriptionDisplayLanguage || "";
}
export function getInicialLanguage(context: any, descriptionDisplayLanguage: string = ""): string {
  let lang = descriptionDisplayLanguage;
  if (_.isEmpty(descriptionDisplayLanguage)) {
    lang = getForceLanguage(context);
  } else if (_.isArray(descriptionDisplayLanguage)) {
    lang = descriptionDisplayLanguage[0];
  }
  return lang;
}
export function getSelectedLanguage(lang: string = ""): string {
  return lang ? lang : DEFAULT_LANGUAGE;
}
export function getAllowedLanguage(lang: string = ""): boolean {
  const _lang = getSelectedLanguage(lang);
  return ALLOW_LANGS.includes(_lang);
}
/* HELPER FUNCTIONS SCROLL */
export function getElementScroll(): Element | null {
  return document.getElementsByClassName("recruitment-connect-main-view") ? document.getElementsByClassName("recruitment-connect-main-view")[0] : null;
}
export function getOffsetHeightHeader() {
  const breadcrumb: any = document.getElementsByClassName("breadcrumbs-container") || [{}];
  const breadcrumbHeader = breadcrumb[0]?.offsetHeight || 0;
  const headerHeight = document.getElementById("app-header")?.offsetHeight || 0;
  return breadcrumbHeader + headerHeight;
}
export function getPXFromStyle(pxString: string): number {
  let numberString = pxString.replace("px", "");
  const numberPX = parseInt(numberString);
  return isNaN(numberPX) ? 0 : numberPX;
}
export function getMinimumHeightFixed(elementBar: any | Element, scrollItem: any | Element): number {
  const elementSelector: any = document.querySelector(".body-jd");
  const heightScrollZone = elementSelector?.offsetHeight || 0;
  const heightZoneJD = scrollItem.offsetHeight - 2 * elementBar.offsetHeight;
  return heightScrollZone - heightZoneJD;
}
