export const getTagDSTheme = (rcTheme?: string) => {
  if (rcTheme?.includes("lhh")) return "lhh";
  switch (rcTheme) {
    case "adecco-theme":
      return "adecco";
    case "akkodis-theme":
      return "akkodis";
    case "adecco-group-theme":
    default:
  }
};
