import React, { ReactElement } from "react";

import AuthHandler from "../master/context/auth/auth-handler";
import UserHandler from "../master/context/user/user-handler";
import NavigationHandler from "../master/context/navigation/navigation-handler";
import TenantHandler from "../master/context/tenant/tenant-handler";
import InactiveContainer from "../master/context/inactive/inactive-container";
import AppContainer from "../master/context/container/app-container";

import NotificationsContainer from "../shared/notifications/notifications-container";
import LiteralHandler from "../master/context/literal/literal-handler";
import ScriptHandler from "../master/context/script/script-handler";

/**
 * Base component that renders the entire application
 */
const App: React.FC<any> = (props): ReactElement => {
  return (
    <>
      <AuthHandler>
        <NavigationHandler>
          <UserHandler>
            <TenantHandler>
              <ScriptHandler>
                <InactiveContainer>
                  <LiteralHandler>
                    <AppContainer />
                  </LiteralHandler>
                </InactiveContainer>
              </ScriptHandler>
            </TenantHandler>
          </UserHandler>
        </NavigationHandler>
      </AuthHandler>
      <NotificationsContainer />
    </>
  );
};

export default App;
