import { http } from "../../../../api";
import { CURRENT_LOCALE } from "../../../../helper/locale-utils";
import { getAllowedLanguage, getSelectedLanguage } from "../../../../helper/inclusive-insights-helper";
import { IResponseInsight, IResponseInsightData } from "../../../../helper/inclusive-insights-helper-response";
import { cleanHTMLBeforeSend, clearAllInsightsErrors } from "./errors-wsyiwyg";
import { addRequest, checkIsCancel, removeRequest, Types_Request } from "./abort-insights";

export const removeTagInsightErrorRegex = /<span data-key="(\d*)" class="(.*?)insight-error">(.*?)<\/span>/gi;

// CLEAR POINTS -> export const regexCleanPoints = /[.]+$/;

export async function getInsightsErrors(key: string, value: string, selectedLanguage: string = CURRENT_LOCALE): Promise<IResponseInsightData> {
  let response = {} as IResponseInsight;
  let _value = clearAllInsightsErrors(value);
  _value = cleanHTMLBeforeSend(_value, true);
  const _selectedLanguage = getSelectedLanguage(selectedLanguage);
  if (value !== "" && getAllowedLanguage(_selectedLanguage)) {
    // Add request to array - return parameter that allow abort
    const cancelToken = addRequest(key, Types_Request.SPELLING);
    response = (await http(false, {}, cancelToken)
      .post(`/api/insight`, { text: _value })
      .catch((err: any) => {
        if (checkIsCancel(err)) {
          // Add to response abort request
          return { data: { abort: true } };
        } else {
          console.error("Cant load Insights: ", err);
        }
      })) as IResponseInsight;
    // Remove request to array
    removeRequest(key, Types_Request.SPELLING);
  } else {
    if (value === "") console.info("Empty text not supported: ", _selectedLanguage);
    else console.info("Language not supported: ", _selectedLanguage);
  }
  return response?.data;
}

export function getClassErrorHTMLInsights(indexError: number, index: number): string {
  let classErrorHTML = "";
  if (indexError === index) {
    classErrorHTML = "active insight-error";
  } else {
    classErrorHTML = "insight-error";
  }
  return classErrorHTML;
}

export function getErrorHTMLInsights(classErrorHTML: string, errorToken: string, index: number) {
  return `<span data-key="${index}" class="${classErrorHTML}">${errorToken}</span>`;
}

export function clearInsightErrors(text: string = ""): string {
  return text.replace(removeTagInsightErrorRegex, "$3");
}
