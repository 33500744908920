import { combineReducers } from "@reduxjs/toolkit";

import { authSlice } from "../slices/authSlice";
import { tenantSlice } from "../slices/tenantSlice";

import processDetailsReducer from "../slices/processDetailsSlice";
import administratorStatsReducer from "../slices/administratorStatsSlice";
import recruiterProfileReducer from "../slices/recruiterProfileSlice";
import candidateWebReducer from "../slices/candidateWebSlice";
import processFunnelingReducer from "../slices/processFunnelingSlice";
import liveInterviewReducer from "../slices/liveInterviewSlice";
import videoLibraryReducer from "../slices/administratorVideoLibrarySlice";
import userCalendarsReducer from "../slices/calendarsSlice";
import serviceCreationSliceReducer from "../slices/serviceCreationSlice";
import navigationSliceReducer from "../slices/navigationSlice";
import notificationsReducer from "../slices/notificationsSlice";
import copilotReducer from "../slices/copilotSlice";
import literalsReducer from "../slices/literalsSlice";

export const rootReducer = combineReducers({
  processDetails: processDetailsReducer,
  tenant: tenantSlice.reducer,
  administratorStats: administratorStatsReducer,
  recruiterProfile: recruiterProfileReducer,
  liveInterview: liveInterviewReducer,
  auth: authSlice.reducer,
  candidateWeb: candidateWebReducer,
  processFunneling: processFunnelingReducer,
  videoLibrary: videoLibraryReducer,
  userCalendars: userCalendarsReducer,
  notifications: notificationsReducer,
  serviceCreation: serviceCreationSliceReducer,
  navigation: navigationSliceReducer,
  copilot: copilotReducer,
  literals: literalsReducer,
});
