import React from "react";

export interface ISidebarFooterProps {
  /**
   * Identifies if sidebar is collapsed
   */
  collapsed?: boolean;
  /**
   * Add class to sidebar divider
   */
  className?: string;
  [others: string]: any;
}

export const SidebarFooter: React.FC<ISidebarFooterProps> = (props) => {
  const { children, className, collapsed, ...rest } = props;
  return collapsed ? <></> : <div {...rest}>{children}</div>;
};

export default SidebarFooter;
