import React, { ReactElement } from "react";
import Loader from "@adeccoux/tag-ds/loader";
import ReactDOM from "react-dom";
import { getTagDSTheme } from "../../../utilities/theme";
import store from "../../../redux/store";

/**
 * Component that renders the app loading
 */
const Loading: React.FC<any> = (props: { title?: string; id?: string }): ReactElement => {
  return ReactDOM.createPortal(
    <div id={props?.id ?? "loading-context"} className="loading-bar-layout">
      <Loader className={`tag-ds ${getTagDSTheme(store?.getState()?.tenant?.selectedTenant?.theme)} loading-main`} title={props?.title} spinner />
    </div>,
    document.getElementById("root") || document.body
  );
};

export default Loading;
