import React from "react";
import { compareDateDays, getFirstDayOfMonth, getLastDayOfMonth, getDisplayedDaysPrevMonth, getDisplayedDaysNextMonth, getDaysFromTo } from "./calendar-service";
import moment from "moment";
import { CURRENT_LOCALE, dayNameLocale } from "../../../helper/locale-utils";

export interface IProps {
  activeDates: any;
  [others: string]: any;
}
export interface IState {
  defaultDate?: Date;
  displayedMonth?: number;
  [others: string]: any;
}

export default class Calendar extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    defaultDate: this.props.defaultDate ? new Date(this.props.defaultDate) : new Date(Date.now()),
    displayedMonth: this.props.defaultDate ? new Date(this.props.defaultDate).getMonth() : new Date(Date.now()).getMonth(),
    activeDates: this.props.activeDates,
    disabledDates: this.props.disabledDates,
  };

  //disabled days
  //default date
  //festivos
  //date range
  //multiple dates

  renderMonth = () => {
    const date: Date = this.state.defaultDate ? this.state.defaultDate : new Date();

    let renderedDays: any[] = [];

    //array of previous month days
    const previousMonth_displayedDays = getDisplayedDaysPrevMonth(date);
    if (previousMonth_displayedDays) {
      renderedDays = renderedDays.concat(previousMonth_displayedDays);
    }

    const thisMonth_firstDay = getFirstDayOfMonth(date);
    const thisMonth_lastDay = getLastDayOfMonth(date);
    const thisMonth_displayedDays = getDaysFromTo(thisMonth_firstDay, thisMonth_lastDay);
    renderedDays = renderedDays.concat(thisMonth_displayedDays);

    const nextMonth_displayedDays = getDisplayedDaysNextMonth(date);
    renderedDays = renderedDays.concat(nextMonth_displayedDays);
    const uniqueDays = renderedDays.filter((v: any, i: any, a: any) => a.findIndex((d:Date)=>d?.getTime()===v?.getTime()) === i);
    return uniqueDays.map((_date: Date) => {
      return this.renderDayOfMonth(_date);
    });
  };

  componentDidUpdate = (prevProps: IProps, prevState: IState) => {
    if (prevProps.activeDates !== this.props.activeDates) {
      this.setState({ activeDates: this.props.activeDates });
    }
  };

  renderDayOfMonth = (date: Date) => {
    let _date: Date = new Date(date);
    let differentMonthCSSClass = _date.getMonth() !== this.state.displayedMonth ? "other-month" : "";

    let todayClass = compareDateDays(Date.now(), _date) ? "today" : "";

    let activeClass = this.findDateInArray(_date, this.state.activeDates) ? "active" : "";
    let disabledClass = this.findDateInArray(_date, this.state.disabledDates) ? "disabled" : "";

    if (this.props.disablePastDates && _date) {
      disabledClass = _date.setHours(0, 0, 0, 0) < new Date(Date.now()).setHours(0, 0, 0, 0) ? "disabled" : disabledClass;
      //NOTE: This disables past dates, not present or future, for that, use the disabledDates property.
    }

    if (this.props.disableFutureDates && _date) {
      disabledClass = _date.setHours(0, 0, 0, 0) > new Date(Date.now()).setHours(0, 0, 0, 0) ? "disabled" : disabledClass;
      //NOTE: This disables future dates, not present or past, for that, use the disabledDates property.
    }

    return (
      <button
        key={_date.toISOString()}
        className={`tile day ${activeClass} ${todayClass} ${disabledClass} ${differentMonthCSSClass}`}
        type="button"
        disabled={disabledClass === "disabled"}
        onClick={() => this.selectDate(_date)}
      >
        {/* <abbr aria-label={_date.toISOString()}>{_date.getDate()} </abbr> */}
        <abbr>{_date.getDate()} </abbr>
      </button>
    );
  };

  findDateInArray = (date: Date, array: []) => {
    let matchindDate = null;
    if (array && array.length) {
      array.forEach((element) => {
        if (compareDateDays(element, date)) {
          matchindDate = element;
        }
      });
    }

    return matchindDate;
  };

  updateDisplayedDate = (date: Date) => {
    let _date = new Date(date);
    this.setState({
      defaultDate: _date,
      displayedMonth: _date.getMonth(),
    });
  };

  selectNextMonth = () => {
    let _date: Date = this.state.defaultDate ? this.state.defaultDate : new Date();
    const month: number = this.state.displayedMonth !== null && this.state.displayedMonth !== undefined ? this.state.displayedMonth : 1;
    _date.setMonth(month + 1);
    this.updateDisplayedDate(_date);
  };

  selectPrevMonth = () => {
    let _date: Date = this.state.defaultDate ? this.state.defaultDate : new Date();
    const month: number = this.state.displayedMonth !== null && this.state.displayedMonth !== undefined ? this.state.displayedMonth : 1;
    _date.setMonth(month - 1);
    this.updateDisplayedDate(_date);
  };

  selectPrevYear = () => {
    let _date: any = this.state.defaultDate ? this.state.defaultDate : new Date();
    let year = _date.getFullYear();
    this.updateDisplayedDate(_date.setFullYear(year - 1));
  };

  selectNextYear = () => {
    let _date: any = this.state.defaultDate ? this.state.defaultDate : new Date();
    let year = _date.getFullYear();
    this.updateDisplayedDate(_date.setFullYear(year + 1));
  };

  selectDate = (date: Date) => {
    const _element: HTMLElement = document.activeElement as HTMLElement;

    if (_element) {
      _element.blur();
    }
    if (this.props.onSelectDate) {
      this.props.onSelectDate(date);
    }
  };

  render() {
    return (
      <div className="calendar">
        <div className="navigation">
          <button className="navigation-arrow navigation-prev2-button btn-transparent" type="button" aria-label="" onClick={this.selectPrevYear}>
            «
          </button>
          <button className="navigation-arrow navigation-prev-button btn-transparent" type="button" aria-label="" onClick={this.selectPrevMonth}>
            ‹
          </button>
          <span className="btn tc navigation-label month-title btn-transparent" aria-label="">
            {moment(this.state.defaultDate).locale(CURRENT_LOCALE).format("MMMM YYYY")}
          </span>
          <button className="navigation-arrow navigation-next-button btn-transparent" type="button" aria-label="" onClick={this.selectNextMonth}>
            ›
          </button>
          <button className="navigation-arrow navigation-next2-button btn-transparent" type="button" aria-label="" onClick={this.selectNextYear}>
            »
          </button>
        </div>

        <div className="month-view ">
          <div>
            <div>
              <div className="month-view-weekdays">
                {getWeekDays()?.map((day: string, index: number) => {
                  return (
                    <div className="weekday" key={index}>
                      <abbr title={day} aria-label={day}>
                        {dayNameLocale(index)}
                      </abbr>
                    </div>
                  );
                })}
              </div>
              <div className="month-view-days">{this.renderMonth()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getWeekDays(): Array<string> {
  const days = moment?.weekdays();
  const sunday = days?.shift() || "Sunday";
  days?.push(sunday);
  return days;
}
